import React from 'react'
import { DateTime } from 'luxon'
import { format } from '../../../utils'
import styles from './MyAcp.module.scss'

const MyPaymentsItem = ({ payment }) => {
  const {
    description,
    createdAt,
    merchant,
    cashback,
    cashbackRate,
    displayStatus,
    accumulatedCashback,
    exclusionPayment,
  } = payment
  const { billingAmount } = payment

  const FMT = 'MM.dd'

  const convertPaymentStatus = (displayStatus) => {
    if (displayStatus === 'confirmed') {
      return '결제완료'
    } else if (displayStatus === 'partial_confirmed') {
      return '부분취소'
    }
    return '결제완료'
  }

  const sliceDescription = (text) => {
    if (text.length > 20) {
      return text.slice(0, 20).concat(' ...')
    }
    return text
  }

  const additionalInfo = exclusionPayment
    ? '적립불가 결제'
    : cashback === 0
    ? '적립한도 초과'
    : `${format.price(accumulatedCashback)}원`

  return (
    <>
      <div>
        <div className={styles.payment_1}>
          <span className={styles.payment_day}>{`${DateTime.fromISO(createdAt).toFormat(FMT)}`}</span>
          <div className={styles.rectangle} />
          <span className={styles.payment_status}>{convertPaymentStatus(displayStatus)}</span>
          <span className={styles.payment_amount}>{`${format.price(billingAmount)}원`}</span>
        </div>
        <div className={styles.payment_2}>
          <div className={styles.payment_2_1}>
            <span>{sliceDescription(description)}</span>
          </div>
          <div className={styles.payment_2_2}>
            <span className={styles.payment_cashback}>{format.price(cashback)}원</span>
            <span className={styles.payment_cashback_rate}> ({cashbackRate})</span>
          </div>
        </div>
        <div className={styles.payment_3}>
          <span className={styles.payment_method}>{merchant.name}</span>
          <span className={styles.payment_addition}>{additionalInfo}</span>
        </div>
        <div className={styles.payment_line} />
      </div>
    </>
  )
}

export default MyPaymentsItem

import React, { useState } from 'react'
import cx from 'classnames'
import { replace } from '../utils'
import { useDemo } from './hooks'
import { getSample, PUBLIC_API_KEY } from './samples'
import Tmon from './tmon.png'
import Loading from '../components/Loading'
import styles from './Begin.module.scss'

const PriceList = [100, 10000, 500000, 1000000]
/* 가맹점 데모: 결제 시작 */
const Begin = ({ history }) => {
  const { api } = useDemo()

  /* state */
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [price, setPrice] = useState(PriceList[1])
  const sample = getSample(price)

  /* 가격 바꾸기 */
  const handleChange = e => {
    setPrice(Number(e.target.value))
  }

  /* 가맹점에서 차이 API에 Payment 생성을 요청 */
  const getPayment = async e => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const { data } = await api.post('/payment', sample.data, sample.config)
      replace('/', getQuery(data))
    } catch (error) {
      setIsSubmitting(false)
      window.alert(error.message)
      console.error(error)
    }
  }

  const getQuery = result => ({
    publicAPIKey: PUBLIC_API_KEY,
    paymentId: result.paymentId,
    idempotencyKey: result.idempotencyKey,
    returnUrl: sample.data.returnUrl
  })

  const radio = { type: 'radio', name: 'price', onChange: handleChange }
  return (
    <>
      {isSubmitting && <Loading backdrop />}
      <form onSubmit={getPayment}>
        <section className={styles.ButtonGroup}>
          {PriceList.map(p => (
            <label className={cx(price === p && styles.active)} key={p}>
              <input {...radio} value={p} hidden />
              {format(p)}원
            </label>
          ))}
        </section>

        <button type="submit" onClick={getPayment} className={styles.submit}>
          <img src={Tmon} alt="" style={{ width: '100%' }} />
        </button>
      </form>
    </>
  )
}

export default Begin

/* formatting */
const format = number =>
  number / 10000 >= 1 ? number / 10000 + '만' : String(number)

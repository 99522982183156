import React, { useState } from 'react'
import { func, oneOf } from 'prop-types'
import { useApp, useTheme, ThemeTypes, useNav, NavTypes } from '../hooks'
import AccountForm from './AccountForm'
import AccountARS from './AccountARS'

const propTypes = {
  theme: oneOf(Object.values(ThemeTypes)),
  nav: oneOf(Object.values(NavTypes)),
  onFinish: func.isRequired
}

const defaultProps = {
  theme: undefined,
  nav: NavTypes.NONE
}

/* 계좌등록 후 ARS */
const Account = ({ theme, nav, navConfig, onFinish }) => {
  useTheme(theme)
  useNav(nav, navConfig)
  const { api, handleError } = useApp()

  /* state */
  const [values, setValues] = useState({}) // 은행과 계좌번호
  const [showARS, setShowARS] = useState(false)
  const [code, setCode] = useState('') // 요청의 응답
  const [isRequested, setIsRequested] = useState(false) // 요청 응답이 성공인지 여부
  const [canRequest, setCanRequest] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [timestamp, setTimestamp] = useState() // key

  /* ARS 인증 요청 */
  const request = async (params = values) => {
    setIsSubmitting(true)
    setIsRequested(false)
    setCanRequest(false) // 다시 true가 되는 시점은 ARS 컨펌 응답에 따라 결정
    setTimestamp(Date.now()) // canRequest: false 상태에서 새 ARS 마운트

    try {
      const { data } = await api.post('/user/account/request', params)
      setIsSubmitting(false)
      setCode(data.code || '')
      setIsRequested(true)
      setShowARS(true)
    } catch (error) {
      setIsSubmitting(false)
      handleError(error)
    }
  }

  /* 계좌번호 제출하기 */
  const onSubmit = values => {
    setValues(values)
    request(values)
  }

  const ars = { isRequested, canRequest, request, onFinish, setCanRequest }
  return !showARS ? (
    <AccountForm isSubmitting={isSubmitting} onSubmit={onSubmit} />
  ) : (
    <AccountARS {...ars} birth={code.slice(-6)} key={timestamp} />
  )
}

Account.propTypes = propTypes
Account.defaultProps = defaultProps

export default Account

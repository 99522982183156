import React, { useRef } from 'react'
import { string, object, node } from 'prop-types'
import cx from 'classnames'
import { isDesktop } from '../env'
import { getHeight } from '../utils'
import { useBodyStyle } from '../hooks'
import styles from './Footer.module.scss'

const propTypes = { className: string, style: object, children: node }
const defaultProps = { className: '', style: {}, children: null }

/* 화면 하단에 고정 */
const Footer = ({ className, style, children }) => {
  const ref = useRef()

  // 이 컴포넌트의 높이만큼 <body> 태그에 padding-bottom 스타일을 부여한다.
  useBodyStyle('paddingBottom', () => (isDesktop ? 0 : getHeight(ref)))

  return (
    <footer
      ref={ref}
      className={cx(styles.Footer, className, isDesktop && styles.desktop)}
      style={style}
    >
      <div className={cx(isDesktop && 'desktop')}>{children}</div>
    </footer>
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer

import React, { createContext, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import axios from 'axios'
import List from './List'
import Item from './Item'
import { BarcodeUrl } from '../../Constants'

export const Context = createContext()
const Barcode = ({ match }) => {
  const [order, setOrder] = useState('rank')
  const [barcodeData, setBarcodeData] = useState({})

  useEffect(() => {
    getBarcode()
  }, [])

  const getBarcode = async () => {
    const { data } = await axios.get(`${BarcodeUrl}?v=${Date.now()}`)
    setBarcodeData(data)
  }

  /* context */
  const value = {
    order,
    setOrder,
    barcodeData,
  }

  return (
    <Context.Provider value={value}>
      <Switch>
        <Route path={match.url + '/:merchantId'} component={Item} />
        <Route path={match.url} component={List} />
      </Switch>
    </Context.Provider>
  )
}

export default Barcode

import React from 'react'
import cx from 'classnames'
import { isDesktop } from '../env'
import styles from './Article.module.scss'

const Article = ({ title, subtitle, children, className }) => (
  <article className={cx(styles.article, isDesktop && 'desktop', className)}>
    <header>
      {title && <h1>{title}</h1>}
      {subtitle && <p>{subtitle}</p>}
    </header>
    <section>{children}</section>
  </article>
)

export default Article

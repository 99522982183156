import React, { createContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import api from '../api'
import Begin from './Begin'
import Complete from './Complete'

export const DemoContext = createContext()
const Demo = ({ match }) => (
  <DemoContext.Provider value={{ api }}>
    <Switch>
      <Route exact path={match.url} component={Begin} />
      <Route path={match.url + '/complete'} component={Complete} />
    </Switch>
  </DemoContext.Provider>
)

export default Demo

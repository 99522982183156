import { useState } from 'react'

export default initial => {
  const [type, setType] = useState(initial) // 타입
  const [config, setConfig] = useState({})

  const set = (type, config) => {
    type && setType(type)
    config && setConfig(config)
  }

  const reset = () => {
    setType(initial)
    setConfig({})
  }

  return { set, reset, type, config }
}

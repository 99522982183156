import React, { useState } from 'react'
import { func, node, oneOfType } from 'prop-types'
import cx from 'classnames'
import styles from './InputGroup.module.scss'

const propTypes = { children: oneOfType([func, node]) }
const defaultProps = { children: null }

const InputGroup = ({ children }) => {
  const [isFocused, setIsFocused] = useState(false)
  const attrs = {
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false)
  }

  return (
    <div className={cx(styles.InputGroup, isFocused && styles.focus)}>
      {typeof children === 'function' ? children(attrs) : children}
    </div>
  )
}

InputGroup.propTypes = propTypes
InputGroup.defaultProps = defaultProps

export default InputGroup

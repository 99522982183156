const hasLastLetterFinalConsonant = (word) => {
  const str = word.split('(')[0]
  const lastLetter = str[str.length - 1]
  const lastUnicode = lastLetter.charCodeAt(0)

  if (lastUnicode >= 44032 && lastUnicode <= 55203) {
    const hasFinal = (lastUnicode - 44032) % 28 !== 0 // 받침이 있음 -> "으로"
    const hasException = ((lastUnicode - 44040) % 588) % 28 === 0 // ㄹ 받침이 있음 -> "로"
    return hasFinal && !hasException
  } else {
    const endsWithMorN = ['M', 'N'].includes(lastLetter.toUpperCase()) // "으로'
    return endsWithMorN
  }
}

export const getAffix = (name) => {
  const adj = hasLastLetterFinalConsonant(name) ? '으로' : '로'
  return name + adj
}

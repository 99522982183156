import React, { useState, useEffect, useCallback } from 'react'
import { DateTime } from 'luxon'

import styles from './MyAcp.module.scss'
import { ReactComponent as Down } from './svg/chevron-right.svg'
import { ReactComponent as On2P } from './svg/img-autoplus-on-2.svg'
import { ReactComponent as On4P } from './svg/img-autoplus-on-4.svg'
import { ReactComponent as Balloon } from './svg/bg-balloon-face.svg'
import { ReactComponent as Info } from './svg/icon-exclamation-mark-d-6-d-6-d-6.svg'
import { ReactComponent as Face } from './svg/face.svg'
import MyPaymentsItem from './MyPaymentsItem'
import { format } from '../../../utils'
import Select from '../../../components/Select'
import { useApp } from '../../../hooks'

const INITIAL_MONTH = '2020-10'
const MAX_CASHBACK = 40000

const MyAcp = ({ history }) => {
  const { api, handleError } = useApp()
  const [list, setList] = useState([])

  const [status, setStatus] = useState('disabled')
  const [autoChargeAmount, setAutoChargeAmount] = useState(0)
  const [totalCashback, setTotalCashback] = useState(0)

  const getCurrentMonth = (d = new Date()) => {
    return '2020-12'
  }

  const [month, setMonth] = useState(getCurrentMonth)

  useEffect(() => {
    fetchList(month)
    // eslint-disable-next-line
  }, [])

  const fetchList = useCallback(
    async (value) => {
      const url = `/event/acp/payment?month=${value}`

      try {
        const { data } = await api.get(url)
        const { list, status, autoChargeAmount, totalCashback } = data
        setList(list)
        setStatus(status)
        setAutoChargeAmount(autoChargeAmount)
        setTotalCashback(totalCashback)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [month]
  )

  const goToDetail = () => {
    history.push('/event/acp/myPage')
    history.replace('/event/acp/detail')
  }

  const FMT = 'yyyy-MM'

  const getMonthList = (end = getCurrentMonth()) => {
    const fn = (acc) => {
      const [prev] = acc

      const cur = DateTime.fromFormat(prev, FMT).plus({ months: 1 }).toFormat(FMT)
      if (cur > end) return [...acc]
      const next = [cur, ...acc]
      return fn(next)
    }

    return fn([INITIAL_MONTH])
  }

  const formatDate = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).toFormat('yyyy년 M월')
  const formatMonth = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).toFormat('M월')
  const formatNextMonth = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).plus({ months: 1 }).toFormat('M월')

  const select = {
    value: month,
    options: getMonthList().map((value) => ({ value, children: formatDate(value) })),
    onChange: ({ value }) => {
      setMonth(value)
      fetchList(value)
    },
    label: '조회 월 선택',
    caret: <Down />,
    children: `${formatMonth(month)} 결제 내역`,
  }

  const title = (
    <>
      <Select v2 {...select} />
    </>
  )

  return (
    <>
      <div className={styles.my}>
        <div className={styles.summary}>
          <div className={styles.mon}>
            <span>{`${formatNextMonth(month)} 적립 예정금액`}</span>
          </div>
          <div className={styles.current}>
            <div className={styles.amount}>
              <span>{`${format.price(totalCashback)}원`}</span>
            </div>
            <div className={styles.onoff}>
              {status !== 'disabled' ? (
                autoChargeAmount === 500000 ? (
                  <On2P className={styles.on} />
                ) : (
                  <On4P className={styles.on} />
                )
              ) : (
                <></>
              )}
            </div>
          </div>
          {totalCashback >= MAX_CASHBACK && (
            <div className={styles.maxCashback}>
              <Info />
              <span>이번 달 최대 적립금입니다</span>
            </div>
          )}
        </div>
        <div className={styles.banner} onClick={() => goToDetail()}></div>

        <div className={styles.detail}>
          <div className={styles.detail_h}>
            {/* <span>10월 결제 내역</span> */}
            {title}
            {/* <Down className={styles.chevron_right} /> */}
          </div>

          {list.length ? (
            list.map((payment, index) => <MyPaymentsItem payment={payment} />)
          ) : (
            <div className={styles.empty}>
              <div className={styles.empty_img}>
                <Balloon className={styles.bg_balloon_face} />
                <Face className={styles.face} />
              </div>
              <div className={styles.ballon_text}>결제 내역이 없어요</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MyAcp

import { useRef, useEffect } from 'react'

export default (isPlay, callback) => {
  const requestRef = useRef()
  const prevTimeRef = useRef()

  const animate = (time) => {
    if (prevTimeRef.current !== undefined) {
      const delta = time - prevTimeRef.current
      callback(delta)
    }

    prevTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    if (isPlay) {
      requestRef.current = requestAnimationFrame(animate)
      return () => cancelAnimationFrame(requestRef.current)
    } else {
      cancelAnimationFrame(requestRef.current)
    }
    // eslint-disable-next-line
  }, [isPlay])
}

import React, { useContext, useState, useEffect } from 'react'
import Lottie from 'lottie-react-web'
import cx from 'classnames'

import { useBodyClass } from '../../../hooks'

import { ReactComponent as Timer } from './svg/CouponTimer.svg'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const win = 'https://static.chai.finance/event/rps/win@2x.png'
const winConfetti = 'https://static.chai.finance/event/rps/rpsWinConfetti.json'
const winBg = 'https://static.chai.finance/event/rps/rpsWinBg.json'
const merchantLogo00 = 'https://static.chai.finance/event/rps/rpsMerchantLogo00.json'
const merchantLogo01 = 'https://static.chai.finance/event/rps/rpsMerchantLogo01.json'
const merchantLogo02 = 'https://static.chai.finance/event/rps/rpsMerchantLogo02.json'
const couponStatus00 = 'https://static.chai.finance/event/rps/couponStatus00.png'
const couponStatus01 = 'https://static.chai.finance/event/rps/couponStatus01.png'
const couponStatus02 = 'https://static.chai.finance/event/rps/couponStatus02.png'

const InviteeWin = ({ match: { params }, history }) => {
  const { api, rpsConfig, getTimeRemainingByIssuedAt } = useContext(Context)

  const [hash] = useState(params.hash)
  const [statusData, setStatusData] = useState()
  const [showLogo, setShowLogo] = useState(false)

  useBodyClass(styles.rps)

  useEffect(() => {
    getStatusData()

    setTimeout(() => {
      setShowLogo(true)
    }, 500)
    // eslint-disable-next-line
  }, [])

  const getStatusData = async () => {
    try {
      const { version } = rpsConfig
      const { data } = await api.get(`/event/rps/${version}/invitee/${hash}/status`)
      setStatusData(data)

      if (data && data.issuedAt) {
        const { hours, minutes } = getTimeRemainingByIssuedAt(data.issuedAt)

        if (hours <= 0 && minutes <= 0) {
          history.replace('/event/rps/end')
        }
      }
    } catch (error) {}
  }

  const onEnd = async () => {
    window.location.href = 'https://chai.onelink.me/4Gwn/f82e270'
  }

  const getLogoByTimeRemaining = () => {
    if (statusData && statusData.issuedAt) {
      const { hours } = getTimeRemainingByIssuedAt(statusData.issuedAt)

      if (hours >= 24) {
        return merchantLogo00
      }
      if (hours >= 8) {
        return merchantLogo01
      }
      return merchantLogo02
    }
    return merchantLogo00
  }

  const getCouponByTimeRemaining = () => {
    if (statusData && statusData.issuedAt) {
      const { hours } = getTimeRemainingByIssuedAt(statusData.issuedAt)

      if (hours >= 24) {
        return couponStatus00
      }
      if (hours >= 8) {
        return couponStatus01
      }
      return couponStatus02
    }
    return couponStatus00
  }

  const getTextByTimeRemaining = () => {
    const { hours, minutes } = getTimeRemainingByIssuedAt(
      statusData && statusData.issuedAt ? statusData.issuedAt : undefined
    )

    if (hours >= 1) {
      return `${hours}시간`
    } else if (minutes > 0) {
      return `${minutes}분`
    }
    return `0분`
  }

  return (
    <section className={cx(styles.resultWrap, styles.winWrap)}>
      <section className={styles.result}>
        <div className={styles.winAnimation}>
          <Lottie
            options={{
              path: winConfetti,
              loop: false,
              rendererSettings: {
                preserveAspectRatio: 'xMaxYMax slice',
              },
            }}
          />
        </div>
        <div className={styles.winAnimation}>
          <Lottie
            options={{
              path: winBg,
              loop: false,
              rendererSettings: {
                preserveAspectRatio: 'xMaxYMax slice',
              },
            }}
          />
        </div>
        <img className={styles.resultImage} src={win} width={185} height={112} alt="" />
        <p className={cx(styles.resultText, styles.winResultText)}>
          이겼어요!
          <br />
          쿠폰이 녹기 전에 사용해볼까요?
        </p>

        <div className={styles.winTimer}>
          <Timer />
          <p>{getTextByTimeRemaining()} 남음</p>
        </div>

        <img className={styles.winCoupon} src={getCouponByTimeRemaining()} width={248} height={168} alt="" />
        {showLogo && (
          <div className={styles.winLogo}>
            <Lottie
              options={{
                path: getLogoByTimeRemaining(),
                loop: false,
              }}
            />
          </div>
        )}
      </section>

      <section className={styles.bottomSection}>
        <div className={styles.blockButton}>
          <button className={styles.primary} onClick={onEnd}>
            쿠폰 받으러가기
          </button>
        </div>
      </section>
    </section>
  )
}

export default InviteeWin

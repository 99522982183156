import React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from '../../utils'
import styles from './Item.module.scss'

const Item = props => {
  const { account, accountKey: key, requestOnConfirm, onClick, isLast } = props
  const { t } = useTranslation()

  const getActions = () =>
    [
      !account.isPrimary && {
        text: t('계좌:주계좌 설정'),
        onClick: () =>
          requestOnConfirm({
            message: t('계좌:주계좌로 설정합니다'),
            callback: api => api.post('/user/account/primary', { key })
          })
      },
      {
        text: t('계좌:계좌 삭제'),
        onClick: () =>
          requestOnConfirm({
            message: isLast
              ? t(
                  '계좌:주계좌 삭제 시 사용이 제한되며, 계좌 등록 후에 사용하실 수 있습니다. 삭제하시겠습니까?'
                )
              : t('계좌:계좌를 삭제합니다'),
            callback: api => api.delete('/user/account', { headers: { key } }),
            isLastDeleted: isLast
          })
      }
    ].filter(Boolean)

  return (
    <button
      onClick={() => onClick(format.bank(account), getActions())}
      className={styles.item}
    >
      <header className={styles.account}>
        <img src={account.logoUrl} alt="" width={30} height={30} />
        {format.bank(account)}
      </header>

      {account.isPrimary && (
        <span className={styles.primary}>{t('계좌:주계좌')}</span>
      )}
    </button>
  )
}

export default Item

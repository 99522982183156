import React from 'react'
import { string, object, node } from 'prop-types'
import cx from 'classnames'

const propTypes = { className: string, style: object, children: node }
const defaultProps = { className: '', style: {}, children: null }

const Center = ({ className, style, children }) => (
  <div className={cx('center', className)} style={style}>
    {children}
  </div>
)

Center.propTypes = propTypes
Center.defaultProps = defaultProps

export default Center

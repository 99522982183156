import React, { useState, useContext, useEffect, useCallback } from 'react'
import cx from 'classnames'

import { useBodyClass } from '../../../hooks'

import { ReactComponent as ChevronDown24 } from '../../../icons/ChevronDown24.svg'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const rpsCountImages = [
  'https://static.chai.finance/event/rps/0@2x.png',
  'https://static.chai.finance/event/rps/1@2x.png',
  'https://static.chai.finance/event/rps/2@2x.png',
  'https://static.chai.finance/event/rps/3@2x.png',
]
const rpsQMImage = 'https://static.chai.finance/event/rps/questionmark@2x.png'
const rpsList = ['scissors', 'rock', 'paper']
const rpsListKo = ['가위', '바위', '보']
const rpsListStyle = [styles.rollingScissors, styles.rollingRock, styles.rollingPaper]

const RpsPlay = ({ history }) => {
  const { api, handleError, rpsConfig, statusData, getStatusData } = useContext(Context)

  const [isEnd, setIsEnd] = useState(false)
  const [count, setCount] = useState(3)
  const [selectedRps, setSelectedRps] = useState()
  const [playedStatus, setPlayedStatus] = useState()

  useBodyClass(styles.rps)

  const redirectToResult = useCallback(() => {
    switch (playedStatus) {
      case 'win':
        history.replace('/event/rps/win')
        break
      case 'lose':
        history.replace('/event/rps/lose')
        break
      case 'draw':
        history.replace('/event/rps/draw')
        break
      default:
        history.replace('/event/rps')
        break
    }
  }, [history, playedStatus])

  const isAvailable = useCallback(() => {
    if (statusData && ['start', 'draw'].includes(statusData.status)) {
      return true
    }

    return false
  }, [statusData])

  const onSubmit = useCallback(async () => {
    if (!isAvailable()) {
      return false
    }

    try {
      const { version } = rpsConfig
      const { data } = await api.post(`/event/rps/${version}/play`, {
        rps: selectedRps,
      })
      if (data && data.status) {
        setPlayedStatus(data.status)
      }
    } catch (error) {
      handleError(error)
    }
  }, [api, handleError, isAvailable, rpsConfig, selectedRps])

  useEffect(() => {
    getStatusData()

    // eslint-disable-next-line
  }, [])

  // 가위바위보를 선택 후 카운트 다운 & 카운트 다운이 끝나면 서버로 게임 결과 요청
  useEffect(() => {
    if (selectedRps) {
      if (count > 0) {
        // count down 중에 가위바위보 선택을 변경하는 경우 count가 안떨어지는 문제로 인해 0.1초 단위로 처리한다
        const timeoutId = setTimeout(() => {
          setCount(count - 0.1)
        }, 100)
        return () => clearTimeout(timeoutId)
      } else {
        onSubmit()
      }
    }
  }, [selectedRps, count, onSubmit])

  // 카운트 다운이 끝난 후 2초 후 결과를 보여주며 게임을 끝냄
  useEffect(() => {
    if (selectedRps && count <= 0) {
      setTimeout(() => {
        setIsEnd(true)
      }, 2000)
    }
  }, [count, selectedRps])

  // 게임이 끝난 후 2초 후 결과 화면으로 이동
  useEffect(() => {
    if (isEnd) {
      setTimeout(() => {
        redirectToResult()
      }, 2000)
    }
  }, [isEnd, redirectToResult])

  const getRpsResultIndex = () => {
    let foundIndex = rpsList.findIndex((rps) => rps === selectedRps)
    if (playedStatus === 'win') {
      foundIndex += 2
    }
    if (playedStatus === 'lose') {
      foundIndex += 1
    }

    return foundIndex % 3
  }

  const getRpsResult = () => {
    return `${rpsListKo[getRpsResultIndex()]}!`
  }
  const getRpsResultStyle = () => {
    return rpsListStyle[getRpsResultIndex()]
  }

  const selectRps = (rps) => {
    if (count > 0) {
      setSelectedRps(rps)
    }
  }

  return (
    <>
      {!selectedRps && <div className={styles.background}></div>}

      <section className={styles.counterSection}>
        <div className={styles.count}>
          <img src={isEnd ? rpsQMImage : rpsCountImages[Math.ceil(count)]} height="96" alt="" />
        </div>
        <p>{count > 0 ? '안 내면 진 거~ 차이 바위 보!' : !isEnd ? '이제는 바꿀 수 없어요' : getRpsResult()}</p>
      </section>

      <section className={styles.gameSection}>
        <div className={styles.rollingWrap}>
          <div className={cx(styles.rollingBg, isEnd && styles.rollingBg2)}></div>
          <div
            className={cx(
              styles.rolling,
              isEnd ? getRpsResultStyle() : count > 0 ? styles.rollingAnimation : styles.rollingAnimation2,
              selectedRps && !isEnd && styles.rollingStart
            )}
          ></div>
        </div>

        {!selectedRps && (
          <div className={styles.popup}>
            <p className={styles.popupText}>
              가위바위보 중 하나를 <br />
              선택해주세요
            </p>
            <p className={styles.popupText2}>3초동안 선택을 바꿀 수 있어요</p>
            <div className={styles.popupArrow}>
              <ChevronDown24 />
            </div>
          </div>
        )}

        <div className={cx(styles.play, count <= 0 && styles.playDisabled)}>
          <button
            type="button"
            className={selectedRps === 'scissors' ? styles.rpsPrimary : styles.rpsSecondary}
            onClick={() => selectRps('scissors')}
          >
            <img src="https://static.chai.finance/event/rps/scissorsDefault@2x.png" width="91" alt="scissors" />
          </button>
          <button
            type="button"
            className={selectedRps === 'rock' ? styles.rpsPrimary : styles.rpsSecondary}
            onClick={() => selectRps('rock')}
          >
            <img src="https://static.chai.finance/event/rps/rockDefault@2x.png" width="91" alt="rock" />
          </button>
          <button
            type="button"
            className={selectedRps === 'paper' ? styles.rpsPrimary : styles.rpsSecondary}
            onClick={() => selectRps('paper')}
          >
            <img src="https://static.chai.finance/event/rps/paperDefault@2x.png" width="91" alt="paper" />
          </button>
        </div>
      </section>
    </>
  )
}

export default RpsPlay

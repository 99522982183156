import React, { createContext, useState, useEffect, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { path } from 'ramda'
import { DeviceUUID } from 'device-uuid'
import semver from 'semver'
import * as Sentry from '@sentry/browser'

import { isDesktop } from '../env'
import { parseSearchParams, getErrorMessage } from '../utils'
import { useBodyClass } from '../hooks'
import api from '../api'
import graph from '../graph'
import routes from '../routes'
import useAuth from './useAuth'
import useNav from './useNav'

import Disconnected from '../components/Disconnected'
import Nav, { Types as NavTypes } from './Nav'
import Ribbon from './Ribbon'
import './App.scss'

export const AppContext = createContext()
export const UserContext = createContext()
const App = ({ location: { search }, history }) => {
  const { i18n } = useTranslation()

  useBodyClass(isDesktop ? 'large' : '')
  const [theme, setTheme] = useState() // Theme
  const [isAuthReady, setIsAuthReady] = useState(false) // Auth 가져왔는지 여부
  const [isHeadersReady, setIsHeadersReady] = useState(false) // Request Headers
  const [withWeb, setWithWeb] = useState() // 결제 플랫폼 선택 결과
  const [withShortenLink, setWithShortenLink] = useState() // App 이 열리지않을 경우.
  const [key, setKey] = useState(0)

  /* Handler: Network Error */
  const handleError = (error) => {
    const shouldSignout = path(['response', 'data', 'code'], error) === 'A003'
    const message = getErrorMessage(error.response)
    !isNetworkError(error) && Sentry.captureException(error)
    toast.error(message)
    shouldSignout && history.replace('/signout' + search)

    /* 503 */
    const shouldCheckMaintenance = error.response?.status === 503
    shouldCheckMaintenance && checkMaintenance()
  }

  /* 503 */
  const checkMaintenance = async () => {
    try {
      const url = 'https://maintenance.chai.finance/versions.json'
      const { data } = await axios.get(url)
      const onMaintenance = !!data.maintenance.status
      const redirect = () => {
        window.location.href = 'https://maintenance.chai.finance'
      }

      onMaintenance && redirect()
    } catch (e) {}
  }

  /* Provider: App */
  const query = parseSearchParams({ search })
  const { paymentId, publicAPIKey, idempotencyKey, language, version, subscriptionId } = query
  const isPayment = !!paymentId && !!idempotencyKey
  const isSubscription = !!subscriptionId && !!idempotencyKey
  const isV2 = version && semver.gte(semver.valid(semver.coerce(version)), '2.0.0')
  const refresh = () => setKey(key + 1)
  const env = {
    isPayment,
    isSubscription,
    withWeb,
    setWithWeb,
    theme,
    setTheme,
    refresh,
    isV2,
    withShortenLink,
    setWithShortenLink,
  }
  const auth = useAuth({ api, handleError })
  const nav = useNav(NavTypes.NONE)
  const value = { api, query, env, theme, auth, nav, toast, handleError, graph }

  /* Provider: User */
  const [user, setUser] = useState()
  const userValue = {
    user,
    setUser: (v) => setUser(Object.assign({}, user, v)),
  }

  /* API 요청 헤더 설정 */
  useEffect(() => {
    api.interceptors.request.use((config) => {
      config.headers['Chai-Web-Duid'] = new DeviceUUID().get()
      publicAPIKey && (config.headers['Public-API-Key'] = publicAPIKey)
      idempotencyKey && (config.headers['Idempotency-Key'] = idempotencyKey)
      language && (config.headers['Accept-Language'] = language ?? 'ko')
      return config
    })

    setIsHeadersReady(true)
  }, [publicAPIKey, idempotencyKey, language])

  /* Auth 가져오기 */
  useEffect(() => {
    const getAuth = async () => {
      await auth.getAuth({
        shouldSuccess: false,
        signoutIfNotActive: true,
        onAuth: () => setIsAuthReady(true),
      })
    }

    isHeadersReady && getAuth()
    // eslint-disable-next-line
  }, [isHeadersReady])

  /* 언어 설정 */
  useEffect(() => {
    const isEnglish = language && language.includes('en')
    const isKorean = language && language.includes('ko')
    isEnglish && i18n.changeLanguage('en')
    isKorean && i18n.changeLanguage('ko')
    // eslint-disable-next-line
  }, [search])

  /* render */
  const render = () => (
    <>
      {!isV2 && <Nav {...nav} />}
      {isAuthReady && routes}
    </>
  )

  const ribbon = process.env.REACT_APP_RIBBON

  return (
    <AppContext.Provider value={value} key={key}>
      <UserContext.Provider value={userValue}>
        <Suspense fallback={null}>{isHeadersReady && render()}</Suspense>
        <ToastContainer {...ToastConfig} />
        <Disconnected />
        {ribbon && <Ribbon>{ribbon}</Ribbon>}
      </UserContext.Provider>
    </AppContext.Provider>
  )
}

export default App

/* Constant */
const ToastConfig = {
  position: toast.POSITION.TOP_CENTER,
  transition: Slide,
  autoClose: 5000,
  closeButton: false,
  draggable: false,
  hideProgressBar: true,
}

/* helper */
const isNetworkError = (error) => error.hasOwnProperty('response')

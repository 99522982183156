export function __stealien_utility() {
  this.dbg = function(data) {
    if (data !== undefined);
  }
  this.min = function() {
    var min = 999999999
    for (var i = 0; i < arguments.length; i++)
      if (min > arguments[i]) min = arguments[i]
    return min
  }
  this.isMobile = (function() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/.*Mobile.*/i)
    )
      return true
    return false
  })()
  this.setClickHandler = function(elem, func) {
    if (this.isMobile) elem.ontouchstart = func
    else elem.onclick = func
  }
}
var _stealien_utility = new __stealien_utility()
export function _stealien_dbg(data) {
  if (data !== undefined);
}
export function _stealien_setClickHandler(elem, func) {
  if (elem !== undefined);
  if (func !== undefined);
}
var STRandom = function(seed) {
  this.seed = seed
  this.shuffleArray = function(_array) {
    var index
    for (var i = _array.length - 1; i > 0; i--) {
      index = this.nextInt(i + 1)
      if (index !== i) {
        _array[index] ^= _array[i]
        _array[i] ^= _array[index]
        _array[index] ^= _array[i]
      }
    }
    return _array
  }
  this.nextBytes = function(bytes) {
    for (var i = 0; i < bytes.length; i += 4) {
      var random = this.next(32)
      for (var j = 0; i + j < bytes.length && j < 4; j++) {
        bytes[i + j] = random & 255
        random >>= 8
      }
    }
    return bytes
  }
  this.nextLong = function() {
    return (this.next(32) << 32) + this.next(32)
  }
  this.nextInt = function(n) {
    if (n <= 0) return
    if ((n & -n) === n) return (n * this.next(31)) >> 31
    var bits
    var val
    do {
      bits = this.next(31)
      val = bits % n
    } while (bits - val + (n - 1) < 0)
    return val
  }
  this.next = function(_bits) {
    this.seed = (this.seed * 25214903917 + 11) & 0xffffffffffff
    return this.seed >>> (48 - _bits)
  }
}
export var apiLevel1 = function(token) {
  this.random = new STRandom(token)
}
export var KeypadsuitApiManager = function(ver) {
  if (ver !== undefined);
}
export var Keypadsuit = function(publickey, elem) {
  if (elem === undefined) elem = document.createElement('div')
  this.document_old_onclick = undefined
  this.generated_array = undefined
  this.TYPE_NUMPAD = 1
  this.TYPE_QWERTY = 2
  this.shape = 0
  this.elem = elem
  this.multiAttached = false
  this.pk = publickey
  this.isMultiInputbox = false
  this.keydata = ''
  this.shuffle_method = Keypadsuit.prototype.insert_array(2)
  var JSEncrypt = require('jsencrypt').JSEncrypt
  this.encrypt = new JSEncrypt()
  this.encrypt.setPublicKey(this.pk)
  this.onfinishinput = Keypadsuit.prototype.undefined_onfinishinput_error
  this.onfocuslost = undefined
  this.event_dict = {}
  this.attach_elem = undefined
  this.elem_created = false
  this.ok_name = undefined
  this.shift_name = undefined
  this.cancel_name = undefined
  this.back_name = undefined
  this.space_name = undefined
  this.password_length = 999999
  this.force_adjust_ui = false
  this.is_popup = false
  this.is_hide = true
  this._debug = true
  this.attach_elem_origin_func = undefined
  this.force_bottom = _stealien_utility.isMobile
  this.onfinish_if_onblur = false
  this.onfinish_if_empty = true
  this.onfinish_if_filled = false
  this.nohide_if_focusout = false
  this.nohide = false
  this.onclickok = function(keypad) {
    keypad.finish_input()
    keypad.hide()
    this.emit('click', 'ok')
  }
  this.onclickcancel = function(keypad) {
    keypad.keydata = ''
    keypad.sync_input()
    keypad.hide()
    this.emit('click', 'cancel')
  }
  this.onclickback = function(keypad) {
    if (keypad.keydata.length === 0) return
    keypad.keydata = keypad.keydata.substring(0, keypad.keydata.length - 1)
    keypad.sync_input()
    this.emit('click', 'back')
  }
  this.onclickshift = function(keypad) {
    keypad.flap_shape()
    keypad.reload_keypad(this.generate_predata())
    this.emit('click', 'shift')
  }
  this.keypad_type = this.TYPE_NUMPAD
  this.flap_shape = function() {
    this.shape += 1
    if (this.shape % 3 === 0) this.shape = 0
  }
  this.generate_predata = function() {
    if (this.generated_array === undefined) {
      this.generated_array = []
      if (this.keypad_type === this.TYPE_NUMPAD)
        this.generated_array = this.shuffle_method(this.get_array())
      if (this.keypad_type === this.TYPE_QWERTY) {
        var insert_one = this.insert_array(1)
        var insert_two = this.insert_array(2)
        var lower_array = this.get_array()
        var num_array = lower_array.slice(0, 10)
        var qwerty_top = lower_array.slice(10, 20)
        var qwerty_middle = lower_array.slice(20, 29)
        var qwerty_bottom = lower_array.slice(29, 36)
        num_array = insert_one(num_array)
        qwerty_top = insert_one(qwerty_top)
        qwerty_middle = insert_two(qwerty_middle)
        qwerty_bottom = insert_one(qwerty_bottom)
        lower_array = num_array.concat(
          qwerty_top.concat(qwerty_middle.concat(qwerty_bottom))
        )
        this.generated_array.push(lower_array)
        this.flap_shape()
        var upper_array = this.get_array()
        num_array = upper_array.slice(0, 10)
        qwerty_top = upper_array.slice(10, 20)
        qwerty_middle = upper_array.slice(20, 29)
        qwerty_bottom = upper_array.slice(29, 36)
        num_array = insert_one(num_array)
        qwerty_top = insert_one(qwerty_top)
        qwerty_middle = insert_two(qwerty_middle)
        qwerty_bottom = insert_one(qwerty_bottom)
        upper_array = num_array.concat(
          qwerty_top.concat(qwerty_middle.concat(qwerty_bottom))
        )
        this.generated_array.push(upper_array)
        this.flap_shape()
        var special_array = this.get_array()
        var first_plain = special_array.slice(0, 10)
        var second_plain = special_array.slice(10, 19)
        var third_plain = special_array.slice(19, 28)
        var last_plain = special_array.slice(28, 32)
        first_plain = insert_one(first_plain)
        second_plain = insert_two(second_plain)
        third_plain = insert_two(third_plain)
        last_plain = this.insert_array(4)(last_plain)
        special_array = first_plain.concat(
          second_plain.concat(third_plain.concat(last_plain))
        )
        this.generated_array.push(special_array)
        this.flap_shape()
      }
    }
    return this.generated_array
  }
}
export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}
Keypadsuit.prototype.emit = function(event_name, parameter) {
  if (this.event_dict[event_name])
    for (var i = 0; i < this.event_dict[event_name].length; i++)
      this.event_dict[event_name][i](parameter)
}
Keypadsuit.prototype.on = function(event_name, func) {
  if (this.event_dict[event_name] === undefined)
    this.event_dict[event_name] = []
  this.event_dict[event_name].push(func)
}
Keypadsuit.prototype.flushEvent = function(event_name) {
  this.event_dict[event_name] = []
}
Keypadsuit.prototype.get_array = function() {
  if (this.keypad_type === this.TYPE_NUMPAD)
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  if (this.keypad_type === this.TYPE_QWERTY) {
    if (this.shape === 0)
      return [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
        'q',
        'w',
        'e',
        'r',
        't',
        'y',
        'u',
        'i',
        'o',
        'p',
        'a',
        's',
        'd',
        'f',
        'g',
        'h',
        'j',
        'k',
        'l',
        'z',
        'x',
        'c',
        'v',
        'b',
        'n',
        'm'
      ]
    if (this.shape === 1)
      return [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
        'Q',
        'W',
        'E',
        'R',
        'T',
        'Y',
        'U',
        'I',
        'O',
        'P',
        'A',
        'S',
        'D',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'Z',
        'X',
        'C',
        'V',
        'B',
        'N',
        'M'
      ]
    if (this.shape === 2)
      return [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '\x26',
        '*',
        '(',
        ')',
        '`',
        '-',
        '\x3d',
        '\\',
        '[',
        ']',
        ';',
        "'",
        ',',
        '.',
        '/',
        '~',
        '_',
        '+',
        '|',
        '{',
        '}',
        ':',
        '"',
        '\x3c',
        '\x3e',
        '?'
      ]
  }
}
Keypadsuit.prototype.get_current_plain = function() {
  if (this.keypad_type === this.TYPE_NUMPAD) return this.generate_predata()
  return this.generate_predata()[this.shape]
}
Keypadsuit.prototype.detach = function() {
  this.release()
}
Keypadsuit.prototype.release = function() {
  var oldfunc = document.onclick || undefined
  if (oldfunc)
    if (this.document_old_onclick) document.onclick = this.document_old_onclick
  if (this.elem) this.elem.parentNode.removeChild(this.elem)
  if (this.multiAttached)
    for (var i in this.attach_elem.childNodes)
      this.attach_elem.childNodes[i].onfocus = this.attach_elem_origin_func[i]
  else this.attach_elem.onfocus = this.attach_elem_origin_func
}
Keypadsuit.prototype.attach = function() {
  if (arguments.length === 1) this.singleAttach(arguments[0])
  else this.multiAttach(arguments)
}
Keypadsuit.prototype.multiAttach = function(attach_elems) {
  this.multiAttached = true
  var attach_elem = document.createElement('span')
  var isOutFocus = 2
  var FOCUS_IN = 1
  var FOCUS_OUT = 0
  this.attach_elem_origin_func = []
  attach_elems[0].parentNode.insertBefore(attach_elem, attach_elems[0])
  var onFocusCallback = function(oldfunc) {
    isOutFocus = FOCUS_IN
    if (this.parent.is_popup) this.parent.show()
    else this.parent.popup()
    if (oldfunc) oldfunc()
    return false
  }
  for (var i in attach_elems) {
    var inside_input = attach_elems[i]
    var oldfunc = inside_input.onfocus
    this.attach_elem_origin_func.push(oldfunc)
    inside_input.parent = this
    inside_input.onfocus = onFocusCallback(oldfunc)
    attach_elem.parent = this
    attach_elem.appendChild(inside_input)
  }
  attach_elem.parent = this
  oldfunc = document.onclick || undefined
  this.document_old_onclick = oldfunc
  document.onclick = function() {
    if (
      isOutFocus === FOCUS_OUT &&
      attach_elem.parent.is_hide === false &&
      attach_elem.parent.onfocuslost !== undefined
    ) {
      var encrypt = attach_elem.parent.encrypt.encrypt(
        attach_elem.parent.keydata
      )
      attach_elem.parent.onfocuslost(encrypt)
    }
    if (isOutFocus !== FOCUS_IN) {
      if (attach_elem.parent.onfinish_if_onblur)
        attach_elem.parent.finish_input()
      if (attach_elem.parent.onclose !== undefined) attach_elem.parent.onclose()
      if (attach_elem.parent.nohide_if_focusout === false)
        attach_elem.parent.hide()
    }
    if (oldfunc !== undefined) return oldfunc()
    return false
  }
  attach_elem.parentNode.onmouseover = function() {
    isOutFocus = FOCUS_IN
    return false
  }
  attach_elem.parentNode.onmouseleave = function() {
    isOutFocus = FOCUS_OUT
    return false
  }
  this.attach_elem = attach_elem
}
Keypadsuit.prototype.singleAttach = function(attach_elem) {
  attach_elem.parent = this
  var isOutFocus = 2
  var FOCUS_IN = 1
  var FOCUS_OUT = 0
  var oldfunc = attach_elem.onfocus
  this.attach_elem_origin_func = oldfunc
  attach_elem.onfocus = function() {
    isOutFocus = FOCUS_IN
    if (this.parent.is_popup) this.parent.show()
    else this.parent.popup()
    if (oldfunc) return oldfunc()
    return false
  }
  oldfunc = document.onclick || undefined
  this.document_old_onclick = oldfunc
  document.onclick = function() {
    if (
      isOutFocus === FOCUS_OUT &&
      attach_elem.parent.is_hide === false &&
      attach_elem.parent.onfocuslost !== undefined
    ) {
      var encrypt = attach_elem.parent.encrypt.encrypt(
        attach_elem.parent.keydata
      )
      attach_elem.parent.onfocuslost(encrypt)
    }
    if (isOutFocus !== FOCUS_IN) {
      if (attach_elem.parent.onfinish_if_onblur)
        attach_elem.parent.finish_input()
      if (attach_elem.parent.onclose !== undefined) attach_elem.parent.onclose()
      if (attach_elem.parent.nohide_if_focusout === false)
        attach_elem.parent.hide()
    }
    if (oldfunc !== undefined) return oldfunc()
    return false
  }
  attach_elem.parentNode.onmouseover = function() {
    isOutFocus = FOCUS_IN
    return false
  }
  attach_elem.parentNode.onmouseleave = function() {
    isOutFocus = FOCUS_OUT
    return false
  }
  this.attach_elem = attach_elem
}
Keypadsuit.prototype.show = function() {
  if (this.is_hide) if (this.onpopup !== undefined) this.onpopup()
  this.is_hide = false
  this.elem.style.display = 'block'
  this.emit('show', this.elem)
}
Keypadsuit.prototype.hide = function() {
  if (this.nohide === true) return
  this.is_hide = true
  this.elem.style.display = 'none'
  this.emit('hide', this.elem)
}
Keypadsuit.prototype.undefined_onfinishinput_error = function(data) {
  if (data !== undefined);
  alert('call keypadsuit.onfinishinput \x3d function(enc_data){...}')
}
Keypadsuit.setShuffleMethod = function(method) {
  this.shuffle_method = method
}
Keypadsuit.set_shufflemethod = function(method) {
  this.shuffle_method = method
}
Keypadsuit.prototype.insert_array = function(count) {
  return function(arr) {
    for (var i = 0; i < count; i++)
      arr.splice(Math.floor(Math.random() * arr.length), 0, undefined)
    return arr
  }
}
Keypadsuit.prototype.finishInput = function() {
  return this.finishInput()
}
Keypadsuit.prototype.finish_input = function() {
  if (this.keydata.length === 0) this.onfinishinput()
  else {
    var decStr = ''
    for (var i = 0; i < this.keydata.length; i++)
      decStr +=
        '' + String.fromCharCode(Si[this.keydata[i].charCodeAt(0) ^ 85] & 127)
    this.keydata = decStr
    var encrypt = this.encrypt.encrypt(this.keydata)
    this.onfinishinput(encrypt)
  }
}
Keypadsuit.prototype.sync_input = function() {
  var out_field = this.attach_elem || this.input_div
  if (out_field !== undefined) {
    if (this.multiAttached) {
      for (var i = 0; i < this.attach_elem.childNodes.length; i++)
        this.attach_elem.childNodes[i].value = ''
      var loopCount = _stealien_utility.min(
        this.attach_elem.childNodes.length,
        this.keydata.length
      )
      for (var j = 0; j < loopCount; j++)
        this.attach_elem.childNodes[j].value = '*'
    } else if (out_field.tagName !== 'INPUT') {
      out_field.innerHTML = ''
      for (var k = 0; k < this.keydata.length; k++) out_field.innerHTML += '*'
    } else {
      out_field.value = ''
      for (var m = 0; m < this.keydata.length; m++) out_field.value += '*'
    }
    if (this.onfinish_if_filled)
      if (this.keydata.length === this.password_length) {
        this.finish_input()
        this.hide()
      }
  }
}
Keypadsuit.prototype.shuffle_array = function(arr) {
  arr.push(undefined)
  arr.push(undefined)
  for (var i = arr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  }
  return arr
}
Keypadsuit.prototype.create_keypad = function(key_value) {
  if (typeof key_value == 'object') return key_value
  var ret = document.createElement('div')
  this.show()
  ret.parent = this
  if (key_value !== undefined) ret.innerHTML = key_value
  else ret.innerHTML = '\x26nbsp;'
  if (key_value === undefined) ret.hexcode = 'null'
  else ret.hexcode = key_value.charCodeAt(0).toString(16)
  _stealien_utility.setClickHandler(ret, function() {
    var key = key_value
    if (key === undefined) return
    if (ret.parent.keydata.length === ret.parent.password_length) return false
    key = String.fromCharCode((S[key.charCodeAt(0)] ^ 85) & 255)
    ret.parent.keydata += '' + key
    ret.parent.sync_input()
    ret.parent.emit('click', key)
  })
  return ret
}
Keypadsuit.prototype.clear = function() {
  this.keydata = ''
  this.sync_input()
}
Keypadsuit.prototype.build_button_list = function() {
  var button_list = []
  var arr = this.get_current_plain()
  for (var i in arr) {
    var keypad = this.create_keypad(arr[i])
    keypad.className += ' _stealien_keybutton'
    button_list.push(keypad)
  }
  return button_list
}
Keypadsuit.prototype.create_ok = function() {
  var ok = document.createElement('div')
  ok.className += '_stealien_keybutton _stealien_special_keybutton'
  ok.innerHTML = this.ok_name || 'Enter'
  ok.parent = this
  ok.hexcode = 'ok'
  ok.is_special = true
  if (this.keypad_type === this.TYPE_QWERTY) ok.width_weight = 3
  _stealien_utility.setClickHandler(ok, function() {
    if (!this.parent.onfinish_if_empty && this.parent.keydata.length === 0)
      return
    return this.parent.onclickok(this.parent)
  })
  return ok
}
Keypadsuit.prototype.create_cancel = function() {
  var cancel = document.createElement('div')
  cancel.className += '_stealien_keybutton _stealien_special_keybutton'
  cancel.innerHTML = this.cancel_name || 'Cancel'
  cancel.parent = this
  if (this.keypad_type === this.TYPE_QWERTY) cancel.width_weight = 4
  cancel.hexcode = 'cancel'
  cancel.is_special = true
  _stealien_utility.setClickHandler(cancel, function() {
    this.parent.onclickcancel(this.parent)
    if (this.parent.oncancel !== undefined) return this.parent.oncancel()
  })
  return cancel
}
Keypadsuit.prototype.create_space = function() {
  var space = document.createElement('div')
  space.className += '_stealien_keybutton _stealien_special_keybutton'
  space.innerHTML = this.space_name || '\u2423'
  space.parent = this
  space.width_weight = 4
  space.hexcode = 'space'
  space.is_special = true
  _stealien_utility.setClickHandler(space, function() {
    var key = ' '
    if (space.parent.keydata.length === space.parent.password_length)
      return false
    key = String.fromCharCode((S[key.charCodeAt(0)] ^ 85) & 255)
    space.parent.keydata += '' + key
    space.parent.sync_input()
    space.parent.emit('click', 'space')
  })
  return space
}
Keypadsuit.prototype.create_shift = function() {
  var shift = document.createElement('div')
  shift.className += '_stealien_keybutton _stealien_special_keybutton'
  shift.innerHTML = '\x26nbsp'
  shift.parent = this
  shift.width_weight = 1
  shift.hexcode = 'shift'
  shift.is_special = true
  if (this.keypad_type === this.TYPE_QWERTY)
    shift.innerHTML = this.shift_name || '\u21e7'
  _stealien_utility.setClickHandler(shift, function() {
    return this.parent.onclickshift(this.parent)
  })
  return shift
}
Keypadsuit.prototype.create_back = function() {
  var back = document.createElement('div')
  back.className += '_stealien_keybutton _stealien_special_keybutton'
  back.innerHTML = this.back_name || '\u232b'
  back.is_special = true
  back.parent = this
  if (this.keypad_type === this.TYPE_QWERTY) back.width_weight = 2
  back.hexcode = 'back'
  _stealien_utility.setClickHandler(back, function() {
    return this.parent.onclickback(this.parent)
  })
  return back
}
Keypadsuit.prototype.reload_keypad = function(arr) {
  this.elem.innerHTML = ''
  this.elem.onmousedown = function() {
    return false
  }
  var button_list = this.build_button_list()
  var ok = this.create_ok()
  var cancel = this.create_cancel()
  if (arr !== undefined);
  var count_in_row = 4
  if (this.keypad_type === this.TYPE_QWERTY) count_in_row = 11
  if (this.keypad_type === this.TYPE_QWERTY) {
    button_list.splice(33, 0, this.create_shift())
    button_list.splice(42, 0, this.create_back())
  }
  if (this.keypad_type === this.TYPE_NUMPAD) {
    button_list.push(this.create_back())
    button_list.push(this.create_shift())
  }
  button_list.push(cancel)
  if (this.keypad_type === this.TYPE_QWERTY)
    button_list.push(this.create_space())
  button_list.push(ok)
  this.elem.className += ' _stealien_keypad_border'
  if (this.attach_elem !== undefined) {
    this.elem.style.top =
      this.attach_elem.style.top + this.attach_elem.style.height
    this.elem.style.left = this.attach_elem.getBoundingClientRect().left
    this.attach_elem.parentNode.insertBefore(
      this.elem,
      this.attach_elem.nextSibling
    )
    if (this.force_bottom) {
      this.elem.style.position = 'fixed'
      this.elem.style.bottom = '0px'
      this.elem.style.left = '0px'
    }
    var btn_size = this.elem.getBoundingClientRect().width / count_in_row
    var height_weight = 1
    if (this.keypad_type === this.TYPE_QWERTY) height_weight = 2
    if (this.force_adjust_ui)
      this.elem.style.width = btn_size * count_in_row + 'px'
    this.emit('updatestyle', this.elem)
    for (var btn in button_list) {
      if (button_list[btn].hexcode !== undefined) {
        if (this.force_adjust_ui) {
          var weight = button_list[btn].width_weight || 1
          button_list[btn].style.width = btn_size * weight + 'px'
          button_list[btn].style.height = btn_size * height_weight + 'px'
          button_list[btn].style.lineHeight = btn_size * height_weight + 'px'
          button_list[btn].style.fontSize = btn_size * 0.8 + 'px'
          this.emit('updatebuttonstyle', button_list[btn])
        }
        button_list[btn].className +=
          ' _stealien_keybutton_' + button_list[btn].hexcode
      }
      this.elem.appendChild(button_list[btn])
    }
    this.elem_created = true
  }
  if (!this.elem_created) {
    while (this.elem.firstChild) this.elem.removeChild(this.elem.firstChild)
    for (var btn1 in button_list) this.elem.appendChild(button_list[btn1])
    document.body.appendChild(this.elem)
    this.elem_created = true
  }
}
Keypadsuit.prototype.popup = function() {
  this.is_popup = true
  this.is_hide = true
  this.elem.innerHTML = ''
  if (this.attach_elem === undefined) {
    var input_div = document.createElement('div')
    input_div.innerHTML = '\x26nbsp;'
    this.input_div = input_div
    input_div.className += ' _stealien_inputdiv'
    this.elem.appendChild(input_div)
  }
  this.reload_keypad(this.generate_predata())
}
Keypadsuit.prototype.constructor = Keypadsuit
var S = [
  99,
  124,
  119,
  123,
  242,
  107,
  111,
  197,
  48,
  1,
  103,
  43,
  254,
  215,
  171,
  118,
  202,
  130,
  201,
  125,
  250,
  89,
  71,
  240,
  173,
  212,
  162,
  175,
  156,
  164,
  114,
  192,
  183,
  253,
  147,
  38,
  54,
  63,
  247,
  204,
  52,
  165,
  229,
  241,
  113,
  216,
  49,
  21,
  4,
  199,
  35,
  195,
  24,
  150,
  5,
  154,
  7,
  18,
  128,
  226,
  235,
  39,
  178,
  117,
  9,
  131,
  44,
  26,
  27,
  110,
  90,
  160,
  82,
  59,
  214,
  179,
  41,
  227,
  47,
  132,
  83,
  209,
  0,
  237,
  32,
  252,
  177,
  91,
  106,
  203,
  190,
  57,
  74,
  76,
  88,
  207,
  208,
  239,
  170,
  251,
  67,
  77,
  51,
  133,
  69,
  249,
  2,
  127,
  80,
  60,
  159,
  168,
  81,
  163,
  64,
  143,
  146,
  157,
  56,
  245,
  188,
  182,
  218,
  33,
  16,
  255,
  243,
  210,
  205,
  12,
  19,
  236,
  95,
  151,
  68,
  23,
  196,
  167,
  126,
  61,
  100,
  93,
  25,
  115,
  96,
  129,
  79,
  220,
  34,
  42,
  144,
  136,
  70,
  238,
  184,
  20,
  222,
  94,
  11,
  219,
  224,
  50,
  58,
  10,
  73,
  6,
  36,
  92,
  194,
  211,
  172,
  98,
  145,
  149,
  228,
  121,
  231,
  200,
  55,
  109,
  141,
  213,
  78,
  169,
  108,
  86,
  244,
  234,
  101,
  122,
  174,
  8,
  186,
  120,
  37,
  46,
  28,
  166,
  180,
  198,
  232,
  221,
  116,
  31,
  75,
  189,
  139,
  138,
  112,
  62,
  181,
  102,
  72,
  3,
  246,
  14,
  97,
  53,
  87,
  185,
  134,
  193,
  29,
  158,
  225,
  248,
  152,
  17,
  105,
  217,
  142,
  148,
  155,
  30,
  135,
  233,
  206,
  85,
  40,
  223,
  140,
  161,
  137,
  13,
  191,
  230,
  66,
  104,
  65,
  153,
  45,
  15,
  176,
  84,
  187,
  22
]
var Si = [
  82,
  9,
  106,
  213,
  48,
  54,
  165,
  56,
  191,
  64,
  163,
  158,
  129,
  243,
  215,
  251,
  124,
  227,
  57,
  130,
  155,
  47,
  255,
  135,
  52,
  142,
  67,
  68,
  196,
  222,
  233,
  203,
  84,
  123,
  148,
  50,
  166,
  194,
  35,
  61,
  238,
  76,
  149,
  11,
  66,
  250,
  195,
  78,
  8,
  46,
  161,
  102,
  40,
  217,
  36,
  178,
  118,
  91,
  162,
  73,
  109,
  139,
  209,
  37,
  114,
  248,
  246,
  100,
  134,
  104,
  152,
  22,
  212,
  164,
  92,
  204,
  93,
  101,
  182,
  146,
  108,
  112,
  72,
  80,
  253,
  237,
  185,
  218,
  94,
  21,
  70,
  87,
  167,
  141,
  157,
  132,
  144,
  216,
  171,
  0,
  140,
  188,
  211,
  10,
  247,
  228,
  88,
  5,
  184,
  179,
  69,
  6,
  208,
  44,
  30,
  143,
  202,
  63,
  15,
  2,
  193,
  175,
  189,
  3,
  1,
  19,
  138,
  107,
  58,
  145,
  17,
  65,
  79,
  103,
  220,
  234,
  151,
  242,
  207,
  206,
  240,
  180,
  230,
  115,
  150,
  172,
  116,
  34,
  231,
  173,
  53,
  133,
  226,
  249,
  55,
  232,
  28,
  117,
  223,
  110,
  71,
  241,
  26,
  113,
  29,
  41,
  197,
  137,
  111,
  183,
  98,
  14,
  170,
  24,
  190,
  27,
  252,
  86,
  62,
  75,
  198,
  210,
  121,
  32,
  154,
  219,
  192,
  254,
  120,
  205,
  90,
  244,
  31,
  221,
  168,
  51,
  136,
  7,
  199,
  49,
  177,
  18,
  16,
  89,
  39,
  128,
  236,
  95,
  96,
  81,
  127,
  169,
  25,
  181,
  74,
  13,
  45,
  229,
  122,
  159,
  147,
  201,
  156,
  239,
  160,
  224,
  59,
  77,
  174,
  42,
  245,
  176,
  200,
  235,
  187,
  60,
  131,
  83,
  153,
  97,
  23,
  43,
  4,
  126,
  186,
  119,
  214,
  38,
  225,
  105,
  20,
  99,
  85,
  33,
  12,
  125
]

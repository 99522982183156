import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './List'
import Agreement from './Agreement'

const Agreed = ({ match }) => (
  <Switch>
    <Route path={match.url + '/:id'} component={Agreement} />
    <Route path={match.url} component={List} />
  </Switch>
)

export default Agreed

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './List'

const Payments = ({ match }) => (
  <Switch>
    <Route path={match.url} component={List} />
  </Switch>
)

export default Payments

import React, { useRef } from 'react'
import { bool, object, func } from 'prop-types'
import InputGroup from './InputGroup'
import styles from './CodeRequest.module.scss'

const propTypes = {
  values: object.isRequired, // 이 폼의 입력 값들
  handleChange: func.isRequired, // 이 폼의 입력 이벤트 핸들러
  onSubmit: func.isRequired, // 제출
  invalid: bool.isRequired, // 밸리데이션 결과
  isSubmitting: bool.isRequired // 네트워크 응답 대기 중인지 여부
}

/* 인증코드 요청: 이름과 주민등록번호 */
const CodeRequest = props => {
  const { values, setValues, onSubmit, isSubmitting, invalid } = props
  const birthRef = useRef()
  const socialGenderRef = useRef()

  const handleChange = e => {
    // 생년월일 입력이 끝나면 포커스 이동
    const { name, value } = e.target
    name === 'birth' && value.length === 6 && socialGenderRef.current.focus()
    props.handleChange(e)
  }

  const handleKeyDown = e => {
    const next = () => {
      const { birth } = values
      setValues({ birth: birth.slice(0, birth.length - 1) })
      birthRef.current.focus()
    }

    const { value } = e.target
    e.key === 'Backspace' && !value.length && next()
  }

  return (
    <form onSubmit={onSubmit}>
      <label>이름</label>
      <InputGroup>
        {attrs => (
          <input
            {...attrs}
            type="text"
            name="name"
            value={values['name']}
            onChange={handleChange}
            readOnly={isSubmitting}
            placeholder="김차이"
            className="input"
            autoComplete="off"
            autoFocus
          />
        )}
      </InputGroup>

      <label>주민등록번호</label>
      <InputGroup>
        {attrs => (
          <div className={styles.SocialNumber}>
            <section className={styles.head}>
              <input
                {...attrs}
                type="tel"
                name="birth"
                value={values['birth']}
                onChange={handleChange}
                maxLength={6}
                readOnly={isSubmitting}
                placeholder="910423"
                className="input"
                autoComplete="off"
                ref={birthRef}
              />
            </section>

            <span className={styles.hyphen} />

            <section className={styles.tail}>
              <input
                {...attrs}
                type="tel"
                name="socialGender"
                value={values['socialGender']}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                maxLength={1}
                readOnly={isSubmitting}
                placeholder="_"
                className="input"
                autoComplete="off"
                ref={socialGenderRef}
              />

              <span className={styles.bullet} />
              <span className={styles.bullet} />
              <span className={styles.bullet} />
              <span className={styles.bullet} />
              <span className={styles.bullet} />
              <span className={styles.bullet} />
            </section>
          </div>
        )}
      </InputGroup>

      <button
        type="submit"
        onClick={onSubmit}
        disabled={invalid}
        className="btn btn-primary btn-block btn-submit"
      >
        인증번호 요청
      </button>
    </form>
  )
}

CodeRequest.propTypes = propTypes

export default CodeRequest

import React from 'react'
import Article from '../../components/Article'
import styles from './Privacy.module.scss'

const TosUpdate = () => (
  <Article title="차이 개인정보 이용내역 통지 안내" className={styles.article}>
    <p>안녕하세요. 항상 차이서비스를 이용해주셔서 감사합니다.</p>
    <p>
      「정보통신망 이용촉진 및 정보보호 등에 관한 법률 제30조의2」에 따라
      차이서비스 회원의 개인정보 이용내역을 다음과 같이 안내해 드립니다.
    </p>

    <article>
      <h2>1. 개인정보 수집·이용 현황</h2>
      <p>
        차이는 서비스 제공에 필요한 최소한의 개인정보를 필수정보와 선택정보로
        구분하여 아래와 같이 수집 및 이용합니다.
      </p>

      <table>
        <thead>
          <tr>
            <th>구분</th>
            <th>수집 및 이용목적</th>
            <th>수집 및 이용항목</th>
            <th>보유 및 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>(필수정보) 회원가입 및 본인확인</td>
            <td>회원제 서비스에 따른 본인확인 및 회원관리</td>
            <td>이름, 휴대폰번호, 생년월일, 성별, 본인확인정보(CI, DI)</td>
            <td>회원탈퇴 시까지</td>
          </tr>
          <tr>
            <td>(필수정보) 은행계죄 등록</td>
            <td>차이포인트 사용을 위한 회원의 계좌등록</td>
            <td>예금주명(이름), 은행명, 계좌번호, 휴대폰번호</td>
            <td>회원탈퇴 시까지</td>
          </tr>
          <tr>
            <td>(필수정보) 고객민원 처리</td>
            <td>회원의 서비스 이용상담, 민원 등의 처리</td>
            <td>이름, 휴대폰번호, 생년월일, 성별</td>
            <td>회원탈퇴 시까지</td>
          </tr>
          <tr>
            <td>(필수정보) 현금영수증 발급</td>
            <td>현금영수증 발급 처리</td>
            <td>휴대폰번호, 현금영수증 카드번호</td>
            <td>회원탈퇴 시까지</td>
          </tr>
          <tr>
            <td>(필수정보) 서비스 부정확인</td>
            <td>서비스 부정이용 방지를 위한 정보 확인</td>
            <td>
              이름, 휴대폰번호, 접속 단말기 종류, OS유형, 단말기식별정보(UUID,
              IMEI)
            </td>
            <td>회원탈퇴 시까지</td>
          </tr>
        </tbody>
      </table>
    </article>

    <article>
      <h2>2. 개인정보 처리 위탁</h2>
      <p>
        차이는 아래와 같은 업무를 위탁하고 있으며, 업무에 필요한 최소한의
        개인정보만을 제공합니다.
      </p>
      <table>
        <thead>
          <tr>
            <th>수탁업체명</th>
            <th>위탁한 업무내용</th>
            <th>개인정보 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>㈜트랜스코스모스코리아</td>
            <td>고객센터 운영 및 고객상담</td>
            <td>회원탈퇴 시 또는 위탁계약 종료 시까지</td>
          </tr>
          <tr>
            <td>㈜쿠콘</td>
            <td>
              선불전자지급수단 서비스를 위한 ARS 출금동의 인증, 예금주 유효성
              체크, 출금요청 중계, 현금영수증 발행을 위한 데이터 전송 중계
            </td>
            <td>회원탈퇴 시 또는 위탁계약 종료 시까지</td>
          </tr>
          <tr>
            <td>세틀뱅크㈜</td>
            <td>
              선불전자지급수단 서비스를 위한 ARS 출금동의 인증, 예금주 유효성
              체크, 출금요청 중계를 위한 데이터 전송 중계
            </td>
            <td>회원탈퇴 시 또는 위탁계약 종료 시까지</td>
          </tr>
          <tr>
            <td>㈜KG모빌리언스</td>
            <td>본인확인을 위한 휴대폰 인증 확인</td>
            <td>회원탈퇴 시 또는 위탁계약 종료 시까지</td>
          </tr>
          <tr>
            <td>네이버 비즈니스 플랫폼㈜</td>
            <td>문자서비스 및 카카오톡 알림톡 발송</td>
            <td>회원탈퇴 시 또는 위탁계약 종료 시까지</td>
          </tr>
        </tbody>
      </table>
    </article>

    <article>
      <h2>3. 개인정보 제3자 제공</h2>
      <p>
        차이는 회원들의 개인정보를 고지한 범위내에서만 사용하며, 회원의 사전동의
        없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 외부에
        공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
      </p>
      <ul>
        <li>- 회원이 사전에 동의한 경우</li>
        <li>- 서비스 제공에 따른 요금정산을 위해 필요한 경우</li>
        <li>
          - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
          따라 수사기관의 요구가 있는 경우
        </li>
      </ul>
    </article>

    <p>
      차이는 회원님의 소중한 개인정보를 안전하게 관리하여, 보다 편리하게
      서비스를 이용하실 수 있도록 최선을 다하겠습니다.
    </p>
    <p>
      * 본 안내는 법령에 따라 푸시(PUSH) 등의 광고성 정보 수신동의 여부와
      무관하게 발송되며, 개인정보에 대한 보다 자세한 사항은 차이
      개인정보처리방침을 통해 언제든 확인하실 수 있습니다.
    </p>
  </Article>
)

export default TosUpdate

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import URLSearchParams from '@ungap/url-search-params'
import cx from 'classnames'

import { ReactComponent as LuckyCashback } from './images/lucky_cashback.svg'
import { ReactComponent as LuckyCashbackSmall } from './images/lucky_cashback_small.svg'

import { useApp } from '../../hooks'

import Result from './Result'

import styles from './Lottery.module.scss'

const Lottery = () => {
  /* url */
  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const id = sp.get('id')
  const url = `/lucky/${id}`

  /* state */
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState() // 가격이 있다면 뒤집혔다는 뜻
  const [errorMessage, setErrorMessage] = useState('')

  const { api, handleError } = useApp()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.get(url)
        if (data && data.benefitAmount) {
          // 이미 뒤집힌 경우, get 응답에도 가격이 있다.
          setPrice(data.benefitAmount)
        }

        setIsLoading(false)
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message
        setErrorMessage(errorMessage.replace(/\s*\([a-z0-9]+\)$/i, ''))
      }
    }

    fetchData()
  }, [api, url])

  const onClick = async () => {
    if (typeof price !== 'number') {
      try {
        const { data } = await api.post(url)
        setPrice(data.benefitAmount)
      } catch (error) {
        handleError(error)
      }
    }
  }

  if (errorMessage) {
    return (
      <div className={cx(styles.bg, styles.errorBg)}>
        <div className={styles.resultCenter}>
          <LuckyCashbackSmall />
          <div className={styles.errorText}>{errorMessage}</div>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <div className={styles.bg}></div>
  }

  return (
    <div className={styles.bg}>
      <div className={styles.center}>
        <LuckyCashback />
        <div className={styles.buttonWrapper}>
          <button onClick={onClick}>당첨금 확인하기</button>
        </div>
      </div>
      <Result price={price} />
    </div>
  )
}

export default Lottery

import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { DateTime } from 'luxon'
import { format } from '../../utils'
import styles from './Details.module.scss'

const Details = ({ details, className, compact }) => {
  const { merchant, description, startAt, endAt, priceMin } = details
  const { t } = useTranslation()

  const renderFull = () => {
    const price = format.price(priceMin)
    const list = [
      [t('쿠폰:사용 매장'), merchant.name],
      [t('쿠폰:사용 기간'), [startAt, endAt].map(formatDate).join(' - ')],
      [t('쿠폰:사용 조건'), t('쿠폰:{{price}}원 이상 결제 시 적용', { price })],
      [t('쿠폰:쿠폰 설명'), description],
    ].filter(Boolean)

    return list.map(([title, content]) => (
      <li className={styles.item} key={title}>
        <article>
          <h1>{title}</h1>
          <p>{content}</p>
        </article>
      </li>
    ))
  }

  const renderCompact = () => (
    <li className={styles.item}>
      <p className={styles.compact}>{description}</p>
    </li>
  )

  return (
    <ul className={cx(styles.list, className)}>
      {compact ? renderCompact() : renderFull()}
    </ul>
  )
}

export default Details

/* utils */
const formatDate = (s) => (s ? DateTime.fromISO(s).toFormat('MM.dd') : '')

import React, { useContext } from 'react'

import { useBodyClass } from '../../../hooks'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const RpsNotice = ({ history }) => {
  const { rpsConfig } = useContext(Context)

  useBodyClass(styles.rps)

  const notices = rpsConfig.text.notice.notices.map((notice) => <li>{notice}</li>)

  return (
    <section className={styles.noticeSectionWrap}>
      <section className={styles.noticeSection}>
        <h4>차이바위보 유의사항</h4>
        <ul>{notices}</ul>
      </section>

      <section className={styles.bottomSection}>
        <div className={styles.blockButton}>
          <button className={styles.primary} onClick={() => history.goBack()}>
            확인
          </button>
        </div>
      </section>
    </section>
  )
}

export default RpsNotice

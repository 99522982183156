import React from 'react'
import { useTranslation } from 'react-i18next'

const Guide = () => {
  const { t } = useTranslation()

  return (
    <article>
      <p>
        {t(
          '인증:쿠키가 차단되어 로그인에 실패했습니다. 브라우저 설정을 바꾸고 브라우저를 재시작하면 문제가 해결될 수 있습니다.'
        )}
      </p>

      <ul>
        <li>Safari: {t('인증:"크로스 사이트 추적 방지"를 해제')}</li>
        <li>
          Chrome:{' '}
          {t('인증:"사이트에서 쿠키 데이터를 저장하고 읽도록 허용"을 허용')}
        </li>
      </ul>
    </article>
  )
}

export default Guide

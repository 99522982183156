import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useNav, NavTypes } from '../../hooks'
import { ReactComponent as Icon1 } from './Icon1.svg'
import { ReactComponent as Icon2 } from './Icon2.svg'
import { ReactComponent as Icon3 } from './Icon3.svg'
import { ReactComponent as Icon4 } from './Icon4.svg'
import Page from '../../components/Page'
import styles from './Help.module.scss'

const Help = ({ match, location: { search } }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })

  return (
    <Page title="고객센터">
      <p className={styles.desc}>
        1544-7839 | help@chai.finance
        <br />
        {t('FAQ:평일')} 09:00 - 18:00 ({t('FAQ:점심')} 13:00 - 14:00)
        <br />
        {t('토, 일요일 및 공휴일은 휴무입니다.')}
      </p>
      <Link to={{ pathname: '/help/faq', search }} className={styles.box}>
        <div className={styles.circle}>
          <Icon1 />
        </div>
        자주 묻는 질문
      </Link>
      <a href="tel:1544-7839" className={styles.box}>
        <div className={styles.circle}>
          <Icon2 />
        </div>
        {t('FAQ:전화문의')}
      </a>
      <Link to={{ pathname: '/chat_update', search }} className={styles.box}>
        <div className={styles.circle}>
          <Icon3 />
        </div>
        1:1 {t('FAQ:채팅 문의')}
      </Link>
      <a href="mailto:help@chai.finance" className={styles.box}>
        <div className={styles.circle}>
          <Icon4 />
        </div>
        {t('FAQ:이메일문의')}
      </a>
    </Page>
  )
}

export default Help

import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Status } from '../../Constants'
import { format } from '../../utils'
import { NavTypes, useNav, WithRequest } from '../../hooks'
import Page from '../../components/Page'
import styles from './Receipt.module.scss'

const Receipt = ({ match }) => {
  const { t } = useTranslation()
  const { paymentId } = match.params
  const isUser = !!paymentId // false: 가맹점
  const url = isUser ? `/user/payment/${paymentId}/receipt` : '/payment/receipt'
  useNav(isUser && NavTypes.GO_BACK, { toApp: true })

  return (
    <WithRequest url={url}>
      {(r) =>
        r && (
          <Page title={t('영수증:영수증')} mainClassName={styles.page}>
            <section className={styles.section}>
              <h1 className={styles.title}>{t('영수증:구매 정보')}</h1>
              <dl className={styles.dl}>
                <dt>{t('영수증:주문명')}</dt>
                <dd>{r.description}</dd>
                <dt>{t('영수증:주문 번호')}</dt>
                <dd>{r.idempotencyKey}</dd>
                {r.metadata && r.metadata.approvalNo && (
                  <>
                    <dt>{t('영수증:승인 번호')}</dt>
                    <dd>{r.metadata.approvalNo}</dd>
                  </>
                )}
              </dl>
            </section>

            <section className={styles.section}>
              <h1 className={styles.title}>{t('영수증:결제 정보')}</h1>
              <dl className={styles.dl}>
                {r.charge && (
                  <>
                    <dt>{t('영수증:결제 계좌')}</dt>
                    <dd>{format.bank(r.charge)}</dd>
                  </>
                )}
                <dt>{t('영수증:결제 상태')}</dt>
                <dd>{t('영수증:' + getMessages(r.displayStatus))}</dd>
                <dt>{t('영수증:결제 일시')}</dt>
                <dd>{format.date(r.createdAt, t)}</dd>
                {r.disposalDepositAmount > 0 && (
                  <>
                    <dt>{t('영수증:일회용품 보증금')}</dt>
                    <dd>
                      {t('통화:{{price}}원', {
                        price: format.price(r.disposalDepositAmount),
                      })}
                    </dd>
                  </>
                )}
                <dt>{t('영수증:주문 금액')}</dt>
                <dd>
                  {r.initial &&
                    t('통화:{{price}}원', {
                      price: format.price(Number(r.initial.checkoutAmount) - Number(r.disposalDepositAmount || 0)),
                    })}
                </dd>

                {r.exchange && Object.entries(r.exchange).length > 0 && (
                  <>
                    <div className={cx(styles.dl, styles.gubun)}></div>
                    <dt>{t('영수증:현지 결제금액')}</dt>
                    <dd>
                      {t('통화:{{price}} {{currency}}', {
                        price: format.dollar(r.exchange.totalAmount),
                        currency: r.exchange.currency,
                      })}
                    </dd>
                    {r.exchange.exchangeRate && (
                      <>
                        <dt>{t('영수증:환율')}</dt>
                        <dd>
                          {t('통화:{{price}}원', {
                            price: format.price(r.exchange.exchangeRate),
                          })}
                        </dd>
                      </>
                    )}
                    {r.exchange.totalFeeKrw && (
                      <>
                        <dt>{t('영수증:수수료')}</dt>
                        <dd>
                          {t('통화:{{price}}원', {
                            price: format.price(r.exchange.totalFeeKrw),
                          })}
                        </dd>
                      </>
                    )}
                    <div className={cx(styles.dl, styles.gubun)}></div>
                  </>
                )}

                {r.initial && !!r.initial.discountAmount && (
                  <>
                    <dt>{t('영수증:할인 금액')}</dt>
                    <dd>
                      -
                      {t('통화:{{price}}원', {
                        price: format.price(r.initial.discountAmount),
                      })}
                    </dd>
                    <dt>{t('영수증:결제 금액')}</dt>
                    <dd>
                      {t('통화:{{price}}원', {
                        price: format.price(r.initial.billingAmount),
                      })}
                    </dd>
                  </>
                )}

                {!!r.canceledBillingAmount && (
                  <>
                    <dt>{t('영수증:취소 금액')}</dt>
                    <dd>
                      -
                      {t('통화:{{price}}원', {
                        price: format.price(r.canceledBillingAmount),
                      })}
                    </dd>
                    <dt>{t('영수증:취소 일시')}</dt>
                    <dd>{format.date(r.updatedAt, t)}</dd>
                  </>
                )}
              </dl>

              {!!r.canceledBillingAmount && (
                <dl className={cx(styles.dl, styles.total)}>
                  <dt>{t('영수증:총 결제 금액')}</dt>
                  <dd>
                    {t('통화:{{price}}원', {
                      price: format.price(r.billingAmount),
                    })}
                  </dd>
                </dl>
              )}
            </section>

            {r.merchant && !(r.metadata && (r.metadata.isOverseasPurchase || r.metadata.isPublicTransportation)) && (
              <section className={styles.section}>
                <h1 className={styles.title}>{t('영수증:판매처 정보')}</h1>
                <dl className={styles.dl}>
                  <dt>{t('영수증:판매자 상호')}</dt>
                  <dd>{r.merchant.name}</dd>
                  <dt>{t('영수증:사업자등록번호')}</dt>
                  <dd>{r.merchant.businessNumber}</dd>
                  {r.merchant.representativeName && (
                    <>
                      <dt>{t('영수증:대표자 명')}</dt>
                      <dd>{r.merchant.representativeName}</dd>
                    </>
                  )}
                  {r.merchant.address && (
                    <>
                      <dt>{t('영수증:사업자주소')}</dt>
                      <dd>{r.merchant.address}</dd>
                    </>
                  )}
                  {r.merchant.customerServicePhone && (
                    <>
                      <dt>{t('영수증:문의처')}</dt>
                      <dd>{r.merchant.customerServicePhone}</dd>
                    </>
                  )}
                </dl>
              </section>
            )}

            {r.cashReceipt &&
              Array.isArray(r.cashReceipt.approve) &&
              r.cashReceipt.approve.map((c, index) => (
                <section className={styles.section} key={index}>
                  <h1 className={styles.title}>{t('영수증:현금영수증 발행정보')}</h1>
                  <dl className={styles.dl}>
                    <dt>{t('영수증:발행 금액')}</dt>
                    <dd>
                      {t('통화:{{price}}원', {
                        price: format.price(c.totalAmount),
                      })}
                    </dd>
                    <dt>{t('영수증:발행 구분')}</dt>
                    <dd>{c.cashReceiptTypeName}</dd>
                    <dt>{t('영수증:신청 번호')}</dt>
                    <dd>
                      {c.cashReceiptNumber === '010-000-1234' && `(${t('영수증:자진발급')}) `}
                      {c.cashReceiptNumber}
                    </dd>
                    <dt>{t('영수증:승인 번호')}</dt>
                    <dd>{c.confirmCode}</dd>
                  </dl>
                  {c.isBookShow && <p className={styles.help}>{t('영수증:도서/공연비로 발행된 현금영수증입니다')}</p>}
                </section>
              ))}
          </Page>
        )
      }
    </WithRequest>
  )
}

export default Receipt

/* constants */
export const getMessages = (status) => Status[status]

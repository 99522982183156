import { useState } from 'react'
import { path } from 'ramda'

export default ({ api, handleError }) => {
  const [auth, setAuth] = useState({})

  const getAuth = async (config = {}) => {
    const {
      shouldSuccess = true,
      signoutIfNotActive = false,
      onAuth = () => {} // boolean으로 로그인 여부를 전달
    } = config

    try {
      const { data } = await api.get('/auth')

      /* actionRequired에 미리 약속된 값이 오지 않으면, 아무값도 오지 않은 것처럼 처리한다. */
      const isValid = ['set-account', 'set-pin', 'agree-terms'].includes(
        data.actionRequired
      )
      const actionRequired = isValid ? data.actionRequired : ''

      setAuth({ ...data, signoutIfNotActive, actionRequired })
      onAuth(true)
    } catch (error) {
      const notLoggedIn = path(['status'], error.response) === 401
      const isError = !notLoggedIn || (shouldSuccess && notLoggedIn)
      notLoggedIn && onAuth(false) // 401: 로그인되지 않음
      isError && handleError(error)
    }
  }

  const signin = async ({ phone }) => {
    try {
      const { data } = await api.post('/auth', { phone })
      setAuth(data)
    } catch (error) {
      handleError(error)
    }
  }

  const signout = async () => {
    try {
      await api.delete('/auth')
      setAuth({})
    } catch (error) {
      handleError(error)
    }
  }

  const authenticated = !!auth.phone
  const procedure = auth.actionRequired
  const actions = { getAuth, signin, signout }
  return { ...auth, authenticated, procedure, ...actions }
}

import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react-web'
import cx from 'classnames'

import { useBodyClass } from '../../../hooks'

import { ReactComponent as ChevronRight12 } from '../../../icons/ChevronRight12.svg'
import { ReactComponent as HomeTitle } from './svg/HomeTitle.svg'
import { ReactComponent as HomeCount0 } from './svg/HomeCount0.svg'
import { ReactComponent as HomeCount1 } from './svg/HomeCount1.svg'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const heartShine = 'https://static.chai.finance/event/rps/rpsHomeHeartShine.json'
const heartPop = 'https://static.chai.finance/event/rps/rpsHomeHeartPop.json'

const Rps = ({ history }) => {
  const { api, handleError, rpsConfig, statusData, getStatusData } = useContext(Context)

  const [isAnimation, setIsAnimation] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useBodyClass(styles.rps)

  useEffect(() => {
    checkPlayable()

    // eslint-disable-next-line
  }, [])

  const checkPlayable = async () => {
    try {
      const { version } = rpsConfig
      const { data } = await api.get(`/event/rps/${version}/available`)
      if (!data || !data.result) {
        history.replace('/event/rps/end')
      } else {
        getStatusData()
      }
    } catch (error) {
      getStatusData()
    }
  }

  const isAvailable = () => {
    return statusData && [undefined, 'ready'].includes(statusData.status)
  }

  const onSubmit = async () => {
    if (submitted) {
      return false
    }

    setIsAnimation(true)
    setSubmitted(true)

    if (!isAvailable()) {
      return false
    }

    try {
      const { version } = rpsConfig
      const { data } = await api.post(`/event/rps/${version}/start`)
      if (data && data.result) {
        setTimeout(() => {
          history.replace('/event/rps/play')
        }, 2100)
      }
    } catch (error) {
      handleError(error)
      setSubmitted(false)
    }
  }

  return (
    <section className={styles.homeSectionWrap}>
      <section className={styles.homeSection}>
        <p className={styles.ticket}>응모권</p>

        <h1>
          <HomeTitle />
        </h1>

        <div className={styles.stickersWrap}>
          <div className={styles.stickers} />
        </div>
      </section>

      <section className={styles.bottomSection}>
        <div className={styles.blockButton}>
          <button className={styles.primary} onClick={onSubmit}>
            {rpsConfig.text.home.startButton}
          </button>
        </div>

        <Link to="/event/rps/notice">
          차이바위보 유의사항 <ChevronRight12 />
        </Link>
      </section>

      <div className={styles.heart01}>
        <Lottie
          width={64}
          height={64}
          options={{
            path: heartShine,
          }}
        />
        <div>{submitted ? <HomeCount0 /> : <HomeCount1 />}</div>
      </div>
      <div className={cx(styles.heart02, isAnimation && styles.animation)}>
        {isAnimation ? (
          <Lottie
            options={{
              path: heartPop,
              loop: false,
            }}
          />
        ) : null}
      </div>
    </section>
  )
}

export default Rps

import React, { useState, useEffect } from 'react'
import { ga } from '../utils'
import { useApp, useUser, useAnalytics } from '../hooks'
import { useTheme, ThemeTypes, useNav, NavTypes } from '../hooks'
import Page from '../components/Page'
import CodeConfirm from './CodeConfirm'
import { Trans } from 'react-i18next'

const SCREEN = 'Certification'

/* 로그인: 인증코드 요청 및 검증 */
const Login = () => {
  useTheme(ThemeTypes.AUTH)
  useNav(NavTypes.CANCEL)
  useAnalytics(SCREEN)

  const [timestamp, setTimestamp] = useState()
  const { api, handleError } = useApp()
  const { user } = useUser()
  const { phone } = user

  /* 인증코드 요청 */
  useEffect(() => {
    request()
    // eslint-disable-next-line
  }, [])

  const request = async () => {
    setTimestamp(Date.now())

    try {
      await api.post('/auth/otp/request', { phone })
    } catch (error) {
      setTimestamp()
      handleError(error)
    }
  }

  /* render */
  const title = (
    <Trans i18nKey="인증:<0>인증번호</0>를 입력해주세요">
      <span className="red" />
    </Trans>
  )

  return (
    <Page title={title}>
      <CodeConfirm
        url="/auth/otp/verify"
        requestAgain={(e) => {
          ga.button(SCREEN, 'Retry')
          request(e)
        }}
        key={timestamp}
      />
    </Page>
  )
}

export default Login

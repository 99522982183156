import React from 'react'
import Account from '../../forms/Account'
import { Types as NavTypes } from '../../layouts/Nav'

const New = ({ history }) => (
  <Account
    nav={NavTypes.GO_BACK}
    navConfig={{ to: '/accounts' }}
    onFinish={() => history.replace('/accounts')}
  />
)

export default New

import React, { useEffect, useCallback } from 'react'
import qs from 'qs'
import { useApp } from '../../hooks'
import { isAndroid, isProduction } from '../../env'
import { getErrorMessage } from '../../utils'
import styles from './Add.module.scss'

const TERRA_TOPUP_DOMAIN = isProduction ? 'https://topup.terra.dev/' : 'https://topup-dev.terra.dev/'

function closeBridge(message) {
  const result = message ? { errorMessage: message } : {}
  if (isAndroid && window.ChaiAndroidHandler) {
    window.ChaiAndroidHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
  } else if (window.ChaiiOSHandler) {
    window.ChaiiOSHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
  } else {
    // 브릿지 처리가 없는 경우
    console.log(result)
  }
}

const Add = ({ match }) => {
  const { api, handleError } = useApp()

  // KRT bridge
  const fetchKRT = useCallback(async () => {
    try {
      const { data } = await api.get('/user/krtToken')
      if (!data.token) {
        handleError(new Error('유저 정보 획득 실패'))
        closeBridge('유저 정보 획득 실패')
        return
      }

      const param = qs.stringify({
        user_id: data.token,
        return_url: `${window.location.origin}/accounts/add/TERRA/complete`,
        app_scheme: 'chai://',
        app_logo_url: 'https://static.chai.finance/logo/img_logo_chai.png',
        app_name: '차이',
      })

      window.location.replace(`${TERRA_TOPUP_DOMAIN}?${param}`)
    } catch (error) {
      const message = getErrorMessage(error.response)
      handleError(error)
      closeBridge(message)
    }
  }, [api, handleError])

  useEffect(() => {
    switch (match.params.bankCode) {
      // KRT
      case 'TERRA':
        fetchKRT()
        break
      default:
        throw new Error('잘못된 경로')
    }
  }, [fetchKRT, match.params.bankCode])

  return (
    <div className={styles.center}>
      <p className={styles.title}>차이에서 계좌를 추가합니다.</p>
    </div>
  )
}

export default Add

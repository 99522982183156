import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { format } from '../../utils'
import { useApp } from '../../hooks'
import styles from './Item.module.scss'

const Item = ({ payment, onCancel }) => {
  const { paymentId, description, createdAt, displayStatus, merchant } = payment
  const { billingAmount, initial = {}, idempotencyKey } = payment

  const { t } = useTranslation()
  const { api, handleError } = useApp()

  /* state */
  const [input, setInput] = useState('')
  const [privateApiKey, setPrivateApiKey] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const amount = Number(input) || 0

  /* 취소 */
  const submit = async e => {
    e.preventDefault()

    const data = {
      cancelAmount: amount,
      bookShowAmount: 0,
      serviceFeeAmount: 0,
      taxFreeAmount: 0
    }

    const headers = {
      'Private-API-Key': privateApiKey,
      'Idempotency-Key': idempotencyKey
    }

    try {
      setIsSubmitting(true)
      await api.post(`/payment/${paymentId}/cancel`, data, { headers })
    } catch (error) {
      handleError(error)
    }

    setIsSubmitting(false)
    onCancel()
  }

  /* render */
  const renderBillingAmount = () => {
    const b = [
      billingAmount !== initial.billingAmount && billingAmount,
      initial.billingAmount
    ]

    return b
      .filter(Boolean)
      .map(v => `${format.price(v)}원`)
      .join(' / ')
  }

  return (
    <article className={styles.article}>
      <section>
        <main>
          <h1>{description}</h1>
          <ul>
            {merchant && <li>{merchant.name}</li>}
            <li>{format.date(createdAt, t)}</li>
          </ul>
          <p>
            {displayStatus} {renderBillingAmount()}
          </p>
        </main>

        <Link to={`/receipt/${paymentId}`}>영수증</Link>
      </section>

      <form onSubmit={submit} className={styles.form}>
        <input
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder="취소할 금액"
        />

        <input
          value={privateApiKey}
          onChange={e => setPrivateApiKey(e.target.value)}
          className={styles.long}
          placeholder="Private API Key"
        />

        <button type="submit" disabled={!amount || !privateApiKey}>
          {isSubmitting ? '취소 요청 중...' : '취소하기'}
        </button>
      </form>
    </article>
  )
}

export default Item

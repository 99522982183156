import en from './en.json'

const mirror = obj =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string' ? key : mirror(value)
    }),
    {}
  )

export default mirror(en)

import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useApp } from '../../hooks'
import Page from '../../components/Page'
import styles from './Verify.module.scss'

const IMG_URL = 'https://static.chai.finance/banner/verify/checkAccount.png'
const MAX_CODE_LENGTH = 3 // 인증코드의 자릿수 최대
const CONFIRM_BLOCKED_CODE = 'V004' // 인증 블락 - 요청부터 다시 시도

const Verify = ({ match, history }) => {
  const { t } = useTranslation()
  const { api, handleError, toast } = useApp()

  /* state */
  const [account, setAccount] = useState({})
  const [requestDisabled, setRequestDisabled] = useState(false)
  const [confirmDisabled, setConfirmDisabled] = useState(true)
  const [checkCode, setCheckCode] = useState('')

  useEffect(() => {
    fetchAccount()
    // eslint-disable-next-line
  }, [])

  /* 주계좌 정보 가져오기 */
  const fetchAccount = async () => {
    try {
      const { data } = await api.get('/user/account/primary')
      setAccount(data)
    } catch (error) {
      handleError(error)
    }
  }

  /* 점유인증 요청 */
  const verifyAccountRequest = async () => {
    setRequestDisabled(true)

    try {
      const { data } = await api.post('/user/account/verify/request')
      if (data.success) {
        toast(t('계좌:1원을 보냈습니다. 은행 내역을 확인해주세요'))
      }
    } catch (error) {
      handleError(error)
      setRequestDisabled(false)
    }
  }

  /* 점유인증 확인 */
  const verifyAccountConfirm = async () => {
    try {
      const { data } = await api.post('/user/account/verify/confirm', {
        checkCode,
      })
      if (data.success) {
        history.replace(match.url + '/complete')
      }
    } catch (error) {
      handleError(error)
      setCheckCode('')
      setConfirmDisabled(true)

      if (error.response.data.code === CONFIRM_BLOCKED_CODE) {
        setRequestDisabled(false)
      }
    }
  }

  /* 이벤트 핸들러 */
  const handleChange = (e) => {
    setCheckCode(e.target.value)

    if (e.target.value.length === 3) {
      setConfirmDisabled(false)
    } else {
      setConfirmDisabled(true)
    }
  }

  const request = (
    <section className={styles.description}>
      <p>{t('계좌:계좌 확인 후 1원 보내기를 눌러보세요')}</p>
      <div className={styles.flex}>
        <p>
          {account.bankName &&
            account.accountNumber &&
            `${account.bankName} ${account.accountNumber}`}
        </p>
        <button
          type="button"
          onClick={verifyAccountRequest}
          className="btn btn-block btn-primary"
          disabled={requestDisabled}
        >
          {t('계좌:1원 보내기')}
        </button>
      </div>
    </section>
  )

  const description = (
    <section className={styles.description}>
      <p>{t('계좌:은행 내역에서 입금된 내역을 확인해보세요')}</p>
      <img src={IMG_URL} alt="" width="100%" />
    </section>
  )

  const confirm = (
    <section className={styles.description}>
      <p>{t('계좌:세 자리 숫자 입력 후 인증해주세요')}</p>
      <div className={styles.flex}>
        <input
          name="code"
          onChange={handleChange}
          value={checkCode}
          placeholder="123"
          className={styles.input}
          maxLength={MAX_CODE_LENGTH}
        />
        <button
          type="button"
          onClick={verifyAccountConfirm}
          className="btn btn-block btn-primary"
          disabled={confirmDisabled}
        >
          {t('계좌:인증하기')}
        </button>
      </div>
    </section>
  )

  const title = (
    <Trans i18nKey="계좌:계좌 인증을 위해<0/> 아래 내용을 확인해주세요">
      <br />
    </Trans>
  )

  return account ? (
    <Page title={title}>
      {request}
      {description}
      {confirm}
    </Page>
  ) : (
    <Page>
      <p>{t('계좌:계좌를 찾을 수 없습니다')}</p>
    </Page>
  )
}

export default Verify

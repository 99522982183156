import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'
import { cond, equals, isNil } from 'ramda'
import { CancelToken, isCancel } from 'axios'
import { isAndroid, isDesktop, isMobile } from '../env'
import { format, ga, getMaxLength, is, isValidDate, sanitizeNumber } from '../utils'
import { NavTypes, ThemeTypes, useAnalytics, useApp, useNav, useTheme, useUser } from '../hooks'
import { ReactComponent as ChevronRight14 } from '../icons/ChevronRight14.svg'
import { isKeyMerchant } from '../merchant'
import WithOpenApp from './WithOpenApp'
import Page from '../components/Page'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import InputGroup from './InputGroup'

const MAX_BIRTH_LENGTH = 8 // 생년월일의 최대 자릿수 (구분자 포함 전체 텍스트 길이)
const SCREEN = 'Login'

/* 가입 여부 확인: 전화번호와 생년월일 */
const Existence = ({ location: { search }, history, openStore, openApp }) => {
  const { t } = useTranslation()

  useTheme(ThemeTypes.AUTH)
  useNav(NavTypes.CANCEL)
  useAnalytics(SCREEN)

  const { api, handleError, toast, query, env } = useApp()
  const { publicAPIKey } = query
  const { withPaymentPush, isSubscription } = env
  const { setUser } = useUser()

  /* Form */
  const phoneRef = useRef()
  const birthRef = useRef()
  const initial = { phone: '', birth: '' }
  const [values, setValues] = useState(initial)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { phone, birth } = values
  const maxLength = getMaxLength(phone) + 2

  /* state: Redirect */
  const [next, setNext] = useState() // 1: 가입 0: 로그인 -1: 본인인증
  const [additional, setAdditional] = useState('')
  const [secondary, setSecondary] = useState('')
  const [nextLocation, setNextLocation] = useState('')

  /* state: 앱 혹은 앱스토어 열기 */
  const [isOpeningApp, setIsOpeningApp] = useState(false) // 앱을 열려고 시도하는 중
  const [showAppStoreButton, setShowAppStoreButton] = useState(false) // 앱이 없음

  /* state: 화면이 숨겨질 때 보낸 리퀘스트가 실패했다가 다시 화면이 보여질 때 에러로 출력되는 것을 방지한다 */
  const [hideError, setHideError] = useState(false)

  /* Validation */
  const valid = is.phone(phone) && isValidDate(birth)
  const disabled = !valid || isSubmitting

  /* API */
  const source = CancelToken.source()
  const request = async () => {
    setIsSubmitting(true)
    setUser(user)

    try {
      const config = { cancelToken: source.token }
      const { data: status } = await api.post('/auth/exists', user, config)
      setIsSubmitting(false)
      handleResponse(status)
    } catch (error) {
      setIsSubmitting(false)

      if (!isCancel(error) && !hideError) {
        handleError(error)
      }

      if (hideError) {
        setHideError(false) // 다시 에러를 보여준다.
      }
    }
  }

  /* 응답 다루기 */
  const handleResponse = cond([
    [
      equals(null), // 미가입
      () => {
        setNext(1)
        setAdditional(t('인증:차이가 처음이시군요. 앱을 다운받아 가입해주세요'))
        isMobile ? setNextLocation('/register/agreement' + search) : requestAppLink()
      },
    ],
    [
      equals('invalid'), // 생년월일 불일치
      () => {
        setNext(1)
        setAdditional(t('인증:휴대폰 번호와 생년월일을 확인해주세요'))
        isDesktop && setSecondary(t('인증:휴대폰 번호가 변경되었나요?'))
        setNextLocation('/register/agreement' + search)
        phoneRef.current.focus()
      },
    ],
    [
      equals('identified'), // 계좌 혹은 핀 없음
      () => {
        setNext(-1)
        setAdditional(t('인증:계좌번호나 비밀번호 입력을 완료해주세요'))
        isDesktop && setSecondary(t('인증:가입 완료하기'))
        setNextLocation('/register' + search)
      },
    ],
    [
      equals('inactive'), // 비활성화됨
      () => {
        setNext(-1)
        setAdditional(t('인증:사용이 제한된 상태입니다. 앱에서 해제해주세요'))
        isDesktop && setSecondary(t('인증:제한 해제하기'))
        setNextLocation('/register' + search)
      },
    ],
    [
      equals('active'),
      () => {
        isDesktop && setNext(0)
        isMobile ? history.replace('/login' + search) : setNextLocation('/login' + search)
      },
    ],
  ])

  useEffect(() => {
    isDesktop && additional && next !== 1 && toast(additional)
    // eslint-disable-next-line
  }, [isDesktop, additional])

  const checkUser = () => {
    if (valid) {
      const shouldRequest = navigator.onLine || isNil(navigator.onLine)
      shouldRequest && request()
    } else {
      setNext()
      setAdditional('')
      setSecondary('')
      setNextLocation('')
    }
  }

  /* 폴링 및 이벤트에 대응하기 위해, key를 통해 제어한다. (0: 멈춤, 1부터: API 요청) */
  useEffect(() => {
    if (valid) {
      checkUser()
    }
    // eslint-disable-next-line
  }, [valid])

  /* 화면 가시 여부에 따른 폴링 멈추거나 시작하기 */
  const handleVisibilityChange = () => {
    if (document.hidden) {
      setHideError(true) // 화면이 드러나는 순간 발생하는 에러를 숨긴다.
    } else {
    }
    checkUser()
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }

    // eslint-disable-next-line
  }, [])

  /* 이벤트 핸들러 */
  const handleFocus = (e) => {
    checkUser()
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: format[name](value) })

    /* 자동 수행 */
    if (name === 'phone' && value.length === maxLength) {
      birthRef.current.focus()
    }
  }

  /* 데이터 다루기 */
  const parseValues = () => ({
    phone: sanitizeNumber(phone),
    birth: sanitizeNumber(birth),
  })

  const user = parseValues()

  /* 앱링크요청 페이지로 이동 */
  const requestAppLink = () => {
    ga.button(SCREEN, 'Register')
    setUser(user)
    history.push('/app' + search)
  }

  /* 앱 혹은 앱스토어 열기 */
  const tryOpenApp = () => {
    setIsOpeningApp(true)
    openApp()

    setTimeout(() => {
      if (!isAndroid) {
        // iOS: 앱을 열려고 시도한 후, 앱이 열리지 않으면 앱스토어 열기 버튼을 표시한다.
        // Android: intent가 앱이 있으면 앱을 열고 앱이 없으면 앱스토어를 열기 때문에 불필요하다.
        setShowAppStoreButton(true)
      }

      /* 앱을 연 후, 폴링 시작 */
      setIsOpeningApp(false)
    }, 3000)
  }

  /* 제출 */
  const submit = async (e) => {
    e.preventDefault()

    if (isKeyMerchant(publicAPIKey, '위메프') && isDesktop) {
      // next보다 먼저 봐야 함
      requestAppLink()
    } else if (next === 0 /* 로그인 */) {
      ga.button(SCREEN, 'Web')
      history.replace(nextLocation)
    } else if (isDesktop) {
      requestAppLink()
    } else {
      tryOpenApp() // 가입 OR 본인인증
    }
  }

  /* render */
  const title = withPaymentPush ? (
    isSubscription ? (
      <Trans i18nKey="인증:<0>등록</0>이 가능하도록 <1/>아래 정보를 입력해주세요">
        <span className="red" />
        {isDesktop ? <span></span> : <br />}
      </Trans>
    ) : (
      <Trans i18nKey="인증:<0>결제</0>가 가능하도록 <1/>아래 정보를 입력해주세요">
        <span className="red" />
        {isDesktop ? <span></span> : <br />}
      </Trans>
    )
  ) : (
    <Trans i18nKey="인증:<0>차이</0>에 오신 것을 <1/>환영합니다">
      <span className="red" />
      {isDesktop ? <span></span> : <br />}
    </Trans>
  )

  const buttonLabelSecondary = isMobile ? additional : secondary

  return (
    <Page title={title}>
      {isOpeningApp && <Loading />}

      <form onSubmit={submit}>
        <label>{t('인증:휴대폰 번호')}</label>
        <InputGroup>
          {(attrs) => (
            <input
              {...attrs}
              type="tel"
              name="phone"
              value={phone}
              onFocus={handleFocus}
              onChange={handleChange}
              maxLength={maxLength}
              readOnly={isSubmitting}
              placeholder="010 1234 5678"
              className="input"
              autoComplete="off"
              autoFocus
              ref={phoneRef}
            />
          )}
        </InputGroup>

        <label>{t('인증:생년월일')}</label>
        <InputGroup>
          {(attrs) => (
            <input
              {...attrs}
              type="tel"
              name="birth"
              value={birth}
              onFocus={handleFocus}
              onChange={handleChange}
              maxLength={MAX_BIRTH_LENGTH}
              readOnly={isSubmitting}
              placeholder="95.01.01"
              className="input"
              autoComplete="off"
              ref={birthRef}
            />
          )}
        </InputGroup>

        {(isDesktop || nextLocation) && (
          <button
            type="submit"
            onClick={submit}
            disabled={disabled || (isDesktop && next !== 0)}
            className="btn btn-block btn-primary btn-submit"
          >
            {isDesktop ? t('인증:로그인') : next === 1 ? t('인증:가입하기') : t('인증:차이 앱으로 이동')}
          </button>
        )}

        {showAppStoreButton && (
          <button
            type="button"
            onClick={() => {
              ga.button(SCREEN, 'Download')
              setShowAppStoreButton(false)
              openStore()
            }}
            className="btn btn-block btn-primary btn-submit animated faster shake"
          >
            {t('인증:차이앱 다운로드')}
          </button>
        )}

        {buttonLabelSecondary && (
          <button
            type="submit"
            onClick={submit}
            disabled={disabled}
            className={cx('btn btn-block btn-submit', isMobile ? 'btn-text' : 'btn-link')}
          >
            {buttonLabelSecondary}
            {isMobile && <ChevronRight14 />}
          </button>
        )}
      </form>

      {isDesktop && (
        <Footer>
          <p className="text-muted text-center" style={{ marginBottom: 12 }}>
            <small>{t('인증:차이가 처음이신가요?')}</small>
          </p>
          <button onClick={requestAppLink} className="btn btn-block btn-outline-primary" type="button">
            {t('인증:가입하기')}
          </button>
        </Footer>
      )}
    </Page>
  )
}

const ExistenceContainer = (props) => (
  <WithOpenApp withoutQueries>{(params) => <Existence {...props} {...params} />}</WithOpenApp>
)

export default ExistenceContainer

import React from 'react'
import cx from 'classnames'
import { bool, string, object, node, func } from 'prop-types'
import { isDesktop } from '../env'
import { useApp } from '../hooks'
import Footer from './Footer'
import styles from './Page.module.scss'

const propTypes = {
  form: bool,
  front: bool,
  title: node,
  description: string,
  className: string,
  footer: object,
  children: node,
  onSubmit: func,
}

const defaultProps = {
  form: false,
  front: false,
  title: '',
  description: '',
  className: '',
  footer: {},
  children: null,
  onSubmit: () => {},
}

const Page = ({ title, description, children, footer, ...props }) => {
  const { form, front, onSubmit, right } = props
  const { className, headerClassName, mainClassName } = props
  const { env } = useApp()
  const articleClassName = cx(
    styles.article,
    styles[env.theme || 'default'],
    isDesktop && 'desktop',
    isDesktop && styles.desktop,
    form && styles.form,
    front && styles.front,
    className
  )

  const content = (
    <>
      {(title || description) && (
        <header className={cx(styles.header, headerClassName, right && styles.right)}>
          {title && <h1 className={styles.title}>{title}</h1>}
          {right}
          {description && <p className={styles.description}>{description}</p>}
        </header>
      )}

      <section className={mainClassName}>{children}</section>
    </>
  )

  const submit = <button type="submit" {...footer}></button>

  return form ? (
    <form onSubmit={onSubmit} className={articleClassName}>
      {content}

      {isDesktop ? <footer className={styles.footer}>{submit}</footer> : <Footer>{submit}</Footer>}
    </form>
  ) : (
    <article className={articleClassName}>{content}</article>
  )
}

Page.propTypes = propTypes
Page.defaultProps = defaultProps

export default Page

export const UNKNOWN =
  '네트워크 문제나 일시적인 현상일 수 있으니 잠시 후 다시 시도해주시기 바랍니다. 문제가 계속되는 경우 이메일로 문의해주세요. help@chai.finance'

const Messages = {
  400: '잘못된 요청입니다.',
  401: '인증에 실패했습니다',
  429: '요청이 너무 많습니다. 잠시 후 다시 시도해주세요.'
}

export default ({ status, data = {} } = {}) =>
  data.message || Messages[status] || UNKNOWN

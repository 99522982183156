import React, { useState } from 'react'
import { bool, string, object, node } from 'prop-types'
import Modal from 'react-modal'
import cx from 'classnames'
import { ReactComponent as Times } from '../icons/Times.svg'
import styles from './Drawer.module.scss'

Modal.setAppElement('#root')
const TransitionDuration = 200 /* Drawer.module.scss */
const propTypes = { v2: bool, large: bool, title: string, config: object, children: node }
const defaultProps = { v2: false, large: false, title: '', config: {}, children: null }

/* 하단에 드로어 형태로 띄우는 모달 */
const Drawer = ({ v2, large, config, children, ...props }) => {
  /* state */
  // isOpen과 무관하게, 트랜지션을 위해 사용하는 상태이다.
  // react-modal의 closeTimeoutMS를 사용하지 않는 이유는,
  // onRequestClose에서 setContent()로 내용을 비우는 동작이 타임아웃 이전에 일어나기 때문이다.
  const [hidden, setHidden] = useState(true)

  const modal = {
    ...config,
    overlayClassName: cx(styles.overlay, hidden && styles.hidden),
    className: cx(styles.content, v2 && styles.v2),
    onAfterOpen: () => {
      config.onAfterOpen && config.onAfterOpen()
      setHidden(false)
    },
    onRequestClose: () => {
      setHidden(true)
      setTimeout(() => config.onRequestClose(), TransitionDuration)
    },
  }

  const title = props.title || config.title
  const close = modal.onRequestClose
  return (
    <Modal {...modal}>
      <div className={cx(styles.Drawer, large && styles.large)}>
        <header className={styles.header}>
          {title && <h1 className={styles.title}>{title}</h1>}
          <button type="button" onClick={close} className={styles.close}>
            {v2 ? '닫기' : <Times />}
          </button>
        </header>
        {children}
      </div>
    </Modal>
  )
}

Drawer.propTypes = propTypes
Drawer.defaultProps = defaultProps

export default Drawer

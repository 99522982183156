import React, { useCallback, useEffect, useState } from 'react'
import { DateTime } from 'luxon'

import styles from './Payments.module.scss'
import { ReactComponent as Down } from './svg/chevron-right.svg'
import { ReactComponent as Balloon } from './svg/bg-balloon-face.svg'
import { ReactComponent as Face } from './svg/face.svg'
import { ReactComponent as ChaiLogo } from './svg/payments/chai_logo.svg'
import { ReactComponent as ChaiMoney } from './svg/payments/chai_money.svg'
import { ReactComponent as QuestionMark } from './svg/payments/questionmark.svg'

import PaymentsItem from './PaymentsItem'
import Select from '../../../components/Select'
import { useApp } from '../../../hooks'
import { format, ga, replaceUrl } from '../../../utils'
import Loading from '../../../components/Loading'

const INITIAL_MONTH = '2021-09'
const SCREEN = 'KVoucherPaymentList'
const INITIAL = 2000
const KVOUCHER_FAQ =
  'https://chaifinance.notion.site/AtoZ-02359c9f6ef540a3bbfcfd806c1132fb#c39d3dcf95164a1fa5cf8813915033ed'
const KVOUCHER_PAYMENTS = '/event/kvoucher/payments'

const Payments = ({ history }) => {
  const { api, handleError } = useApp()
  const [list, setList] = useState([])

  const [totalCashback, setTotalCashback] = useState(0)
  const [balance, setBalance] = useState(0)
  const [isLoading, setIsLoading] = useState(false) // 로딩 인디케이터 잠깐 출력

  const getCurrentMonth = (d = new Date()) => {
    const YYYY = d.getFullYear()
    const MM = String(d.getMonth() + 1).padStart(2, '0')
    return [YYYY, MM].join('-')
  }

  const [month, setMonth] = useState(getCurrentMonth)

  useEffect(() => {
    const init = async () => {
      ga.screen(SCREEN)
    }
    init()
    fetchList(month)
    // eslint-disable-next-line
  }, [])

  const fetchList = useCallback(
    async (value) => {
      const url = `/user/payment/kvoucher?month=${value}`
      try {
        const { data } = await api.get(url)
        const { paymentList, voucherBalance } = data
        const { amount } = voucherBalance
        setList(paymentList)
        const reservedCashback = paymentList.reduce((sum, payment) => {
          return sum + payment.cashbackAmount || 0
        }, 0)
        setBalance(amount)
        setTotalCashback(reservedCashback)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [month]
  )

  const beforeClick = (label, loading = true) => {
    ga.button(SCREEN, label)

    if (loading) {
      setIsLoading(true) // 로딩 인디케이터를 출력한다.
      // 3초 후
      setTimeout(() => {
        setIsLoading(false) // 로딩 인디케이터를 숨긴다.
      }, INITIAL)
    }
  }

  const goToVoucherFaq = () => {
    history.push(KVOUCHER_PAYMENTS)
    replaceUrl(KVOUCHER_FAQ)
  }

  const FMT = 'yyyy-MM'

  const getMonthList = (end = getCurrentMonth()) => {
    const fn = (acc) => {
      const [prev] = acc

      const cur = DateTime.fromFormat(prev, FMT).plus({ months: 1 }).toFormat(FMT)
      if (cur > end) return [...acc]
      const next = [cur, ...acc]
      return fn(next)
    }

    return fn([INITIAL_MONTH])
  }

  const formatDate = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).toFormat('yyyy년 M월')
  const formatMonth = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).toFormat('M월')
  const formatNextMonth = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).plus({ months: 1 }).toFormat('M월')

  const select = {
    value: month,
    options: getMonthList().map((value) => ({ value, children: formatDate(value) })),
    onChange: ({ value }) => {
      setMonth(value)
      fetchList(value)
    },
    label: '조회 월 선택',
    caret: <Down />,
    children: `${formatMonth(month)} 결제 내역`,
  }

  const title = (
    <>
      <Select v2 {...select} />
    </>
  )

  return (
    <>
      {isLoading && <Loading backdrop />}
      <div className={styles.container}>
        <div className={styles.title_box}>
          <div className={styles.title}>국민지원금 사용내역</div>
          <div className={styles.question_mark}>
            <QuestionMark
              onClick={() => {
                beforeClick('voucherFaq')
                goToVoucherFaq()
              }}
            />
          </div>
        </div>
        <div className={styles.round_on1}>
          <div className={styles.left_area}>
            <ChaiLogo />
          </div>
          <div className={styles.right_area}>
            <div className={styles.text1}>국민지원금 잔고</div>
            <div>
              <span>{`${format.price(balance)}원`}</span>
            </div>
          </div>
        </div>

        <div className={styles.round_on2}>
          <div className={styles.left_area}>
            <ChaiMoney />
          </div>
          <div className={styles.right_area}>
            <div className={styles.text1}>{`${formatNextMonth(month)} 적립 예정금액`}</div>
            <div>
              <span>{`${format.price(totalCashback)}원`}</span>
            </div>
          </div>
        </div>
        <div className={styles.detail}>
          <div className={styles.detail_h}>{title}</div>

          {list.length ? (
            list.map((payment, index) => <PaymentsItem payment={payment} />)
          ) : (
            <div className={styles.empty}>
              <div className={styles.empty_img}>
                <Balloon className={styles.bg_balloon_face} />
                <Face className={styles.face} />
              </div>
              <div className={styles.ballon_text}>결제 내역이 없어요</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Payments

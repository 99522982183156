import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { replace, ga } from '../../utils'
import { useApp, useNav, NavTypes, useAnalytics } from '../../hooks'
import { WithRequest } from '../../hooks'
import Keypad from '../../assets/Keypad'
import Loading from '../../components/Loading'
import { getQueryParam } from '../../function'

/* 결제 승인: Pin 입력을 통해 */
const Approval = () => {
  const { t } = useTranslation()

  useNav(NavTypes.CANCEL)
  useAnalytics('Pincode')
  const { api, query, handleError } = useApp()
  const { paymentId } = query

  /* state */
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [attempt, setAttempt] = useState(0)

  /* 차이 API에 승인 요청 */
  const approve = async (pin) => {
    setIsSubmitting(true)
    try {
      const url = `/payment/${paymentId}/approve`
      const headers = { authorization: `Chai-Pin ${pin}` }
      const { data } = await api.post(url, null, { headers })
      handleResponse(data)
    } catch (error) {
      setIsSubmitting(false)
      setAttempt(attempt + 1)
      handleError(error)
    }
  }

  /* 가맹점으로 돌아가기 */
  const handleResponse = ({ returnUrl, status, errorMessage, errorCode }) => {
    ga.screen('Confirm')
    const params = getQueryParam({ query, status, errorMessage, errorCode })

    replace(returnUrl, params)
  }

  return (
    <>
      {isSubmitting && <Loading />}
      <WithRequest url="/auth/pin/token">
        {(token) => (
          <Keypad
            title={t('결제:결제 비밀번호를 입력해주세요')}
            token={token}
            shake={!!attempt}
            onFinish={approve}
            key={attempt}
          />
        )}
      </WithRequest>
    </>
  )
}

export default Approval

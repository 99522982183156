import React, { useState } from 'react'
import cx from 'classnames'
import Actions from './Actions'
import styles from './Dropdown.module.scss'

// 주의: select.value와 option.value의 구분
const Dropdown = ({ caret, emptyContent, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  /* props */
  const { buttonClassName, dropdownClassName, onClick, ...rest } = props
  const { name, placeholder, options, onChange, ...select } = rest

  /* Modal: Open */
  const handleClick = async () => {
    onClick()
    setIsOpen(!isOpen)
  }

  const getList = () => {
    const list = options.map(({ image, children, ...option }, index) => {
      const isSelected = index === getSelected()
      return {
        image,
        text: children,
        additionalText: option.additionalText,
        active: isSelected,
        disabled: option.disabled,
        onClick: () => {
          !isSelected && onChange({ name, value: option.value })
          setIsOpen(false)
        }
      }
    })

    return list
  }

  const getSelected = () =>
    options.findIndex(option => option.value === select.value)

  /* render */
  // 선택한 옵션의 텍스트 (동적으로 옵션을 가져올 때에는 작동하지 않는다.)
  const { children: text } = options[getSelected()] || {}
  return (
    <div className={styles.compoment}>
      <button
        type="button"
        onClick={props.shouldNotOpen ? undefined : handleClick}
        className={buttonClassName(isOpen)}
      >
        <span>{children || text || placeholder}</span>
        {!props.shouldNotOpen && caret}
      </button>

      {isOpen && (
        <Actions
          list={getList()}
          emptyContent={emptyContent}
          className={cx(styles.actions, dropdownClassName)}
          itemClassName={styles.item}
        />
      )}
    </div>
  )
}

export default Dropdown

import React, { useCallback, useEffect, useState } from 'react'
import { useApp } from '../../../hooks'

import { ReactComponent as ApplyBtn } from './svg/home/btn-apply.svg'
import { ReactComponent as HistoryBtn } from './svg/home/btn-history.svg'
import { ReactComponent as InfoBtn } from './svg/home/btn-info.svg'

import styles from './Home.module.scss'
import cx from 'classnames'
import { ga, replaceUrl } from '../../../utils'
import Loading from '../../../components/Loading'

const API_BALANCE_URL = '/card/user/voucher/balance'
const KVOUCHER_HOME = '/event/kvoucher'
const KVOUCHER_PAYMENTS = '/event/kvoucher/payments'
const KVOUCHER_INFO = 'https://chaifinance.notion.site/A-to-Z-02359c9f6ef540a3bbfcfd806c1132fb'

const SCREEN = 'KVoucher'
const INITIAL = 2000

const Home = ({ history }) => {
  const { api, graph, handleError } = useApp()
  const [showModal, setShowModal] = useState(false)
  const [showFinish, setShowFinish] = useState(false)
  const [status, setStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // 로딩 인디케이터 잠깐 출력

  const checkVoucher = useCallback(
    async () => {
      try {
        const { data } = await graph.post('/', {
          query: `query getKVoucherStatus {getKVoucherStatus}`,
        })
        const { getKVoucherStatus } = data.data
        setStatus(getKVoucherStatus)

        if (getKVoucherStatus) {
          const result = await hasVoucherBalance()
          if (result) {
            goToPayments()
          }
        }
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    []
  )

  const hasVoucherBalance = async () => {
    try {
      const { data } = await api.get(API_BALANCE_URL)
      const { code } = data
      const result = code === '00'
      return result
    } catch (e) {
      handleError(e)
    }
    return false
  }

  const beforeClick = (label, loading = true) => {
    ga.button(SCREEN, label)

    if (loading) {
      setIsLoading(true) // 로딩 인디케이터를 출력한다.
      // 3초 후
      setTimeout(() => {
        setIsLoading(false) // 로딩 인디케이터를 숨긴다.
      }, INITIAL)
    }
  }

  const goToPayments = () => {
    history.replace(KVOUCHER_PAYMENTS)
  }

  useEffect(() => {
    const init = async () => {
      ga.screen(SCREEN)
    }
    checkVoucher()
    init()
    checkVoucher()
    // eslint-disable-next-line
  }, [])

  const goToApplyVoucher = () => {
    setShowFinish(true)
  }

  const goToVoucherInfo = () => {
    history.push(KVOUCHER_HOME)
    replaceUrl(KVOUCHER_INFO)
  }

  const clickGoToPayments = async () => {
    const result = await hasVoucherBalance()
    if (result) {
      goToPayments()
    } else {
      setIsLoading(false)
      setShowModal(true)
    }
  }

  const closeModal = async () => {
    setTimeout(async () => {
      setShowModal(false)
    }, 300)
  }

  const closeFinishModal = async () => {
    setTimeout(async () => {
      setShowFinish(false)
    }, 300)
  }

  return (
    <>
      {showModal && (
        <>
          <div className={cx(styles.modal)}>
            <div className={styles.showModal}>
              {status ? (
                <>
                  <div className={styles.title}>재난지원금 사용내역이 없습니다</div>
                  <div className={styles.description}>확인 후 다시 시도해주세요.</div>
                </>
              ) : (
                <>
                  <div className={styles.title}>재난지원금 사용내역</div>
                  <div className={styles.description}>내일 오전 8시부터 확인할 수 있어요</div>
                </>
              )}
              <div className={styles.btn} onClick={closeModal}>
                <span>확인</span>
              </div>
            </div>
          </div>
        </>
      )}
      {showFinish && (
        <>
          <div className={cx(styles.modal)}>
            <div className={styles.showModal}>
              <>
                <div className={styles.title}>아쉽지만 지원금 신청이 마감되었어요</div>
                <div className={styles.description}></div>
              </>
              <div className={styles.btn} onClick={closeFinishModal}>
                <span>확인</span>
              </div>
            </div>
          </div>
        </>
      )}
      {isLoading && <Loading backdrop />}
      <div className={styles.container}>
        <img
          src="https://static.chai.finance/event/kvoucher/img_kvoucher_main.png"
          alt="국민 지원금도 차이카드로"
          className={styles.main}
        />
        <ApplyBtn
          className={styles.btn_apply}
          onClick={() => {
            goToApplyVoucher()
          }}
        />
        <HistoryBtn
          className={styles.btn_history}
          onClick={() => {
            beforeClick('payments', status)
            status ? clickGoToPayments() : setShowModal(true)
          }}
        />
        <InfoBtn
          className={styles.btn_info}
          onClick={() => {
            beforeClick('WhatIsKVoucher')
            goToVoucherInfo()
          }}
        />
        <img
          src="https://static.chai.finance/event/kvoucher/img_kvoucher_benefit.png"
          alt="혜택"
          className={styles.benefit}
        />
        <img
          src="https://static.chai.finance/event/kvoucher/img_kvoucher_notice.png"
          alt="유의사항"
          className={styles.notice}
        />
      </div>
    </>
  )
}

export default Home

import React from 'react'
import { useApp } from '../../hooks'
import Agreements from './Agreements'

const Agree = () => {
  const { auth } = useApp()
  const { requiredAgreements = [] } = auth
  return (
    <Agreements
      data={{
        required: requiredAgreements.filter(({ optional }) => !optional),
        optional: requiredAgreements.filter(({ optional }) => optional)
      }}
    />
  )
}

export default Agree

import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-react-web'

import luckyCashback from '../../animations/lucky_cashback.json'
import { ReactComponent as LuckyCashbackSmall } from './images/lucky_cashback_small.svg'

import { format } from '../../utils'

import Counter from './Counter'

import styles from './Lottery.module.scss'

const Result = (props) => {
  const [price, setPrice] = useState()
  const [isAnimationEnd, setIsAnimationEnd] = useState(false)

  useEffect(() => {
    if (props.price) {
      setPrice(props.price)
      setTimeout(() => {
        setIsAnimationEnd(true)
      }, 300)
    }
  }, [props.price])

  if (!price) {
    return <></>
  }

  return (
    <div className={styles.result}>
      <Lottie
        options={{
          animationData: luckyCashback,
          loop: false,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
      {isAnimationEnd && (
        <div className={styles.resultCenter}>
          <LuckyCashbackSmall />
          <div className={styles.priceWrapper}>
            <Counter initial={price * 0.7} dest={price} onFinish={() => {}}>
              {(count) => <>{format.price(Math.min(count, price))}원 당첨!</>}
            </Counter>
          </div>
        </div>
      )}
    </div>
  )
}

export default Result

import { v4 as uuidv4 } from 'uuid'

export const PUBLIC_API_KEY =
  '471943b3771c81abf73edc61e9a30ec0fe0a39637a6c972e3b8bde474f67559c'

export const PRIVATE_API_KEY =
  '3daff7abae8014eedd2f081912c488be7bca19ea28070aedef3175e7291794e4'

export const getSample = (checkoutAmount) => ({
  data: {
    checkoutAmount,
    merchantUserId: 'chai-tester',
    currency: 'KRW',
    description: '[TEST] 차이 테스트용 상품',
    returnUrl: `${window.location.origin}/demo/complete`,
  },

  config: {
    headers: {
      'Private-API-Key': PRIVATE_API_KEY,
      'Public-API-Key': PUBLIC_API_KEY,
      'Idempotency-Key': uuidv4(),
    },
  },
})

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { closeWebView } from '../../utils/replace'
import { useApp, useModal, useNav, NavTypes } from '../../hooks'
import Page from '../../components/Page'
import Footer from '../../components/Footer'
import Drawer from '../../components/Drawer'
import Actions from '../../components/Actions'
import Check from '../../components/Check'
import Loading from '../../components/Loading'
import styles from './Delete.module.scss'

const Delete = ({ history }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })
  const { api, handleError } = useApp()
  const modal = useModal()
  const [checked, setChecked] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const disabled = !checked || isSubmitting || isSubmitted

  const toggle = () => setChecked((c) => !c)

  const confirm = () => {
    const request = async () => {
      setIsSubmitting(true)

      try {
        await api.delete('/user')
        setIsSubmitted(true)
        setIsSubmitting(false)
        closeWebView()
      } catch (error) {
        setIsSubmitting(false)
        handleError(error)
      }

      modal.close()
    }

    const list = [
      { text: t('일반:예'), onClick: request, disabled },
      { text: t('일반:아니오'), onClick: modal.close },
    ]

    modal.open(<Actions list={list} />, {
      title: t('탈퇴:정말로 탈퇴하시겠습니까?'),
    })
  }

  return (
    <Page
      title={t('탈퇴:회원 탈퇴')}
      description={t(
        '탈퇴:회원 탈퇴 시 계좌 및 개인정보와 이용 기록이 즉시 삭제됩니다. 적립된 차이머니는 회수되며 쿠폰도 삭제되어 복구 불가하기 때문에 유의해주세요'
      )}
    >
      {isSubmitting && <Loading />}
      <Footer>
        <p>
          <button onClick={toggle} className={styles.agreement}>
            <Check checked={checked} className={styles.check} />
            <span>{t('탈퇴:위 유의사항을 숙지하고 탈퇴에 동의합니다')}.</span>
          </button>
        </p>

        <button
          onClick={confirm}
          disabled={disabled}
          className="btn btn-block btn-outline btn-submit"
        >
          {t('탈퇴:탈퇴하기')}
        </button>

        <Drawer config={modal.config}>{modal.content}</Drawer>
      </Footer>
    </Page>
  )
}

export default Delete

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RequestRoute } from '../../routes'
import { useTheme, ThemeTypes } from '../../hooks'
import Agreements from '../agreements/Agreements'
import Verification from '../../forms/Verification'

/* 회원가입: 약관동의와 본인인증 */
const Registration = ({ match }) => {
  useTheme(ThemeTypes.AUTH)

  const AgreementsRoute = {
    url: '/agreement',
    path: match.url + '/agreement'
  }

  return (
    <Switch>
      <RequestRoute {...AgreementsRoute} component={Agreements} />
      <Route path={match.url} component={Verification} />
    </Switch>
  )
}

export default Registration

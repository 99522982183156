import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ChevronDown12 } from '../../icons/ChevronDown12.svg'
import { ReactComponent as ChevronRight12 } from '../../icons/ChevronRight12.svg'
import Page from '../../components/Page'
import Select from '../../components/Select'
import styles from './List.module.scss'
import { BarcodeOrderType } from '../../Constants'
import { Context } from './Barcode'

const List = () => {
  const { order, setOrder, barcodeData } = useContext(Context)

  const [merchants, setMerchants] = useState([])

  useEffect(() => {
    if (barcodeData && Array.isArray(barcodeData.merchants)) {
      const key = order === 'abc' ? 'name' : 'rank'
      setMerchants([...barcodeData.merchants.sort((a, b) => (a[key] > b[key] ? 1 : -1))])
    }
  }, [barcodeData, order])

  const right = (
    <Select
      label="정렬"
      caret={<ChevronDown12 />}
      v2
      options={Object.keys(BarcodeOrderType).map((key) => ({
        value: key,
        children: BarcodeOrderType[key],
      }))}
      value={order}
      onChange={({ value }) => setOrder(value)}
    />
  )

  return (
    <Page title="사용가능 매장" right={right}>
      <ul className={styles.merchants}>
        {merchants &&
          Array.isArray(merchants) &&
          merchants.map((merchant, index) => (
            <li key={index}>
              <Link to={`/barcode/${merchant.id}`}>
                <img src={merchant.logo} alt={merchant.name} />
                <div className={styles.discount}>
                  <p>{merchant.discount}</p>
                  <ChevronRight12 />
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </Page>
  )
}

export default List

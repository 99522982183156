import React from 'react'
import styles from './BoostUp.module.scss'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import { isDesktop } from '../../env'
import IconMiss from '../../images/icBoostupMiss24@3x.png'
import IconSmall from '../../images/icBoostupSmall24@3x.png'
import IconLarge from '../../images/icBoostupLarge24@3x.png'

const BoostUpDetail = ({ match }) => {
  useNav(NavTypes.GO_BACK, { toApp: true })

  return (
    <WithRequest url={`/boost/campaign/${match.params.id}/boostUpPolicy`}>
      {(boostUpDescription) => (
        <article className={isDesktop ? styles.desktop : undefined}>
          <h1 className={styles.h1}>부스트 강화 유의사항</h1>
          <div className={styles.padding}></div>
          <div
            className={styles.gray}
            dangerouslySetInnerHTML={{ __html: boostUpDescription?.commonDescription || '' }}
          ></div>
          <div className={styles.padding}></div>
          <table className={styles.schemeTable}>
            <thead>
              <tr>
                <th width="60%">적립금액</th>
                <th width="20%">추가할인율</th>
                <th width="20%">강화확률</th>
              </tr>
            </thead>
            <tbody>
              {(boostUpDescription?.boostUpSchemePolicies || [])
                .sort((a, b) => (a.cashbackAmountMax || 0) - (b.cashbackAmountMax || 0))
                .map((item, idx) => {
                  const icon =
                    idx === 0 && item.cashbackAmountMax === 0
                      ? IconMiss
                      : idx === boostUpDescription?.boostUpSchemePolicies.length - 1
                      ? IconLarge
                      : IconSmall
                  return (
                    <tr key={`boost-up-detail-scheme-row-${idx}`}>
                      <td className={styles.schemeTableCashbackAmountMax}>
                        <img src={icon} className={styles.icon} alt="miss" />
                        {item.cashbackAmountMin === item.cashbackAmountMax
                          ? `${item.cashbackAmountMin.toLocaleString()}원`
                          : `${item.cashbackAmountMin.toLocaleString()}~${item.cashbackAmountMax.toLocaleString()}원`}
                      </td>
                      <td className={styles.schemeTableProbability}>{item.discountRate}%</td>
                      <td className={styles.schemeTableProbability}>{item.probability}%</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div className={styles.padding}></div>
          <div className={styles.gray}>
            {boostUpDescription?.description
              ? boostUpDescription.description
                  .split('\n')
                  .map((text, index) => <p key={`boostup-description-${index}`}>{text ? text : ' '}</p>)
              : ''}
          </div>
        </article>
      )}
    </WithRequest>
  )
}

export default BoostUpDetail

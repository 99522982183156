import React, { useState } from 'react'
import qs from 'qs'
import { isAndroid, isDevelopment, isSamsungBrowser } from '../env'
import { isKeyMerchant, isIdSubMerchant } from '../merchant'
import { useApp, WithRequest } from '../hooks'
import { pick } from 'ramda'
import { replaceUrl } from '../utils'

const IFRAME = 'iframe'
const LINK = 'link'

const OpenApp = ({ children, appScheme, linkMethod = IFRAME, subMerchantId, ...rest }) => {
  const { withoutQueries = false } = rest
  const { query, env } = useApp()

  const { isSubscription } = env

  // (개발용) method 강제 변경
  const [forcedMethod, setForcedMethod] = useState()

  // query에서 필요한 것만 넘기지 않으면 intent로 설치 후 결제가 작동하지 않음.
  // url 길이 또는 market_referrer 길이 제한 때문인 것으로 추정.
  const q = withoutQueries
    ? ''
    : qs.stringify(pick(['publicAPIKey', 'paymentId', 'idempotencyKey', 'subscriptionId'], query))
  const scheme = withoutQueries ? 'chai' : 'chaipayment'

  /* 앱스토어 열기 */
  const store = `itms-apps://itunes.apple.com/kr/app/apple-store/id1459979272`
  const market = `market://details?id=finance.chai.app&referrer=${encodeURIComponent(q)}`
  const openStore = () => replace(isAndroid ? market : store /* iphone, ipad */)

  /* 앱 열기 */
  const [iframeKey, setIframeKey] = useState(0)

  /**
   * url rules
   * 1. 기본 url = {scheme}://?{q}
   *    scheme = withoutQueries ? 'chai' : 'chaipayment'
   *    q = withoutQueries ? '' : '?querystring'
   * 2. Android 일 때
   *    appScheme이 없거나(=타사 앱에서 여는 경우가 아닌 경우=모바일 웹에서 여는 경우), 야놀자의 경우 url을 intent로 변경
   * 3. iOS 일 때
   *    appScheme이 없거나, linkMethod가 LINK가 아닐 경우 url을 유니버셜 링크로 변경
   *    (appScheme이 있는데 linkMethod가 LINK가 아닌 경우는 현재 티몬 앱이 유일하고 티몬만 앱인데도 기본 url을 사용하게 됨)
   *    단, 유니버설 링크는 현재(2019-10-21) chaipayment 형식(앱 로그인 후 바로 결제)만 지원하므로
   *    파라메터(q)가 없으면 url로 열어서 앱이 있을 때만 열리도록 처리
   **/
  let url = `${scheme}://${q && `?${q}`}`
  const schemePath = isSubscription ? 'subscription' : 'payment'

  if (isAndroid) {
    // The ultimate developer guide to Android application linking methods:
    // https://simonmarquis.github.io/Android-App-Linking/
    url = `${scheme}://${schemePath}${q && `?${q}`}`
    if (!appScheme || isKeyMerchant(query.publicAPIKey, '야놀자') || isKeyMerchant(query.publicAPIKey, '티몬')) {
      // Intent
      url = `intent://${schemePath}?${q}#Intent;scheme=${scheme};package=finance.chai.app;S.market_referrer=${encodeURIComponent(
        q
      )};end`
      // Market scheme 방식도 가능하지만 디바이스에 앱스토어용 앱이 여러개일 때 앱스토어 선택창이 나오는 단점이 있다.
      // url = `market://details?id=finance.chai.app&url=${encodeURIComponent(url)}&referrer=${encodeURIComponent(q)}`;
    }
  } else {
    if (q && (!appScheme || linkMethod !== LINK) && !isIdSubMerchant(subMerchantId, '하이마트')) {
      // Universal link
      url = `https://chai.finance/${schemePath}?os=ios&${q}`
    }
  }
  /**
   * forcedMethod가 있을 때 -> forcedMethod
   * 위메프일 때
   *   1) 안드로이드 앱 결제일 때 -> IFRAME
   *    그 외 링크
   *  iOS 혹은 웹 결제 일 때 -> 링크
   *    아니면 linkMethod
   */
  let method
  if (forcedMethod) {
    method = forcedMethod
  } else if (isKeyMerchant(query.publicAPIKey, '위메프')) {
    if (isAndroid && !!appScheme) {
      // 위메프 안드로이드 웹
      method = IFRAME
    } else {
      method = LINK
    }
  } else {
    method = !isAndroid || !appScheme ? LINK : linkMethod
  }

  const openApp = () =>
    method === LINK
      ? isAndroid && !isSamsungBrowser && !appScheme && isKeyMerchant(query.publicAPIKey, '티몬')
        ? replaceUrl(url)
        : replace(url)
      : setIframeKey((n) => n + 1)

  /* render */
  // (개발용) method 강제 변경 버튼
  const renderButton = (m) => (
    <button style={getButtonStyle(method === m)} onClick={() => setForcedMethod(m)} key={m}>
      {m}
    </button>
  )

  return (
    <>
      {children({
        openApp,
        openStore,
        isApp: !!appScheme,
        url: !isAndroid && !!appScheme ? url : undefined,
      })}

      {iframeKey > 0 && <iframe src={url} key={iframeKey} title="openApp" style={{ visibility: 'hidden' }} />}

      {isDevelopment && [LINK, IFRAME].map(renderButton)}
    </>
  )
}

const WithOpenApp = (props) => {
  const { query } = useApp()

  return query.paymentId ? (
    <WithRequest url={`/payment/${query.paymentId}/env`}>{(env) => <OpenApp {...props} {...env} />}</WithRequest>
  ) : (
    <OpenApp {...props} />
  )
}

export default WithOpenApp

/* helpers */
const replace = (s) => (window.top.location.href = s)

const getButtonStyle = (isActive) =>
  Object.assign(
    { fontSize: 10, padding: 15, width: '50%' },
    isActive && { fontWeight: 'bold', textDecoration: 'underline' }
  )

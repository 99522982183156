import URLSearchParams from '@ungap/url-search-params'

export default ({ search }) => {
  const query = new URLSearchParams(search)
  return {
    publicAPIKey: query.get('publicAPIKey'),
    paymentId: query.get('paymentId'),
    subscriptionId: query.get('subscriptionId'),
    idempotencyKey: query.get('idempotencyKey'),
    returnUrl: query.get('returnUrl'),
    status: query.get('status'),
    language: query.get('language'),
    version: query.get('version'),
  }
}

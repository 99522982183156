import React, { useState, useEffect } from 'react'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { useNav, NavTypes, useApp } from '../../hooks'
import History from './History'
import styles from './Month.module.scss'

const pageSize = 20

const Money = ({ month }) => {
  useNav(NavTypes.GO_BACK, { toApp: true })
  const { t } = useTranslation()
  const { api } = useApp()

  /* state */
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  /* result */
  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const [done, setDone] = useState(false)

  /* api */
  const fetch = async () => {
    try {
      setLoading(true)
      setError(undefined)

      const url = '/user/tx/history'
      const params = { page, pageSize, month }
      const { data } = await api.get(url, { params })

      setPage(p => p + 1)
      setData(prev => [...prev, ...data])
      setDone(data.length < pageSize || data.length === 0)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  /* observer */
  const [ref, inView] = useInView({ rootMargin: '50px' })
  useEffect(() => {
    inView && fetch()
    // eslint-disable-next-line
  }, [inView])

  /* render */
  const more = loading ? (
    <span className={styles.more}>{t('차이머니:불러오는 중...')}</span>
  ) : (
    <button className={styles.more} onClick={fetch} ref={ref}>
      {t('차이머니:더 불러오기')}
    </button>
  )

  return error ? (
    <p className={styles.message}>{error.message}</p>
  ) : data.length === 0 ? (
    <p className={styles.message}>
      {loading ? '' : t('차이머니:이용내역이 없습니다')}
    </p>
  ) : (
    <>
      <History history={data} />
      {!done && more}
    </>
  )
}

export default Money

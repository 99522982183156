import { useState, useEffect, useMemo } from 'react'
import useRAF from './useRAF'

const Counter = ({ initial, dest, onFinish, children }) => {
  const [count, setCount] = useState(initial) // 보여줄 숫자

  useEffect(() => {
    count >= dest && onFinish()
  }, [count, dest, onFinish])

  /* raf */
  const f = useMemo(() => 1000 / 60 + Math.random(), []) // 16.xx
  useRAF(count <= dest, (delta) => {
    setCount((prev) => prev + (delta * dest) / (150 * f))
  })

  return children(count)
}

export default Counter

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import Page from '../../components/Page'
import styles from './List.module.scss'

const List = ({ match, location: { search } }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })

  const renderItem = ({ title, createdAt, id }, index) => {
    const today = DateTime.local()
    const isNew = today.minus({ days: 3 }) < DateTime.fromISO(createdAt)

    return (
      <li className={styles.item} key={index}>
        <Link
          to={{ pathname: `${match.url}/${id}`, search }}
          className={styles.link}
        >
          <section>
            {isNew && <span className={styles.dot} />}
            <time>{DateTime.fromISO(createdAt).toFormat(t('MM.dd'))}</time>
          </section>

          <h1>{title}</h1>
        </Link>
      </li>
    )
  }

  return (
    <WithRequest url="/notice">
      {(notices) => (
        <Page title={t('공지사항:공지사항')}>
          <ul>
            {notices.filter(({ fixed }) => fixed).map(renderItem)}
            {notices.filter(({ fixed }) => !fixed).map(renderItem)}
          </ul>
        </Page>
      )}
    </WithRequest>
  )
}

export default List

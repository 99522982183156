import React, { useCallback, useEffect, useState } from 'react'
import { useApp } from '../../hooks'
import styles from './Hana.module.scss'

const HanaIssueTest = () => {
  const { api, handleError } = useApp()
  const [lastData, setLastData] = useState()
  const agent = navigator.userAgent

  // KRT bridge
  const fetchHana = useCallback(async () => {
    try {
      const {
        data: { id, token, userId, ...others },
      } = await api.get('/user/plcc/apply')
      setLastData({ chaiToken: id, hanaToken: token, ...others })
    } catch (error) {
      handleError(error)
    }
  }, [api, handleError])

  useEffect(() => {
    fetchHana()
  }, [fetchHana])

  return (
    <div className={styles.center}>
      <p style={{ padding: 8 }}>
        <p className={styles.title}>차이카드 최근 신청 데이터 (DEV)</p>
        <pre>{lastData && JSON.stringify(lastData, undefined, 4)}</pre>
        <pre>UserAgent: {agent}</pre>
      </p>
    </div>
  )
}

export default HanaIssueTest

import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react-web'
import cx from 'classnames'

import { useBodyClass } from '../../../hooks'

import { ReactComponent as ChevronRight12 } from '../../../icons/ChevronRight12.svg'
import { ReactComponent as ChaiEvent } from './svg/ChaiEvent.svg'
import { ReactComponent as ChaiIcon } from './svg/ChaiIcon.svg'
import { ReactComponent as HomeTitle } from './svg/HomeTitle.svg'
import { ReactComponent as HomeCount0 } from './svg/HomeCount0.svg'
import { ReactComponent as HomeCount1 } from './svg/HomeCount1.svg'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const heartShine = 'https://static.chai.finance/event/rps/rpsHomeHeartShine.json'
const heartPop = 'https://static.chai.finance/event/rps/rpsHomeHeartPop.json'

const Invitee = ({ match: { params }, history }) => {
  const { api, rpsConfig } = useContext(Context)

  const [hash] = useState(params.hash)
  const [statusData, setStatusData] = useState()
  const [isAnimation, setIsAnimation] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useBodyClass(styles.rpsInvite)

  useEffect(() => {
    getStatusData()

    // eslint-disable-next-line
  }, [])

  const getStatusData = async () => {
    try {
      const { version } = rpsConfig
      const { data } = await api.get(`/event/rps/${version}/invitee/${hash}/status`)
      setStatusData(data)

      if (data && data.status) {
        history.replace(`/event/rps/invitee/${hash}/win`)
      }
    } catch (error) {}
  }

  const isAvailable = () => {
    return !statusData || statusData.status !== 'win'
  }

  const onSubmit = async () => {
    if (submitted) {
      return false
    }

    if (!statusData) {
      window.location.href = 'https://chai.onelink.me/4Gwn/f82e270'
      return false
    }

    setIsAnimation(true)
    setSubmitted(true)

    if (!isAvailable()) {
      history.replace(`/event/rps/invitee/${hash}/win`)
      return false
    }

    setTimeout(() => {
      history.replace(`/event/rps/invitee/${hash}/play`)
    }, 2100)
  }

  return (
    <section className={styles.homeSectionWrap}>
      <section className={styles.homeEvent}>
        <ChaiIcon />
        <div className={styles.right}>
          <ChaiEvent />
          <p>차이와 대결해서 이기고 쿠폰 받아가세요!</p>
        </div>
      </section>

      <section className={styles.homeSection}>
        <p className={styles.ticket}>응모권</p>

        <h1>
          <HomeTitle />
        </h1>

        <div className={styles.stickersWrap}>
          <div className={styles.stickers} />
        </div>
      </section>

      <section className={styles.bottomSection}>
        <div className={styles.blockButton}>
          <button className={styles.primary} onClick={onSubmit}>
            {rpsConfig.text.home.startButton}
          </button>
        </div>

        <Link to="/event/rps/notice">
          차이바위보 유의사항 <ChevronRight12 />
        </Link>
      </section>

      <div className={cx(styles.heart01, styles.inviteeHeart)}>
        <Lottie
          width={64}
          height={64}
          options={{
            path: heartShine,
          }}
        />
        <div>{submitted ? <HomeCount0 /> : <HomeCount1 />}</div>
      </div>
      <div className={cx(styles.heart02, styles.inviteeHeart, isAnimation && styles.animation)}>
        {isAnimation ? (
          <Lottie
            options={{
              path: heartPop,
              loop: false,
            }}
          />
        ) : null}
      </div>
    </section>
  )
}

export default Invitee

import React from 'react'
import cx from 'classnames'
import { ReactComponent as Percent } from '../images/Percent.svg'
import styles from './Loading.module.scss'

const Loading = ({ backdrop }) => (
  <div className={cx(styles.backdrop, backdrop && styles.white)}>
    <div className={styles.box}>
      <Percent className={cx('animated infinite', styles.flip)} />
    </div>
  </div>
)

export default Loading

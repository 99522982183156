import React, { useState, useEffect, useCallback } from 'react'
import { DateTime } from 'luxon'
import { useApp } from '../../hooks'
import Page from '../../components/Page'
import Item from './Item'
import styles from './List.module.scss'

const List = () => {
  const { api, handleError } = useApp()

  /* state */
  const today = DateTime.local()
  const initial = {
    yyyy: Number(today.toFormat('yyyy')),
    mm: Number(today.toFormat('MM'))
  }
  const [values, setValues] = useState(initial)
  const [list, setList] = useState([])
  const { yyyy, mm } = values

  /* Form */
  const handleChange = e => {
    const { name, value } = e.target
    setValues(values => ({ ...values, [name]: Number(value) }))
  }

  /* API로부터 목록 가져오기 */
  const fetchList = useCallback(async () => {
    const month = `${yyyy}-${String(mm).padStart(2, '0')}`
    const url = `/user/payment?page=0&pageSize=20&month=${month}`

    try {
      const { data } = await api.get(url)
      setList(data)
    } catch (error) {
      handleError(error)
    }
    // eslint-disable-next-line
  }, [yyyy, mm])

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line
  }, [yyyy, mm])

  const submit = e => {
    e.preventDefault()
    fetchList()
  }

  return (
    <Page title="결제내역">
      <form onSubmit={submit} className={styles.form}>
        <input type="number" name="yyyy" value={yyyy} onChange={handleChange} />
        년
        <input type="number" name="mm" value={mm} onChange={handleChange} />월
      </form>

      {list.length ? (
        list.map((payment, index) => (
          <Item payment={payment} onCancel={fetchList} key={index} />
        ))
      ) : (
        <p>결제내역 없음</p>
      )}
    </Page>
  )
}

export default List

import React from 'react'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import Article from '../../components/Article'

const Agreement = ({ match }) => {
  const isTerms = match.path === '/terms/:id' /* 앱으로부터 접근하는 경로 */
  useNav(NavTypes.GO_BACK, { toApp: isTerms })
  return (
    <WithRequest url={'/agreement/' + match.params.id}>
      {({ title, description }) => (
        <Article title={title}>
          <section dangerouslySetInnerHTML={{ __html: description }} />
        </Article>
      )}
    </WithRequest>
  )
}

export default Agreement

import { useState, useEffect } from 'react'
import useApp from './useApp'

export default (url) => {
  const { api, handleError } = useApp()
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const request = async () => {
      setIsLoading(true)

      try {
        const { data } = await api.get(url)
        setData(data)
      } catch (error) {
        setError(error)
        handleError(error)
      }

      setIsLoading(false)
    }

    request()
    // eslint-disable-next-line
  }, [])

  return { data, isLoading, error }
}

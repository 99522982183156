import React, { useState } from 'react'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ga } from '../utils'
import { useApp, useModal, useUserCancel } from '../hooks'
import Drawer from '../components/Drawer'
import Actions from '../components/Actions'

const propTypes = { children: func }
const defaultProps = { children: () => null }

/* 닫기 버튼 */
const Cancel = ({ children }) => {
  const { t } = useTranslation()
  const { query, env } = useApp()
  const usercancel = useUserCancel()
  const { returnUrl } = query
  const { isSubscription } = env
  const modal = useModal()

  /* 중복 취소를 방지하기 위해 취소 여부를 저장 */
  const [canceled, setCanceled] = useState(false)

  /* 사용자 컨펌 */
  const confirm = () => {
    /* 결제 취소 API에 전달하고 가맹점으로 돌려보내기 */
    const cancel = async () => {
      setCanceled(true)
      ga.button('공통', '닫기')
      await usercancel()
    }

    const title = isSubscription ? t('결제:등록을 취소하시겠습니까?') : t('결제:결제를 취소하시겠습니까?')
    const list = [
      {
        text: t('일반:예'),
        onClick: async () => {
          !canceled && (await cancel())
          modal.close()
        },
        disabled: canceled,
      },
      {
        text: t('일반:아니오'),
        onClick: () => {
          modal.close()
        },
      },
    ]

    modal.open(<Actions list={list} />, { title })
  }

  return !returnUrl ? null : (
    <>
      {children(confirm, canceled)}
      <Drawer config={modal.config}>{modal.content}</Drawer>
    </>
  )
}

Cancel.propTypes = propTypes
Cancel.defaultProps = defaultProps

export default Cancel

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import * as Sentry from '@sentry/browser'
import Error from './components/Error'

Sentry.init({
  dsn: 'https://84c92a08809245f0a4c645b1a446eeca@sentry-pub.chai.finance/7',
  environment: process.env.REACT_APP_ENV,
})

class ErrorBoundary extends Component {
  state = { error: null }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    const { children, t } = this.props
    const { error } = this.state

    // Fallback UI
    // 에러가 없을 땐 원래대로 렌더한다.
    return error ? <Error>{t('오류:오류가 발생했습니다')}</Error> : children
  }
}

export default withTranslation()(ErrorBoundary)

import React from 'react'
import { parseSearchParams } from '../utils'
import Footer from '../components/Footer'
import { getMessages } from '../pages/payments/Receipt'

/* 가맹점 데모: 결제 완료 */
const Complete = ({ location }) => (
  <>
    <p>{getMessages(parseSearchParams(location).status)}</p>

    <Footer>
      <a
        href="/demo"
        className="btn btn-block btn-primary"
        style={{ background: '#f27935' /* TMON 색상 */ }}
      >
        확인
      </a>
    </Footer>
  </>
)

export default Complete

import React, { createContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { useApp } from '../../../hooks'
import NotFound from '../../NotFound'

import AcpDetail from './AcpDetail'
import MyAcp from './MyAcp'

export const Context = createContext()

const Acp = ({ match }) => {
  const { api, handleError } = useApp()
  /* context */
  const value = {
    api,
    handleError,
  }

  return (
    <Context.Provider value={value}>
      <Switch>
        <Route path={match.url + '/myPage'} component={MyAcp} />
        <Route path={match.url + '/detail'} component={AcpDetail} />

        <Route path={match.url + '/'} component={MyAcp} />
        {/* Not found */}
        <Route component={NotFound} />
      </Switch>
    </Context.Provider>
  )
}

export default Acp

import React, { useContext } from 'react'

import { useBodyClass } from '../../../hooks'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const RpsConfig = ({ history }) => {
  const { rpsConfig } = useContext(Context)

  useBodyClass(styles.rps)

  const getRpsConfig = () => {
    try {
      return JSON.stringify(rpsConfig, null, 2)
    } catch (error) {
      return JSON.stringify({})
    }
  }

  return (
    <section className={styles.noticeSectionWrap}>
      <section className={styles.noticeSection}>
        <pre>
          <code>{getRpsConfig()}</code>
        </pre>
      </section>

      <section className={styles.bottomSection}>
        <div className={styles.blockButton}>
          <button className={styles.primary} onClick={() => history.goBack()}>
            확인
          </button>
        </div>
      </section>
    </section>
  )
}

export default RpsConfig

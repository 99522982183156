import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { isMobile } from '../env'
import { format, is, sanitizeNumber, getMaxLength, ga } from '../utils'
import { useApp, useUser, useAnalytics } from '../hooks'
import { useTheme, ThemeTypes, useNav, NavTypes } from '../hooks'
import Page from '../components/Page'
import Divider from '../components/Divider'
import InputGroup from './InputGroup'

const SCREEN = 'SMS'

const AppLink = ({ location, history }) => {
  const { t } = useTranslation()

  useTheme(ThemeTypes.AUTH)
  useNav(isMobile ? ThemeTypes.NONE : NavTypes.GO_BACK)
  useAnalytics(SCREEN)
  const { api, handleError, toast } = useApp()
  const { user } = useUser()

  /* Form */
  const [phone, setPhone] = useState(user ? format.phone(user.phone) : '')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const maxLength = getMaxLength(phone) + 2

  /* Validation */
  const valid = is.phone(phone)
  const disabled = !valid || isSubmitting

  /* 제출 */
  const submit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    ga.button(SCREEN, 'Send')

    try {
      await api.get('/appLink', { params: { phone: sanitizeNumber(phone) } })
      toast(t('앱링크:잠시 후 메시지가 도착합니다'))
      setIsSubmitting(false)
      history.push('/exist' + location.search)
    } catch (error) {
      handleError(error)
      setIsSubmitting(false)
    }
  }

  return (
    <Page>
      <form onSubmit={submit}>
        <p>
          <Trans i18nKey="앱링크:회원가입 및 정보 변경은 차이 앱에서 가능합니다.<0/>휴대폰으로 앱 설치 링크를 보내드리겠습니다.">
            <br />
          </Trans>
        </p>
        <Divider />

        <label>{t('앱링크:휴대폰 번호')}</label>
        <InputGroup>
          {attrs => (
            <input
              {...attrs}
              type="tel"
              name="phone"
              value={phone}
              onChange={e => setPhone(format.phone(e.target.value))}
              maxLength={maxLength}
              readOnly={isSubmitting}
              placeholder="010 1234 5678"
              className="input"
              autoComplete="off"
              autoFocus
            />
          )}
        </InputGroup>

        <button
          type="submit"
          onClick={submit}
          disabled={disabled}
          className="btn btn-block btn-primary btn-submit"
        >
          {t('앱링크:전송하기')}
        </button>
      </form>
    </Page>
  )
}

export default AppLink

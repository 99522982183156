import { isDevelopment } from '../../env'
import { isKeyMerchant } from '../../merchant'

/**
 * 가맹점 ReturnURl query param 을 가맹점 별로 만들어준다.
 * @param query
 * @param status
 * @param errorMessage
 * @param errorCode
 * @returns {*&{errorMessage, errorCode, message, status}}
 */
export default ({ query, status, errorMessage, errorCode }) => {
  const { publicAPIKey } = query

  const message = errorMessage
  let params = {}
  if (isDevelopment || isKeyMerchant(publicAPIKey, 'SK매직')) {
    const { returnUrl, ...queryString } = query
    params = { ...queryString, status, message, errorMessage, errorCode }
  } else {
    params = { ...query, status, message, errorMessage, errorCode }
  }

  return params
}

import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { format } from '../../utils'
import styles from './CouponItem.module.scss'

const Coupon = ({ coupon, to, onClick }) => {
  const { promotionId, title, merchant, status } = coupon
  const { priceMin, startAt, endAt } = coupon
  const period = getPeriod([startAt, endAt])
  const button = {
    label: { enabled: '발급완료', used: '사용완료' }[status] || '다운받기',
    className: { enabled: styles.black, used: styles.muted }[status]
  }

  return (
    <article className={styles.Coupon}>
      <section>
        <header>
          {merchant && (
            <section className={styles.merchant}>
              <img src={merchant.logoUrl} alt="로고" width={18} height={18} />
              {merchant.name}
            </section>
          )}

          {to ? (
            <Link to={to} className={cx(styles.title, button.className)}>
              {title}
            </Link>
          ) : (
            <h1 className={cx(styles.title, button.className)}>{title}</h1>
          )}
        </header>

        {priceMin && (
          <p className={styles.muted}>{format.price(priceMin)}원 이상 결제시</p>
        )}

        {period && <p className={styles.muted}>유효기간 {period}</p>}
      </section>
      
      <button
        onClick={() => onClick([promotionId])}
        disabled={!promotionId}
        className={cx(styles.download, button.className)}
      >
        {button.label}
      </button>
    </article>
  )
}

export default Coupon

/* utils */
const parseDate = string => {
  const d = new Date(string)
  const yyyy = d.getFullYear()
  const mm = d.getMonth() + 1
  const dd = d.getDate()
  return { yyyy, mm, dd }
}

const stringify = string => {
  const { mm, dd } = parseDate(string)
  return [mm, dd].join('.')
}

const getPeriod = array =>
  array
    .filter(Boolean)
    .map(stringify)
    .join(' - ')

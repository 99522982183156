import { string, func } from 'prop-types'
import useRequest from './useRequest'

const propTypes = { url: string, children: func }
const defaultProps = { url: '', children: () => null }

/* 특정 API의 결과가 있어야 렌더할 수 있는 컴포넌트를 감싼다 */
const WithRequest = ({ url, children }) => {
  const { data, isLoading, error } = useRequest(url)
  return isLoading || error ? null : children(data)
}

WithRequest.propTypes = propTypes
WithRequest.defaultProps = defaultProps

export default WithRequest

import { useEffect } from 'react'

export default (property, getValue = () => undefined) => {
  // 마운트하면 body 스타일을 변경하고,
  // 마운트 해제할 때 원래 스타일로 되돌려 놓는 hook
  useEffect(() => {
    const getProperty = () => document.body.style[property]
    const setProperty = value => (document.body.style[property] = value)
    const value = getValue() // 이 시점에 값을 얻어야하므로 함수로 전달받는다.
    const initial = getProperty()
    value && setProperty(value)
    return () => setProperty(initial)
  })
}

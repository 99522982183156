import React from 'react'
import { WithRequest } from '../../hooks'
import ReactSpeedometer from 'react-d3-speedometer'
import Page from '../../components/Page'

const Burning = () => {
  return (
    <WithRequest url="/burning">
      {(burning) => {
        const { voltage, bucketIndex, decayedVoltage, availableBoltCount, wheelPeak } = burning.burningResult

        return (
          <Page title={'🔥 Burning'}>
            <span>
              사용가능한 번개: {availableBoltCount} 개, 전압: {decayedVoltage} ({voltage})
            </span>
            <ReactSpeedometer
              minValue={0}
              maxValue={wheelPeak}
              value={decayedVoltage}
              maxSegmentLabels={0}
              segments={5555}
              startColor="yellow"
              endColor="red"
              ringWidth={60}
              needleTransitionDuration={3333}
              needleTransition="easeElastic"
              currentValueText="Voltage"
            />
            <ReactSpeedometer
              minValue={0}
              maxValue={10}
              needleHeightRatio={0.7}
              value={bucketIndex + 0.5}
              currentValueText="Burning index"
              customSegmentStops={[0, 1, 2, 8, 9, 10]}
              segmentColors={['#0054FF', '#00D8FF', '#FFE400', '#FF5E00', '#FF0000']}
              customSegmentLabels={['', '', '', '', '']}
              ringWidth={60}
              needleTransitionDuration={3333}
              needleTransition="easeElastic"
              needleColor={'#90f2ff'}
            />
          </Page>
        )
      }}
    </WithRequest>
  )
}

export default Burning

/* 웹결제가 모두 공개될 때 사라질 임시 코드입니다. */
const MerchantPublicKeysList = {
  티몬: [
    '8f018353-4876-4bce-b85c-231379fd2676', // Production
    '471943b3771c81abf73edc61e9a30ec0fe0a39637a6c972e3b8bde474f67559c', // Staging
  ],
  위메프: [
    '97062901-6bb0-4307-8077-9b02da80dc8f', // Staging
    'ff0dae36-982b-42cb-a98a-0b5ed7d4ed9f', // Production
  ],
  야놀자: [
    'bf92eb02-5caf-469d-b788-0be708862bc7', // Production
    'd41f6e83-1e9e-46da-9e1d-12474ade2549', // Production
    'd27eee65-80a2-448c-90cf-d38d3e2e8426', // Production
    'd887ecdb-f8ed-4e86-8275-4577df1fbb0a', // Production
    'c15e3b95-0557-4fb9-a739-366012940d58', // Production
    'cfa8aa20-76c2-4a2e-bd45-9561ac0ecfdf', // Staging
    'ebf2329e-2228-4729-9ecf-5eb8abcc8e5b', // Staging
    'ab41002b-1f0f-49f4-bae1-4c9248451281', // Staging
    'd3656c35-6623-426a-ba62-40cac5933c2f', // Staging
  ],
  번개장터: [
    '8e3ec16a-5b89-43af-b224-15a7c31ea1f6', // Production
    'bb95e86b-822c-4ace-9e2e-6a0fe2b2056c', // 번개장터 (BGZT)
    '1604e600-34ba-4dbe-963e-71b6c19fa4fb', // 번개장터 (상품권)
    'bef70ff2-32ed-4bf1-aaea-f9cffbbae239', // 번개장터 (광고포인트)
    '6ac26276-6756-4b37-b734-7935305250ce', // 번개장터 (컨시어지)
    'f33dae60-8de9-4fcb-a767-201865b0cb7e', // 번개장터 (직거래)
    '19e4821c-1453-48f2-bb2e-821597ee7394', // 번개장터 (택배)
  ],
  투믹스: ['3c41373a-c887-47d0-908d-8238de6a3604'], // Production
  아이디어스: [
    'f5bb9912-f7cb-40ac-a99a-fb69e9cd9f07', // Production
  ],
  에이블씨엔씨: [
    'd6df59bd-21eb-4e8b-8dd6-709f2c7378ac', // Production
  ],
  이니시스: [
    '596ae2fe-a971-4f74-b8e5-ea21cc50b124', // Production
  ],
  교보: [
    '7693252f-71df-4dee-b9cb-99ca3ab2f55e', // Production
  ],
  interpark: [
    'fa0e7f58-2441-455f-b95e-f6e4c6725b9a', // Prod - 해외 호텔
    '275bc8f5-0a9b-40d8-848b-8fc78b0b0b53', // Prod - 국내 숙박
    '7acb2e95-a5f7-43a3-8b32-2775250dca56', // Prod - 문화비
    '74ad109d-4167-4a64-9efa-7805a8990421', // Prod - 국내 숙박
    '7a06fba3-45e6-4529-a1c9-b95be0ed9e97', // Prod - 티켓
    '2a8d597a-e1a7-49ed-ad4f-607ed94d25fb', // Prod - 여행
    '02f8fc13-b916-442a-a089-20a1e18355eb', // Prod - 도서
    '51cfb1df-f52c-4358-87ba-66bb3a19d4e4', // Prod - 쇼핑
  ],
  SK매직: [
    '29803865-8b31-429c-a48e-de515c8a98be', // Prod
    '0b207798-fb85-45e9-ade7-fee7a76f54a1', // Staging
  ],
}

const SubMerchantIdList = {
  하이마트: [
    6, // Production
  ],
}

/* 웹결제가 모두 공개될 때 사라질 임시 코드입니다. */
export const isKeyMerchant = (publicAPIKey, name) => {
  const keys = MerchantPublicKeysList[name]
  return !!keys && keys.includes(publicAPIKey)
}

/* IOS IFRAME으로 오픈하고, method가 링크가 아닐경우. 유니버셜 링크로 전달시 오류 수정을 위한 코드입니다. 로그 추적 하고, 특정 케이스에서 캐치가 가능 하다면 사라질 예정입니다. */
export const isIdSubMerchant = (id, name) => {
  const keys = SubMerchantIdList[name]
  return !!keys && keys.includes(id)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'ramda'
import { DateTime } from 'luxon'
import Item from './Item'
import styles from './History.module.scss'

const History = ({ history }) => {
  const { t } = useTranslation()
  const group = groupByDate(history, t)

  return group.map(({ title, list }) => (
    <article className={styles.date} key={title}>
      <h1 className={styles.title}>{title}</h1>
      {list.map((item, index) => (
        <Item {...item} key={index} />
      ))}
    </article>
  ))
}

export default History

/* helper */
const groupByDate = (array, t) => {
  const grouped = groupBy(o =>
    DateTime.fromISO(o.createdAt).toFormat(t('날짜:yyyy.MM.dd'))
  )(array)

  return Object.entries(grouped).map(([title, list]) => ({ title, list }))
}

import React, { useContext } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { cond, equals, adjust, not } from 'ramda'
import { useApp, useUser, useNav, NavTypes } from '../hooks'
import { ReactComponent as ChevronRight14 } from '../icons/ChevronRight14.svg'
import Page from '../components/Page'
import Check from '../components/Check'
import { Context } from '../pages/agreements/Agreements'
import styles from './Agreements.module.scss'

const Checkbox = ({ children, ...attrs }) => (
  <label className={styles.Checkbox}>
    <span className={styles.label}>{children}</span>
    <Check checked={attrs.checked} />
    {attrs.name && <input type="checkbox" {...attrs} hidden />}
  </label>
)

/* 약관 동의 */
const Agreements = ({ location, history, match }) => {
  useNav(NavTypes.CANCEL)
  const { search } = location

  /* context */
  const { auth, api, handleError } = useApp()
  const { setUser } = useUser()
  const { required = [], optional = [], ...form } = useContext(Context)
  const { agreed, setAgreed, initAgreed } = form

  /* conditions */
  const isAgreedAll = agreed['required'] && agreed['optional'].every(v => v)

  /* 토글 */
  const toggleAll = () => {
    // 모두 선택됐다면 모두 해제한다. 그렇지 않으면 모두 선택한다.
    isAgreedAll
      ? initAgreed()
      : setAgreed({ required: true, optional: optional.map(() => true) })
  }

  /* 개별 체크 */
  const handleChange = e => {
    e.persist()

    const { name, value } = e.target
    const getValue = cond([
      [equals('required'), () => !agreed['required']],
      [equals('optional'), () => adjust(Number(value), not, agreed['optional'])]
    ])

    setAgreed({ [name]: getValue(name) })
  }

  /* 제출: 원래 경로로 돌아가기, 회원가입 중이라면 유저 상태에 동의한 아이디를 저장 */
  const submit = async e => {
    const getId = ({ id }) => id
    const getAgreedIds = () => ({
      agreedIds: [
        ...required.map(getId),
        ...optional.filter((_, index) => agreed['optional'][index]).map(getId)
      ],
      disagreedIds: [
        ...optional.filter((_, index) => !agreed['optional'][index]).map(getId)
      ]
    })

    e.preventDefault()

    if (match.url === '/agree') {
      try {
        await api.post('/user/agreement', getAgreedIds())
        await auth.getAuth()
        history.replace('/' + location.search)
      } catch (error) {
        handleError(error)
      }
    } else {
      setUser({ agreementIds: getAgreedIds()['agreedIds'] })
      history.replace('/register' + location.search)
    }
  }

  /* 약관 상세 페이지 링크 */
  const getLink = id => ({ pathname: `${match.url}/${id}`, search })
  const footer = {
    onClick: submit,
    disabled: !agreed['required'],
    className: 'btn btn-block btn-primary',
    children: '다음'
  }

  return (
    <Page form onSubmit={submit} footer={footer} title="약관동의">
      {!required.length ? (
        '약관 내용을 가져오는 중입니다'
      ) : (
        <>
          <button type="button" className={styles.button} onClick={toggleAll}>
            <Checkbox checked={isAgreedAll}>약관 전체 동의</Checkbox>
          </button>

          <div className={styles.section}>
            <Checkbox
              name="required"
              checked={agreed['required']}
              onChange={handleChange}
            >
              필수 약관 전체동의
            </Checkbox>

            <ul className={styles.list}>
              {required.map(({ id, title, hasDescription }, index) =>
                hasDescription ? (
                  <li key={index}>
                    <Link to={getLink(id)} className={styles.item}>
                      {title}
                      {hasDescription && (
                        <div className={styles.caret}>
                          <ChevronRight14 />
                        </div>
                      )}
                    </Link>
                  </li>
                ) : (
                  <li key={index} className={styles.item}>
                    {title}
                  </li>
                )
              )}
            </ul>
          </div>

          {optional.map(({ id, title, hasDescription }, index) => {
            const text = title + ' (선택)'
            return (
              <div className={styles.section} key={index}>
                <Checkbox
                  name="optional"
                  value={index}
                  checked={agreed['optional'][index]}
                  onChange={handleChange}
                >
                  {hasDescription ? <Link to={getLink(id)}>{text}</Link> : text}
                </Checkbox>
              </div>
            )
          })}
        </>
      )}
    </Page>
  )
}

export default withRouter(Agreements)

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import OpenAppWithShortenLink from './OpenAppWithShortenLink'

const Shorten = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + `/:group/:shortenId`} component={OpenAppWithShortenLink} />
      <Route path={match.url + `/:shortenId`} component={OpenAppWithShortenLink} />
    </Switch>
  )
}

export default Shorten

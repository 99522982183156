import React, { useContext, useEffect, useState } from 'react'
import Page from '../../components/Page'
import styles from './List.module.scss'
import { Context } from './Barcode'

const Item = ({ match: { params } }) => {
  const { barcodeData } = useContext(Context)

  const [merchant, setMerchant] = useState()

  useEffect(() => {
    if (barcodeData.merchants) {
      setMerchant(barcodeData.merchants.find((merchant) => merchant.id === params.merchantId))
    }
  }, [params, barcodeData])

  if (!merchant) {
    return <></>
  }

  return (
    <Page title={merchant.name} mainClassName={styles.detail}>
      <section>
        <h3>혜택 안내</h3>
        {merchant.benefit.split('\n').map((benefit, index) => (
          <p key={index}>{benefit}</p>
        ))}
      </section>

      <section>
        <h3>사용 불가 매장</h3>
        {merchant.disableBranch.split('\n').map((disableBranch, index) => (
          <p key={index}>{disableBranch}</p>
        ))}
      </section>

      <section>
        <h3>유의사항</h3>
        {merchant.notice.split('\n').map((notice, index) => (
          <p key={index}>{notice}</p>
        ))}
      </section>
    </Page>
  )
}

export default Item

import React from 'react'
import styles from './Bridge.module.scss'
import bridgeLogo from './img-chai-app-icon-rounded@3x.png'

const Bridge = ({ children }) =>
  children ? (
    <div className={styles.bridge}>
      <div>
        <img alt="logo" src={bridgeLogo} width="112px" height="112px" />
        <p className={styles.bridgeWrapper}>{children}</p>
      </div>
    </div>
  ) : null

export default Bridge

import React, { useState, useRef } from 'react'
import { string, object, shape, func } from 'prop-types'
import { isDesktop } from '../../env'
import { sanitizeNumber } from '../../utils'
import { useNav, NavTypes } from '../../hooks'
import { getUtils } from './helpers'
import { ReactComponent as Caret } from '../../icons/ChevronDownLight.svg'
import Page from '../../components/Page'
import Select from '../../components/Select'
import Footer from '../../components/Footer'
import InputGroup from '../../forms/InputGroup'

const propTypes = {
  initial: shape({ type: string, number: string }).isRequired,
  types: object.isRequired,
  onSubmit: func.isRequired
}

const Form = ({ initial, types, onSubmit }) => {
  useNav(NavTypes.GO_BACK)

  /* ref */
  const inputRef = useRef()

  /* props */
  const mapTypes = ([value, children]) => ({ value, children })
  const entries = Object.entries(types).map(mapTypes)

  /* state */
  const getInitialState = () => ({
    type: initial.type || (entries[0] && entries[0]['value']) || '',
    number: getUtils(initial).formatted || ''
  })

  const [values, setValues] = useState(getInitialState)
  const { type, number } = values

  /* helpers */
  const { format, valid, maxLength, placeholder } = getUtils(values)

  /* 이벤트 핸들러 */
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: format(value) })
  }

  const setType = value => {
    value !== type && setValues({ type: value, number: '' })
    inputRef.current.focus()
  }

  /* 제출 */
  const submit = async e => {
    e.preventDefault()
    onSubmit({
      cashReceiptType: type,
      cashReceiptNumber: sanitizeNumber(number)
    })
  }

  const renderSubmitButton = () => (
    <button
      type="submit"
      onClick={submit}
      disabled={!valid}
      className="btn btn-block btn-primary btn-submit"
    >
      완료
    </button>
  )

  const typeAttrs = {
    name: 'type',
    label: '공제유형 선택',
    value: type,
    className: 'select'
  }

  return (
    <Page title="현금 영수증">
      <form onSubmit={submit}>
        <label>종류</label>
        <InputGroup>
          {attrs =>
            isDesktop ? (
              <>
                <select {...typeAttrs} onChange={e => setType(e.target.value)}>
                  {entries.map((option, index) => (
                    <option {...option} key={index} />
                  ))}
                </select>
                <Caret />
              </>
            ) : (
              <Select
                {...attrs}
                {...typeAttrs}
                onChange={({ value }) => setType(value)}
                options={entries}
              />
            )
          }
        </InputGroup>

        <label>{types[type].split(' - ')[1]}</label>
        <InputGroup>
          {attrs => (
            <input
              {...attrs}
              ref={inputRef}
              type="tel"
              name="number"
              value={number}
              onChange={handleChange}
              maxLength={maxLength}
              placeholder={placeholder}
              className="input"
              autoComplete="off"
              autoFocus
            />
          )}
        </InputGroup>

        {isDesktop ? (
          <Footer>{renderSubmitButton()}</Footer>
        ) : (
          renderSubmitButton()
        )}
      </form>
    </Page>
  )
}

Form.propTypes = propTypes

// 현금영수증 타입과 번호를 콜백으로 받아 /pay 경로에 state를 통해 전달한다.
const SetCashReceipt = ({ location: { search, state }, history, data }) => {
  const { cashReceiptTypes: types = {} } = data || {}
  const onSubmit = data => history.replace('/pay' + search, data)
  return <Form initial={state || {}} types={types} onSubmit={onSubmit} />
}

export default SetCashReceipt

import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios'
import qs from 'qs'
import { Switch, Route } from 'react-router-dom'
import { DateTime } from 'luxon'

import { EventRpsConfigUrl } from '../../../Constants'
import { isAndroid } from '../../../env'

import { useApp } from '../../../hooks'

import Loading from '../../../components/Loading'

import NotFound from '../../NotFound'
import RpsNotice from './RpsNotice'
import RpsEnd from './RpsEnd'
import Invitee from './Invitee'
import InviteePlay from './InviteePlay'
import InviteeWin from './InviteeWin'
import RpsHome from './RpsHome'
import RpsPlay from './RpsPlay'
import RpsWin from './RpsWin'
import RpsDraw from './RpsDraw'
import RpsLose from './RpsLose'
import RpsConfig from './RpsConfig'

export const Context = createContext()

const Rps = ({ match, history }) => {
  const { api, handleError, toast } = useApp()
  const [rpsConfig, setRpsConfig] = useState()
  const [isConfigLoading, setIsConfigLoading] = useState(false)
  const [statusData, setStatusData] = useState()

  useEffect(() => {
    const initConfig = async () => {
      setIsConfigLoading(true)

      try {
        const { data } = await axios.get(EventRpsConfigUrl)
        setRpsConfig(data)
      } catch (error) {
        handleError(error)
      }

      setIsConfigLoading(false)
    }

    if (!isConfigLoading) {
      initConfig()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    if (search.buildNumber) {
      localStorage.setItem('buildNumber', search.buildNumber)
    }

    try {
      const args = JSON.stringify({
        bounces: false,
      })

      if (!isAndroid) {
        window.ChaiiOSHandler.postAction('', 'setScrollConfig', args)
      }
    } catch (e) {}
  }, [history])

  /**
   * @deprecated TODO inviteeWin 화면에 이 함수를 사용하는게 남아있는데, 공유하기 고칠 때 같이 손봐야한다.
   * @see getTimeRemainingByEndAt
   * @param issuedAt
   * @returns {{hours, minutes: number}}
   */
  const getTimeRemainingByIssuedAt = (issuedAt) => {
    const issuedDateTime = issuedAt ? DateTime.fromISO(issuedAt) : DateTime.local()
    const expirationDateTime = issuedDateTime.endOf('day').plus({ days: 1 })
    const { hours, minutes } = expirationDateTime.diffNow(['hours', 'minutes']).toObject()
    return {
      hours,
      minutes: Math.max(Math.floor(minutes), 1),
    }
  }

  /**
   * 쿠폰 만료일을 기준으로 남은 시간을 계산
   * @param endAt
   */
  const getTimeRemainingByEndAt = (endAt) => {
    if (!endAt) {
      return {
        hours: 0,
        minutes: 0,
      }
    }

    const expirationDateTime = DateTime.fromISO(endAt)
    const { hours, minutes } = expirationDateTime.diffNow(['hours', 'minutes']).toObject()

    return {
      hours,
      minutes: Math.max(Math.floor(minutes), 1),
    }
  }

  const getStatusData = async () => {
    // 이벤트 기간 체크. 없으면 체크 안함
    const { version, maxAttempt, period: { start, end } = {} } = rpsConfig
    if ((start && new Date(start).getTime() > Date.now()) || (end && new Date(end).getTime() < Date.now())) {
      history.replace('/event/rps/end')
      return
    }

    try {
      const { data } = await api.get(`/event/rps/${version}/status`)
      setStatusData(data)

      if (data) {
        switch (data.status) {
          case undefined:
          case 'ready':
            sendEvent('view_chai_rps')
            history.replace('/event/rps')
            break

          case 'start':
          case 'draw':
            history.replace('/event/rps/play')
            break

          case 'win':
            history.replace('/event/rps/win')
            break
          case 'end':
            if (data.hasUsableCoupon) {
              history.replace('/event/rps/win')
            } else {
              // 쿠폰이 사용 가능한 상태가 아닌데 쓴 적이 없다면 만료된 쿠폰이다.
              if (!data.isUsedCoupon) {
                sendEvent('view_coupon_expired')
              }

              history.replace('/event/rps/end')
            }
            break

          case 'lose':
            if (data.attempt < maxAttempt) {
              history.replace('/event/rps/lose')
            } else {
              history.replace('/event/rps/end')
            }
            break

          default:
            history.replace('/event/rps')
            break
        }
      }
    } catch (error) {}
  }

  const sendEvent = async (action) => {
    try {
      const { version } = rpsConfig
      await api.post(`/event/rps/${version}/event`, { action })
    } catch (error) {}
  }

  /* context */
  const value = {
    api,
    handleError,
    toast,
    rpsConfig,
    statusData,
    getTimeRemainingByIssuedAt,
    getTimeRemainingByEndAt,
    getStatusData,
    sendEvent,
  }

  return !isConfigLoading && rpsConfig ? (
    <Context.Provider value={value}>
      <Switch>
        <Route path={match.url + '/notice'} component={RpsNotice} />
        <Route path={match.url + '/end'} component={RpsEnd} />
        <Route path={match.url + '/invitee/:hash/play'} component={InviteePlay} />
        <Route path={match.url + '/invitee/:hash/win'} component={InviteeWin} />
        <Route path={match.url + '/invitee/:hash'} component={Invitee} />
        <Route path={match.url + '/play'} component={RpsPlay} />
        <Route path={match.url + '/win'} component={RpsWin} />
        <Route path={match.url + '/draw'} component={RpsDraw} />
        <Route path={match.url + '/lose'} component={RpsLose} />
        <Route path={match.url + '/config'} component={RpsConfig} />
        <Route path={match.url + '/'} component={RpsHome} />

        {/* Not found */}
        <Route component={NotFound} />
      </Switch>
    </Context.Provider>
  ) : (
    <Loading backdrop />
  )
}

export default Rps

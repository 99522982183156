import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isProduction, isAndroid } from '../env'
import { ga } from '../utils'
import Page from '../components/Page'
import Benefit from '../components/Benefit'
import Footer from '../components/Footer'

const SCREEN = 'OpenApp'
const PATH = 'chai://benefit'

const PROMOTIONS = [
  {
    benefitType: 'cashback',
    endAt: '2020-02-29T14:59:59.000Z',
    discount: '5000',
    priceMin: 10000,
    isUsableOnFirstPurchase: true,
  },
  {
    benefitType: 'cashback',
    endAt: '2020-02-29T14:59:59.000Z',
    discount: '10%',
    priceMin: 10000,
    isUsableOnFirstPurchase: false,
  },
]

const OpenApp = () => {
  const { t } = useTranslation()
  const TITLE = t('혜택:CU에서는\n매일 매일\n차이머니 쌓이는 재미!')

  useEffect(() => {
    isProduction && ga.screen(SCREEN)
    !isAndroid && replace(PATH)
    // eslint-disable-next-line
  }, [])

  return !isAndroid ? null : (
    <Page title={TITLE}>
      <Benefit promotions={PROMOTIONS} />
      <Footer>
        <a href={PATH} className="btn btn-block btn-primary">
          {t('혜택:차이앱에서 혜택 더보기')}
        </a>
      </Footer>
    </Page>
  )
}

const replace = (s) =>
  process.env.NODE_ENV === 'production'
    ? window.location.replace(s)
    : console.info(s)

export default OpenApp

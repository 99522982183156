import React from 'react'
import styles from './Progress.module.scss'

const Progress = ({ w = 36, t = 2, percentage = 0, children }) => {
  const r = (w - t) / 2
  const c = 2 * Math.PI * r
  const attrs = { r, cx: w / 2, cy: w / 2, strokeWidth: t, fill: 'none' }
  const dash = c * ((100 - percentage) / 100)
  const progress = { strokeDasharray: c, strokeDashoffset: dash }

  return (
    <div className={styles.container} style={{ width: w, height: w }}>
      <svg width={w} height={w} viewBox={`0 0 ${w} ${w}`}>
        <circle {...attrs} className={styles.track} />
        <circle {...attrs} className={styles.progress} {...progress} />
      </svg>

      <span className={styles.text}>{children}</span>
    </div>
  )
}

export default Progress

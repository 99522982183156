import React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from '../../utils'
import { ReactComponent as Logo } from '../../images/Percent.svg'
import { ReactComponent as ExclamationCircle } from '../../icons/ExclamationCircle.svg'
import Select from '../../components/Select'
import styles from './Money.module.scss'

const Money = ({ payment, ...rest }) => {
  const { t } = useTranslation()
  const { fetchAccounts, onFetchAccounts, selectAttrs } = rest
  const { userBalance, chargingAmount, cashbackAmount, topupMessage } = payment
  const { chargeAccountKey, chargeAccount } = payment
  const { onMaintenance, maintenanceTime } = chargeAccount || {}

  /* 계좌를 선택하려고 할 때, 비동기적으로 계좌정보 새로 가져오기 */
  const getConvertedAccounts = async () => {
    const map = ([value, acc]) => ({ value, children: format.bank(acc) })
    const accounts = await fetchAccounts()
    onFetchAccounts(accounts)
    return accounts ? Object.entries(accounts).map(map) : []
  }

  return (
    <section className={styles.component}>
      <header className={styles.header}>
        <h1 className={styles.title}>
          <div className={styles.logo}>
            <Logo width={12} />
          </div>
          {t('결제:차이머니')}
          {topupMessage && (
            <div className={styles.message}>
              <div className={styles.inner}>
                <ExclamationCircle width={16} height={16} />
                <span>{topupMessage}</span>
              </div>
            </div>
          )}
        </h1>

        {cashbackAmount > 0 && (
          <span className={styles.cashback}>
            {t('결제:{{price}}원 즉시적립', {
              price: format.price(cashbackAmount),
            })}
          </span>
        )}
      </header>

      <dl className={styles.dl}>
        <dt>{t('결제:보유잔액')}</dt>
        <dd>{t('통화:{{price}}원', { price: format.price(userBalance) })}</dd>

        <dt>
          {t('결제:충전')}
          {chargeAccount && (
            <Select
              {...selectAttrs}
              label={t('결제:계좌 선택')}
              wrapperClassName={styles.wrapper}
              className={styles.select}
              getOptions={getConvertedAccounts}
              value={chargeAccountKey}
              caret={null}
            >
              {format.bank(chargeAccount)}
            </Select>
          )}
        </dt>

        {onMaintenance ? (
          <dd className={styles.muted}>
            {t('결제:점검중')} ({maintenanceTime})
          </dd>
        ) : (
          <dd>{t('통화:{{price}}원', { price: format.price(chargingAmount) })}</dd>
        )}
      </dl>
    </section>
  )
}

export default Money

import React from 'react'
import { useBodyClass } from '../hooks'
import styles from './Temp.module.scss'

const Temp = () => {
  useBodyClass(styles.body)

  const getImage = n =>
    `https://static.chai.finance/exhibitions/chai/201912/img_topup_promotion_${n}.png`

  const length = 7
  const linkIndex = 6
  const array = Array.from({ length }, (_, i) => i + 1)
  const blank = { target: '_blank', rel: 'noopener noreferrer' }
  const config = 'chai://topup/config'
  const close = 'chaiinternalweb://close'

  return (
    <>
      <a href={close} className={styles.close}>
        &times;
      </a>

      {array.map(n => {
        const img = (
          <img src={getImage(n)} alt="" className={styles.image} key={n} />
        )

        return n === linkIndex ? (
          <a {...blank} href={config} key="link">
            {img}
          </a>
        ) : (
          img
        )
      })}
    </>
  )
}

export default Temp

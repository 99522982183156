import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Benefit from './Benefit'

const Card = ({ match }) => (
  <Switch>
    <Route path={match.url} component={Benefit} />
  </Switch>
)

export default Card

import React, { useState, useEffect } from 'react'
import { path, equals } from 'ramda'
import qs from 'qs'
import cx from 'classnames'
import { useTranslation, Trans } from 'react-i18next'
import { isDesktop } from '../../env'
import { format, ga } from '../../utils'
import { useApp, useNav, NavTypes } from '../../hooks'
import { useAnalytics, useUserCancel } from '../../hooks'
import { ReactComponent as Check } from './Check.svg'
import Dropdown from '../../components/Dropdown'
import Footer from '../../components/Footer'
import Cancel from '../../layouts/Cancel'
import Money from './Money'
import styles from './Preperation.module.scss'

const SCREEN = 'Payment'

const ChevronDown = () => (
  <svg width="20" height="12">
    <path d="M2.81.12L.7 2.24l9.55 9.55 9.54-9.55L17.66.12l-7.42 7.43z" />
  </svg>
)

/* 결제 준비 */
const Preperation = ({ location: { search, state = {} }, match, history }) => {
  useNav(NavTypes.CANCEL)
  useAnalytics(SCREEN)

  const { t } = useTranslation()
  const { api, query, toast, handleError } = useApp()
  const { paymentId } = query
  const usercancel = useUserCancel()

  /* state */
  const [payment, setPayment] = useState() // 준비된 결제
  const [accounts, setAccounts] = useState() // 각 시점에 사용자의 계좌 목록을 저장
  const [error, setError] = useState()
  const [key, setKey] = useState(0)
  const [timeoutId, setTimeoutId] = useState() // 은행 점검 끝나면 새로고침

  /* onMount */
  useEffect(() => {
    paymentId && prepare(state)
    // eslint-disable-next-line
  }, [])

  /* 점검중인 은행을 선택했을 때, 새로고침 예약 */
  const maintenanceEndTime = path(
    ['chargeAccount', 'maintenanceEndTime'],
    payment
  )

  useEffect(() => {
    const setRefresh = () => {
      // 서버 응답에 점검 완료 시간이 있다면, 그 시간에 prepare()를 새로 불러온다.
      // 단, 점검 완료 시간이 지났으나 백엔드의 반영이 느려서 여전히 점검 완료 시간을 받게된다면,
      // 새로고침의 무한반복에 빠지게 된다. 그것을 방지하기 위해 최소 1초 후 새로고침한다.
      const ms = Math.max(new Date(maintenanceEndTime) - new Date(), 1000)
      const id = setTimeout(() => prepare(), ms)
      setTimeoutId(id)
    }

    maintenanceEndTime && setRefresh()
    // eslint-disable-next-line
  }, [maintenanceEndTime])

  useEffect(() => {
    // 마운트 해제될 때 타임아웃 초기화
    return () => clearTimeout(timeoutId)
  }, [timeoutId])

  const { current, countdown } = useCountdown(3, usercancel)
  const [additionalMessage, setAdditionalMessage] = useState()

  useEffect(() => {
    setAdditionalMessage(
      current ? `${current}초 후 자동으로 결제 창이 닫힙니다.` : ''
    )
  }, [current])

  /* 결제 정보 가져오기 */
  const prepare = async (params) => {
    try {
      const [payment, accounts] = await fetch(params)
      setPayment(payment)
      setAccounts(accounts)
      setKey((k) => k + 1)
    } catch (error) {
      /* 최소/최대 결제금액을 벗어난 경우 오류를 화면에 렌더한다. */
      const handleCode = (code) => {
        ;['P011', 'P012', 'R019'].includes(code)
          ? setError(error.response.data)
          : handleError(error)

        code === 'R019' && countdown()
      }

      error.response && handleCode(error.response.data.code)
    }
  }

  /* 결제 정보 및 사용자의 계좌 목록 가져오기 (다음으로 넘어가기 전에 검증하기 위해 분리) */
  const fetch = async (params) => {
    const { data } = await api.post(`/payment/${paymentId}/prepare`, params)
    const accounts = await fetchAccounts()
    return [data, accounts]
  }

  /* 사용자의 계좌 목록 가져오기 (계좌 선택할 때 단독으로 호출하기 위해 분리) */
  const fetchAccounts = async () => {
    const { data } = await api.get('/user/account')
    return data
  }

  /* 계좌 정보가 그대로라면 approve 아니라면 toast */
  const approve = async () => {
    ga.button(SCREEN, 'Pay')
    const [, latestAccounts] = await fetch()

    if (payment.chargeAccountKey && !latestAccounts[payment.chargeAccountKey]) {
      // 상황: 선택했던 계좌를 삭제해서 사라짐
      // 처리: 다른 계좌를 선택해주고 토스트 출력
      const { chargeAccountKey } =
        Object.values(latestAccounts).find((a) => a.isPrimary) ||
        Object.values(latestAccounts)[0] ||
        {}

      setAccounts(latestAccounts)
      prepare({ chargeAccountKey })
      toast.error(t('결제:결제 계좌가 변경됐습니다'))
    } else if (!equals(accounts, latestAccounts)) {
      // 상황: 계좌 목록이 기존과 상이함
      // 처리: 토스트 출력
      setAccounts(latestAccounts)
      toast.error(t('결제:결제 계좌가 변경됐으니 확인해주세요'))
    } else {
      // 상황: 모든게 정상
      // 처리: 키패드 화면으로 이동
      history.replace(`/approve?${qs.stringify(query)}`)
    }
  }

  const render = () => {
    const { merchantName, billingAmount } = payment
    const { coupon = {}, availCoupons = [], unavailCoupons = [] } = payment
    const { userBalance, chargeAccount, metadata = {} } = payment

    const chaiCouponAmount = Number(metadata.chaiCouponAmount) || 0

    // 결제불가: 결제할 금액이 잔액보다 큰데, 충전 게좌의 은행이 점검중일 때
    const disabled = billingAmount > userBalance && chargeAccount.onMaintenance

    /* 쿠폰 선택 모듈 */
    const hasCoupon = !!availCoupons.length || !!unavailCoupons.length
    const selected = availCoupons.find(({ id }) => id === coupon.couponId)
    const price = (selected ? selected.discountAmount : 0) + chaiCouponAmount

    const getCouponOption = (c) => {
      const min = format.price(c.priceMin)
      return {
        value: c.id,
        children: (
          <span>
            {c.title}
            {c.priceMin && (
              <small>
                ({t('결제:{{price}}원 이상 결제시', { price: min })})
              </small>
            )}
          </span>
        ),
        disabled: !!c.reason,
        additionalText: c.reason,
      }
    }

    const getSelectCoupon = () => ({
      name: 'coupon',
      emptyContent: t('결제:사용 가능한 쿠폰이 없습니다'),
      options: !hasCoupon
        ? []
        : [
            ...availCoupons.map(getCouponOption),
            ...unavailCoupons.map(getCouponOption),
            { value: null, children: t('결제:쿠폰 사용 안함') },
          ],
      onClick: () => ga.button(SCREEN, 'Coupon'),
      onChange: ({ value: discountCoupon }) => prepare({ discountCoupon }),
      caret: (
        <div className={styles.caret}>
          <ChevronDown />
        </div>
      ),
      buttonClassName: (isOpen) => cx(styles.coupon, isOpen && styles.open),
      dropdownClassName: cx(
        styles.dropdown,
        hasCoupon ? styles.list : styles.empty
      ),
      value: selected ? selected.id : null,
      children: price
        ? t('결제:{{price}}원 할인받고', { price: format.price(price) })
        : t('결제:할인없이'),
      shouldNotOpen: chaiCouponAmount && !availCoupons.length,
    })

    return (
      <article
        className={cx(isDesktop && 'desktop', isDesktop && styles.desktop)}
      >
        <section className={styles.description}>
          {merchantName && (
            <p>
              {t('결제:{{merchant}}에서', { merchant: merchantName })}
              <br />
            </p>
          )}

          {!!(availCoupons.length || chaiCouponAmount) && (
            <Dropdown {...getSelectCoupon()} />
          )}

          <p>
            <Trans i18nKey="결제:{{price}}원을<0/>결제합니다">
              <br />
              {{ price: format.price(billingAmount) }}
            </Trans>
          </p>
        </section>

        <Footer>
          <div className={styles.chaiCouponWrapper}>
            {!!chaiCouponAmount && (
              <span className={styles.chaiCoupon}>
                <span className={styles.circle}>
                  <Check />
                </span>

                {t('결제:{{merchantName}}쿠폰 {{price}}원 할인적용', {
                  merchantName,
                  price: format.price(chaiCouponAmount),
                })}
              </span>
            )}
          </div>

          {key > 0 && (
            <Money
              payment={payment}
              fetchAccounts={fetchAccounts}
              onFetchAccounts={setAccounts}
              selectAttrs={{
                onClick: () => ga.button(SCREEN, 'Account'),
                onChange: ({ value }) => {
                  prepare({ chargeAccountKey: value })
                },
              }}
              key={key}
            />
          )}

          <button
            onClick={approve}
            disabled={disabled}
            className="btn btn-block btn-primary"
          >
            {t('결제:결제하기')}
          </button>

          <p className="text-center text-muted btn-submit">
            <small>{t('결제:구매조건 확인 후 동의하시면 결제해주세요')}</small>
          </p>
        </Footer>
      </article>
    )
  }

  const renderError = () => {
    const contents = {
      P011: (
        <Trans i18nKey="결제:<0>최소 결제금액 제한</0>으로<1/>결제가 불가능합니다">
          <span className="red" />
          <br />
        </Trans>
      ),
      P012: (
        <Trans i18nKey="결제:<0>결제 한도 초과</0>로<1/>결제가 불가능합니다">
          <span className="red" />
          <br />
        </Trans>
      ),
    }

    return (
      <article
        className={cx(isDesktop && 'desktop', isDesktop && styles.desktop)}
      >
        <p className={styles.description}>{contents[error.code]}</p>
        <p className={styles.message}>{error.message}</p>
        <p className={styles.message}>{additionalMessage}</p>

        <Footer>
          <Cancel>
            {(onClick, disabled) => (
              <button
                onClick={onClick}
                disabled={disabled}
                className="btn btn-block btn-primary"
              >
                {t('결제:확인')}
              </button>
            )}
          </Cancel>
        </Footer>
      </article>
    )
  }

  return error ? renderError() : payment /* API Response */ ? render() : null
}

export default Preperation

/* hooks */
const useCountdown = (n, callback) => {
  const [current, setCurrent] = useState(n)
  const [timeoutId, setTimeoutId] = useState()

  const timeout = (i) => {
    if (i > 0) {
      const id = setTimeout(() => {
        setCurrent((c) => c - 1)
        timeout(i - 1)
      }, 1000)

      setTimeoutId(id)
    } else {
      callback()
    }
  }

  const countdown = () => {
    timeout(current)
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId)
  }, [timeoutId])

  return { current, countdown }
}

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './List'
import Item from './Item'

const Notices = ({ match }) => (
  <Switch>
    <Route path={match.url + '/:id'} component={Item} />
    <Route path={match.url} component={List} />
  </Switch>
)

export default Notices

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useNav, NavTypes } from '../../hooks'
import { ReactComponent as ChevronDownLarge } from '../../icons/ChevronDownLarge.svg'
import Page from '../../components/Page'
import Select from '../../components/Select'
import Month from './Month'
import styles from './Money.module.scss'

const INITIAL_MONTH = '2020-01'

const Money = () => {
  useNav(NavTypes.GO_BACK, { toApp: true })

  const { t } = useTranslation()
  const [month, setMonth] = useState(getCurrentMonth)

  const format = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).toFormat(t('차이머니:yyyy년 M월'))

  const formatMonth = (yyyyMM) => DateTime.fromFormat(yyyyMM, FMT).toFormat(t('차이머니:M월'))

  const select = {
    value: month,
    options: getMonthList().map((value) => ({ value, children: format(value) })),
    onChange: ({ value }) => setMonth(value),
    label: t('차이머니:조회 월 선택'),
    caret: <ChevronDownLarge />,
    children: t('차이머니:{{month}} 이용내역', { month: formatMonth(month) }),
    className: styles.select,
  }

  const title = (
    <>
      {t('차이머니:차이머니')}
      <Select {...select} />
    </>
  )

  return (
    <Page title={title} front>
      <Month month={month} key={month} />
    </Page>
  )
}

export default Money

/* helper */
const getCurrentMonth = (d = new Date()) => {
  const YYYY = d.getFullYear()
  const MM = String(d.getMonth() + 1).padStart(2, '0')
  return [YYYY, MM].join('-')
}

const FMT = 'yyyy-MM'

const getMonthList = (end = getCurrentMonth()) => {
  const fn = (acc) => {
    const [prev] = acc

    const cur = DateTime.fromFormat(prev, FMT).plus({ months: 1 }).toFormat(FMT)

    const next = [cur, ...acc]

    return cur === end ? next : fn(next)
  }

  return fn([INITIAL_MONTH])
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import Article from '../../components/Article'

const Item = ({ match }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { backLabel: t('공지사항:공지사항') })

  return (
    <WithRequest url={`/notice/${match.params.id}`}>
      {({ title, createdAt, content }) => (
        <Article
          title={title}
          subtitle={
            createdAt &&
            DateTime.fromISO(createdAt).toFormat(t('날짜:yyyy.MM.dd'))
          }
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Article>
      )}
    </WithRequest>
  )
}

export default Item

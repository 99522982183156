import { replace } from '../utils'
import { useApp } from '../hooks'

export default () => {
  const { api, query, handleError, env } = useApp()
  const { paymentId, idempotencyKey, returnUrl, subscriptionId } = query

  const usercancel = async () => {
    const { isSubscription } = env
    try {
      let url = `/payment/${paymentId}/usercancel`
      if (isSubscription) {
        url = `/payment/subscription/${subscriptionId}/usercancel`
      }

      const { data } = await api.post(url)
      const { status, errorMessage: message, errorMessage, errorCode } = data
      const params = { paymentId, subscriptionId, idempotencyKey, status, message, errorMessage, errorCode }
      replace(returnUrl, params)
    } catch (error) {
      handleError(error)
    }
  }

  return usercancel
}

import { cond, equals } from 'ramda'
import isISO8601 from 'validator/lib/isISO8601'
import sanitize from './sanitizeNumber'

export default (string = '') => {
  const validate = (prefix = '') => isISO8601(prefix + number, { strict: true })
  const number = sanitize(string)
  return !!cond([
    [equals(6), () => ['19', '20'].some(validate)],
    [equals(8), () => validate()]
  ])(number.length)
}

import qs from 'qs'

export const getReturnLink = (url, query = {}) => {
  const string = qs.stringify(query, { skipNulls: true })
  const sep = url.includes('?') || url.includes(encodeURIComponent('?')) ? '&' : '?'
  return url + (string ? sep + string : '')
}

export const replaceUrl = (url) => {
  window.location.replace(url)
}

export default (url, query = {}) => {
  window.location.replace(getReturnLink(url, query))
}

export const closeWebView = () =>
  process.env.NODE_ENV === 'development'
    ? console.info('웹뷰 닫기')
    : window.location.replace('chaiinternalweb://close')

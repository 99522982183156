import React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from '../../utils'
import { WithRequest, useNav, NavTypes } from '../../hooks'
import Page from '../../components/Page'
import Details from './Details'
import styles from './Coupon.module.scss'

const Coupon = ({ match }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })

  const render = data => {
    const { title, discount = '' } = data
    const fontSize = discount.includes('%') ? 55 : 46

    const card = (
      <div className={styles.card}>
        <h1>{title}</h1>
        <p style={{ fontSize }}>{format.discount(discount, t)}</p>
      </div>
    )

    return (
      <>
        {card}
        <Details details={data} className={styles.details} />
      </>
    )
  }

  return (
    <Page title={t('쿠폰:쿠폰 상세')}>
      <WithRequest url={`/user/coupon/${match.params.id}`}>
        {data => data && data.merchant && render(data)}
      </WithRequest>
    </Page>
  )
}

export default Coupon

import React from 'react'
import cx from 'classnames'
import styles from './PinInput.module.scss'

const PinInput = ({ length, shake }) => (
  <div className={cx(styles.Input, shake && 'animated faster shake')}>
    {Array.from({ length: 6 }).map((_, index) => (
      <span
        className={cx(
          styles.Disc,
          index % 2 ? styles.odd : styles.even,
          index < length && styles.active
        )}
        key={index}
      />
    ))}
  </div>
)

export default PinInput

import React, { useState, useEffect } from 'react'
import { string, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useApp, useUser, useTimeout } from '../hooks'
import Countdown from '../components/Countdown'
import Progress from '../components/Progress'
import Guide from '../pages/auth/Guide'
import InputGroup from './InputGroup'
import styles from './CodeConfirm.module.scss'

const propTypes = {
  url: string.isRequired, // 검증요청할 API 경로
  requestAgain: func, // 인증코드 재요청
}

const defaultProps = {
  requestAgain: undefined,
}

const MAX_CODE_LENGTH = 6 // 인증코드의 자릿수 최대
const TIME_TO_REQUEST = 10 // 인증코드를 요청할 수 있는 시간 간격(초)
const TIME_TO_EXPIRE = 180 // 인증코드가 만료되기까지의 시간(초)

/* 인증코드 검증: 재요청 및 만료 처리 */
const CodeConfirm = ({ url, requestAgain }) => {
  const { t } = useTranslation()
  const { auth, api, toast, handleError } = useApp()
  const { user } = useUser()

  /* state */
  const [code, setCode] = useState('') // 요청한 코드
  const [isCodeExpired, setIsCodeExpired] = useState(false) // 만료 여부
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [canRequest, setCanRequest] = useState(false) // 요청할 수 있는지 여부
  const [attempt, setAttempt] = useState(0) // 시도 카운터 (key)
  const [showGuide, setShowGuide] = useState(false)

  /* 재요청 활성화 */
  useTimeout(() => setCanRequest(true), TIME_TO_REQUEST * 1000)

  /* 자동 제출 */
  useEffect(() => {
    /* 인증코드 검증 요청 */
    const requestConfirm = async () => {
      setIsSubmitting(true)

      try {
        await api.post(url, { ...user, code })
        setIsSubmitting(false) // 언마운트 이전에 수행해야 한다.

        // 코드 인증을 통과하고도 계정 인증에 실패했다면, 뭔가 잘못된 것이다.
        // 예를들면 맥 사파리에서 크로스 사이트 추적 방지가 켜져있을 때 쿠키를 전달하지 못해서 발생한다.
        // 로그인에 성공하지 못하면 가이드를 보여준다.
        await auth.getAuth({
          onAuth: (loggedIn) => !loggedIn && setShowGuide(true),
        })
      } catch (error) {
        setIsSubmitting(false)
        setCode('')
        setAttempt(attempt + 1)
        handleError(error)
      }
    }

    code.length === MAX_CODE_LENGTH && !isCodeExpired && requestConfirm()
    // eslint-disable-next-line
  }, [code])

  /* 이벤트 핸들러 */
  const handleChange = (e) => {
    setCode(e.target.value)
  }

  const handleCountdownEnd = () => {
    const message = t('인증:입력 시간이 만료됐으니\n재요청을 눌러 다시 인증해주세요')
    setIsCodeExpired(true)
    toast.error(message, { autoClose: false })
  }

  return showGuide ? (
    <Guide />
  ) : (
    <>
      <label>{t('인증:인증번호')}</label>
      <InputGroup>
        <input
          type="tel"
          name="code"
          value={code}
          onChange={handleChange}
          maxLength={MAX_CODE_LENGTH}
          readOnly={isSubmitting}
          placeholder="123456"
          className="input"
          autoComplete="one-time-code"
          autoFocus
          key={attempt}
        />
      </InputGroup>

      <footer className={styles.footer}>
        {requestAgain && (
          <button
            type="button"
            onClick={(e) => {
              toast.dismiss()
              requestAgain(e)
            }}
            disabled={!canRequest || isSubmitting}
            className={styles.button}
          >
            {t('인증:재요청')}
          </button>
        )}

        {!isCodeExpired && (
          <Countdown initial={TIME_TO_EXPIRE} onEnd={handleCountdownEnd}>
            {(time) => <Progress percentage={getPercent(time)}>{formatCountdown(time)}</Progress>}
          </Countdown>
        )}
      </footer>
    </>
  )
}

CodeConfirm.propTypes = propTypes
CodeConfirm.defaultProps = defaultProps

export default CodeConfirm

/* util */
const formatCountdown = (time) => {
  const m = Math.floor(time / 60)
  const s = String(time % 60).padStart(2, 0)
  return time && [m, s].join(':')
}

const getPercent = (time) => 100 - (100 * time) / TIME_TO_EXPIRE

import 'react-app-polyfill/ie9'
import 'core-js'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { isDevelopment } from './env'
import './index.scss'
import ErrorBoundary from './ErrorBoundary'
import ScrollToTop from './ScrollToTop'
import App from './layouts/App'
import Demo from './demo'

import en from './lang/en.json'
import ko from './lang/ko'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { en: { translation: en }, ko: { translation: ko } },
    keySeparator: ':'
  })

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <ScrollToTop>
        <Switch>
          {isDevelopment && <Route path="/demo" component={Demo} />}
          <Route path="/" component={App} />
        </Switch>
      </ScrollToTop>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)

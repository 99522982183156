import React, { createContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { useApp } from '../../../hooks'
import NotFound from '../../NotFound'

import Payments from './Payments'
import Home from './Home'

export const Context = createContext()

const KVoucherRoute = ({ match }) => {
  const { api, handleError } = useApp()
  const value = {
    api,
    handleError,
  }

  return (
    <Context.Provider value={value}>
      <Switch>
        <Route path={match.url + '/payments'} component={Payments} />

        <Route path={match.url + '/'} component={Home} />
        {/* Not found */}
        <Route component={NotFound} />
      </Switch>
    </Context.Provider>
  )
}

export default KVoucherRoute

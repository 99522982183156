/* 결제 결과, 영수증 */
export const Status = {
  waiting: '결제 대기중',
  prepared: '결제 확인 후 대기중',
  approved: '결제 승인 후 대기중',
  canceled: '결제 취소',
  confirmed: '결제 완료',
  timeout: '결제 시간초과',
  failed: '결제 실패',
  user_canceled: '사용자에 의한 결제 취소',
  partial_confirmed: '부분 취소',
}

/* 차이머니 내역 */
export const Types = {
  charge_in: { label: '충전', description: '차이머니 충전', cat: '+' },
  withdrawal_out: { label: '인출', description: '차이머니 인출', cat: '-' },
  payment_out: { label: '결제', cat: '-' },
  revoke_in: { label: '취소', cat: '+' },
  cashback_in: { label: '적립', description: '차이머니 적립', cat: '+' },
  revoke_cashback_out: {
    label: '회수',
    description: '차이머니 회수',
    cat: '-',
  },
}

export const BarcodeOrderType = {
  abc: '가나다순',
  rank: '인기순',
}

export const BarcodeUrl = {
  development: 'https://static.chai.finance/barcode/barcode-dev.json',
  staging: 'https://static.chai.finance/barcode/barcode-staging.json',
  qa: 'https://static.chai.finance/barcode/barcode-staging.json',
  alpha: 'https://static.chai.finance/barcode/barcode.json',
  production: 'https://static.chai.finance/barcode/barcode.json',
}[process.env.REACT_APP_ENV]

export const EventRpsRound2Url = {
  local: 'https://local.chai.finance:3000/event/rps/round2/start',
  development: 'https://payment-dev.chai.finance/event/rps/round2/start',
  staging: 'https://payment-staging.chai.finance/event/rps/round2/start',
  qa: 'https://payment-qa.chai.finance/event/rps/round2/start',
  alpha: 'https://payment.chai.finance/event/rps/round2/start',
  production: 'https://payment.chai.finance/event/rps/round2/start',
}[process.env.REACT_APP_ENV]

export const EventRps2Url = {
  local: 'https://local.chai.finance:3000/event/rps',
  development: 'https://payment-dev.chai.finance/event/rps',
  staging: 'https://payment-staging.chai.finance/event/rps',
  qa: 'https://payment-qa.chai.finance/event/rps',
  alpha: 'https://payment.chai.finance/event/rps',
  production: 'https://payment.chai.finance/event/rps',
}[process.env.REACT_APP_ENV]

export const EventRpsConfigUrl = {
  local: 'https://static.chai.finance/event/rps/config/dev.json',
  development: 'https://static.chai.finance/event/rps/config/dev.json',
  staging: 'https://static.chai.finance/event/rps/config/staging.json',
  qa: 'https://static.chai.finance/event/rps/config/staging.json',
  alpha: 'https://static.chai.finance/event/rps/config/prod.json',
  production: 'https://static.chai.finance/event/rps/config/prod.json',
}[process.env.REACT_APP_ENV]

import { useEffect } from 'react'
import useApp from './useApp'
export { Types } from '../layouts/Nav'

export default (type, config) => {
  const { nav } = useApp()

  /* onMount */
  useEffect(() => {
    const { set, reset } = nav
    set(type, config)
    return reset // 마운트 해제할 때 기본값으로 되돌리기
    // eslint-disable-next-line
  }, [])
}

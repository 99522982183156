import React, { useState, useEffect, useCallback } from 'react'

import cx from 'classnames'
import qs from 'qs'
import { pick } from 'ramda'

import { useTranslation, Trans } from 'react-i18next'
import { ga, getReturnLink, replace } from '../utils'
import { useApp, useNav, NavTypes } from '../hooks'

import Center from '../components/Center'
import styles from './PrepareWithShortenLink.module.scss'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import { getQueryParam } from '../function'

const SCREEN = 'CustomUrl'
const INITIAL = 5000
const INTERVAL = 1000

const PrepareWithShortenLink = () => {
  useNav(NavTypes.CANCEL)

  const { t } = useTranslation()
  const { handleError, query, api, env } = useApp()

  const { isSubscription } = env

  const [isLoading, setIsLoading] = useState(false) // 로딩 인디케이터 잠깐 출력
  const [retryCount, setRetryCount] = useState(0)
  const [timeoutId, setTimeoutId] = useState() // 3초 후 폴링 시작
  const [timestamp, setTimestamp] = useState() // 폴링 시작 시점
  const [pollingId, setPollingId] = useState() // 1초마다 결제상태 가져오기
  const [isFinished, setIsFinished] = useState(false) // 결제 완료 여부
  const [replaceUrl, setReplaceUrl] = useState() // replace url

  const { paymentId, returnUrl, subscriptionId } = query

  /* 함수: 개발용 로그 전송 */
  const sendPayLog = useCallback(
    async (message = '') => {
      if (!isSubscription && message) {
        try {
          await api.post(`/payment/${paymentId}/log`, { message })
        } catch (error) {
          // do nothing
        }
      }
    },
    [api, paymentId, isSubscription]
  )

  /* 함수: 응답의 상태값이 가맹점으로 돌아가야하는지 판별 */
  const isFinishedStatus = useCallback(
    (s) => ['approved', 'user_canceled', 'canceled', 'inactive', 'failed', 'timeout', 'confirmed', 'churn'].includes(s),
    []
  )

  /* customUrl을 보내기전에 결제건 prepare 처리 */
  const prepare = useCallback(
    async (params) => {
      const { paymentId, subscriptionId } = query
      try {
        if (isSubscription) {
          await api.post(`/payment/subscription/${subscriptionId}/prepare`, params)
        } else {
          await api.post(`/payment/${paymentId}/prepare`, params)
        }
        return true
      } catch (error) {
        handleError(error)
      }
      return false
    },
    [api, handleError, isSubscription, query]
  )

  const beforePrepare = useCallback(async () => {
    setIsLoading(true) // 로딩 인디케이터를 출력한다.

    // 5초 후:
    const id = setTimeout(() => {
      setIsLoading(false)
    }, INITIAL)

    setTimeoutId(id)
    // 기존 마운트 시점에 폴링 정책을 버튼 클릭시로 옮겨옴.
    setPollingId(0)
  }, [])

  /**
   * shorten link 생성
   */
  const requestShortenLink = useCallback(async () => {
    ga.button(SCREEN, 'Push')
    const q = qs.stringify(pick(['publicAPIKey', 'paymentId', 'idempotencyKey', 'subscriptionId'], query))
    const queryParam = `${q && `?${q}`}`
    const scheme = 'chaipayment'
    const group = isSubscription ? 'subscription' : 'payment'
    const id = isSubscription ? subscriptionId : paymentId
    try {
      await api.post(`/s/${group}`, { retryCount, scheme, queryParam, id })
      setRetryCount(retryCount + 1)
    } catch (error) {
      handleError(error)
    }
  }, [api, handleError, isSubscription, paymentId, query, retryCount, subscriptionId])

  const requestSendMessage = useCallback(async () => {
    await beforePrepare()

    const result = await prepare()
    if (result) {
      await requestShortenLink()
    }
    setTimestamp(Date.now())
  }, [prepare, requestShortenLink, beforePrepare])

  /**
   * init
   */
  useEffect(() => {
    const init = async () => {
      sendPayLog(`Init Custom payment`)
      ga.screen(SCREEN)
      requestSendMessage()
    }
    init()
    // eslint-disable-next-line
  }, [])

  /* 언마운트할 때: 타임아웃 비우기 */
  useEffect(() => {
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line
  }, [timeoutId])

  const finish = useCallback(
    async ({ status, errorMessage, errorCode }) => {
      const params = getQueryParam({ query, status, errorMessage, errorCode })
      const returnLink = getReturnLink(returnUrl, params)
      sendPayLog(`Return to ${returnLink}`)
      ga.button('결제완료대기', '결제완료')

      setReplaceUrl(returnLink)
    },
    [query, returnUrl, sendPayLog, setReplaceUrl]
  )

  /* api: request */
  const request = useCallback(async () => {
    try {
      const checkUrl = isSubscription ? `/payment/subscription/${subscriptionId}` : `/payment/${paymentId}`
      const { data } = await api.get(checkUrl)
      return data
    } catch (error) {
      if (Date.now() - timestamp < 600 * 1000) {
        // 10분 이내라면 무조건 재시도한다
        return { status: 'waiting' }
      }

      handleError(error)
    }
  }, [api, handleError, isSubscription, paymentId, subscriptionId, timestamp])

  /* 함수: 폴링 */
  const checkPaymentStatus = useCallback(async () => {
    // 기존 polling  제거
    const data = await request()
    if (isFinishedStatus(data.status)) {
      setIsFinished(true)
      finish(data)
    }
  }, [request, isFinishedStatus, finish])

  /**
   * Polling 반복 호출
   */
  useEffect(() => {
    const id = setTimeout(() => {
      checkPaymentStatus()
      setPollingId(pollingId + 1)
    }, INTERVAL)

    return () => clearTimeout(id)
  }, [pollingId, checkPaymentStatus])

  useEffect(() => {
    if (replaceUrl) {
      replace(replaceUrl)
    }
  }, [replaceUrl])

  return (
    <>
      {isLoading && <Loading backdrop />}

      <Center className={styles.content}>
        <p className={styles.brand}>
          {isSubscription ? (
            <Trans i18nKey="브릿지:곧 알림이 도착하니<0/>눌러서 등록해주세요<0/><1>알림이 안온다면 문자나 카카오톡을 확인해보세요</1>">
              <br />
              <span />
            </Trans>
          ) : (
            <Trans i18nKey="브릿지:곧 알림이 도착하니<0/>눌러서 결제해주세요<0/><1>알림이 안온다면 문자나 카카오톡을 확인해보세요</1>">
              <br />
              <span />
            </Trans>
          )}
        </p>
      </Center>
      <Footer className={styles.footer}>
        <p className={styles.hoxy}>
          <Trans i18nKey="브릿지:혹시 아무것도 받지 못하셨나요?"></Trans>
        </p>
        <button
          className={cx('btn btn-block btn-primary', styles.message)}
          disabled={isLoading || isFinished}
          onClick={() => {
            requestSendMessage()
          }}
        >
          <div>{t('브릿지:메세지 다시 받기')}</div>
        </button>
      </Footer>
    </>
  )
}

export default PrepareWithShortenLink

import React, { useState, useCallback, useEffect } from 'react'
import { useApp } from '../../hooks'
// import qs from 'qs'
import { isAndroid } from '../../env'
// import { getErrorMessage } from '../../utils'
import { closeWebView } from '../../utils/replace'
import Bridge from '../../layouts/Bridge'

// 하나 카드신청 완료 페이지
const HanaIssueComplete = ({ history }) => {
  // const { api, handleError } = useApp()
  // const [errorMsg, setErrorMsg] = useState()

  const { handleError } = useApp()
  const [errorMsg] = useState()

  const fetchHanaCard = useCallback(async () => {
    /*
    try {
      const search = qs.parse(history.location.search, { ignoreQueryPrefix: true })
      const { chai_token, hana_token, status } = search
      const { data } = await api.post('/user/plcc/apply/done', { status, chaiToken: chai_token, hanaToken: hana_token })
      if (data?.success) {
        closeBridge()
      } else {
        // 오류 발생
        const message = getErrorMessage(data.response)
        setErrorMsg('e:' + message)
        // closeBridge(message)
      }
    } catch (error) {
      handleError(error)
      const message = getErrorMessage(error.response)
      setErrorMsg('e:' + message)
    }
    */
    closeBridge()
  }, [])

  useEffect(() => {
    fetchHanaCard()
  }, [fetchHanaCard, handleError])

  if (errorMsg) {
    return (
      <Bridge>
        <h1>오류가 발생하였습니다.</h1>
        <h2>{errorMsg}</h2>
      </Bridge>
    )
  }

  return (
    <Bridge>
      <h1>차이에서 카드를 신청합니다.</h1>
    </Bridge>
  )
}

function closeBridge(message) {
  const result = message ? { errorMessage: message } : {}
  if (isAndroid && window.ChaiAndroidHandler) {
    // window.ChaiAndroidHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
    closeWebView()
  } else if (window.ChaiiOSHandler) {
    // window.ChaiiOSHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
    closeWebView()
  } else {
    // 브릿지 처리가 없는 경우 (웹 버전?)
    console.log(result)
  }
}

export default HanaIssueComplete

import React from 'react'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import Article from '../../components/Article'

const Item = ({ match }) => {
  useNav(NavTypes.GO_BACK)
  return (
    <WithRequest url="/faq">
      {(faq) => {
        const { title, content } = faq[Number(match.params.id)]
        return (
          <Article title={title}>
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </Article>
        )
      }}
    </WithRequest>
  )
}

export default Item

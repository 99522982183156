import React from 'react'
import cx from 'classnames'
import { ReactComponent as Check } from '../icons/Check.svg'
import styles from './Options.module.scss'

const Options = ({ options }) => (
  <ul className={styles.list}>
    {options.map(({ text, active, disabled, onClick }, index) => (
      <li key={index}>
        <button
          type="button"
          onClick={onClick}
          disabled={disabled}
          className={cx(
            styles.item,
            active && styles.active,
            disabled && styles.disabled
          )}
        >
          {active && <Check />}
          <span>{text}</span>
        </button>
      </li>
    ))}
  </ul>
)

export default Options

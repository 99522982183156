import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BalloonFace } from '../images/bg_balloon_face.svg'
import { ReactComponent as Chai } from '../images/CHAI.svg'

import styles from './NotFound.module.scss'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.layout}>
        <BalloonFace />
        <p>{t('오류:유효하지 않은 경로로 접근했습니다')}</p>
      </div>
      <div className={styles.logo}>
        <Chai />
      </div>
    </>
  )
}

export default NotFound

import { useEffect, useRef } from 'react'

export default (callback = () => {}, delay = 1000) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()
    const id = setTimeout(tick, delay)
    return () => clearTimeout(id)
  }, [delay])
}

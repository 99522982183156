import React, { useState, useEffect, useRef } from 'react'
import { bool, string, func } from 'prop-types'
import 'animate.css'
import { shuffle, throwError } from '../utils'
import PinInput from '../forms/PinInput'
import { Keypadsuit } from './Keypadsuit'
import './Keypad.scss'
import styles from './Keypad.module.scss'

const propTypes = {
  token: string.isRequired,
  title: string,
  shake: bool,
  disabled: bool,
  onFinish: func
}

const defaultProps = {
  title: '',
  shake: false,
  disabled: false,
  onFinish: () => {}
}

const Keypad = ({ token, title, shake, onFinish, disabled }) => {
  const [length, setLength] = useState(0)
  const inputRef = useRef()

  /* init keypad */
  useEffect(() => {
    !token && throwError('보안키패드 토큰이 없습니다.')
    const k = new Keypadsuit(token)
    k.password_length = 6
    k.shuffle_method = shuffle
    k.nohide = true
    k.onfinish_if_filled = true
    k.onfinishinput = onFinish
    k.on('show', element => (element.style.display = 'flex'))
    k.on('click', key => setLength(k.keydata.length))
    k.attach(inputRef.current)
    k.popup()
    return () => k.detach()
    // eslint-disable-next-line
  }, [token])

  return disabled ? null : (
    <article>
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <PinInput length={length} shake={shake} />
      </header>
      <input type="text" ref={inputRef} className={styles.input} readOnly />
    </article>
  )
}

Keypad.propTypes = propTypes
Keypad.defaultProps = defaultProps

export default Keypad

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import NotFound from '../NotFound'
import Promotion from '../coupons/Promotion'
import Event from '../event/Event'

const PromotionRoutes = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + '/event'} component={Event} />
      <Route path={match.url + '/:id'} component={Promotion} />
      {/* Not found */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default PromotionRoutes

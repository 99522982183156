import React, { useRef, useState, useEffect } from 'react'
import { bool, string, oneOf, shape } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { cond, equals } from 'ramda'
import cx from 'classnames'
import { isDesktop } from '../env'
import { getHeight } from '../utils'
import { closeWebView } from '../utils/replace'
import { useApp, useBodyStyle } from '../hooks'
import { ReactComponent as CHAI } from '../images/CHAI.svg'
import { ReactComponent as Times } from '../icons/TimesLarge.svg'
import { ReactComponent as ChevronLeftLarge } from '../icons/ChevronLeftLarge.svg'
import Cancel from './Cancel'
import styles from './Nav.module.scss'

export const Types = {
  NONE: 'none', // 아무것도 없음 - 초기값
  CANCEL: 'cancel', // 브랜드 로고와 닫기 버튼 (동작: 결제 취소) - 회원가입 및 로그인
  CLOSE: 'close', // 제목과 닫기 버튼 (동작: 웹뷰닫기 || 뒤로가기) - 약관 상세
  GO_BACK: 'goBack', // 뒤로가기 버튼 (동작: 웹뷰닫기 || 뒤로가기) - 현금영수증 설정
}

const propTypes = {
  type: oneOf(Object.values(Types)).isRequired,
  config: shape({ title: string, bg: bool, toApp: bool, transparent: bool }),
}

const defaultProps = {
  title: undefined,
  config: {},
}

/* GNB */
const Nav = ({ type, config }) => {
  const { title, bg, toApp, transparent, to, backLabel } = config
  const { replace, goBack: _goBack, length } = useHistory()
  const goBack = length > 1 ? _goBack : closeWebView

  /* onMount */
  useBodyStyle('paddingTop', () => (isDesktop || transparent ? 0 : getHeight(ref, 32)))

  /* context */
  const ref = useRef()
  const { env } = useApp()
  const { theme } = env

  /* observer */
  const [touched, setTouched] = useState(false)
  useEffect(() => {
    const listener = () => setTouched(window.pageYOffset > 0)
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [])

  /* render */
  const className = cx(
    styles.Nav,
    styles[theme],
    styles[type],
    bg && styles.bg,
    touched && styles.touched,
    transparent && styles.transparent,
    isDesktop && styles.desktop
  )

  const nav = { ref, className }
  const button = {
    type: 'button',
    className: cx(styles.button, type === Types.GO_BACK && styles.buttonBack),
  }

  const render = cond([
    [
      equals(Types.CANCEL),
      () => (
        <nav {...nav}>
          <CHAI className={styles.chai} />
          {(env.isPayment || env.isSubscription) && (
            <Cancel>
              {(onClick, disabled) => (
                <button {...button} onClick={onClick} disabled={disabled}>
                  <Times />
                </button>
              )}
            </Cancel>
          )}
        </nav>
      ),
    ],
    [
      equals(Types.CLOSE),
      () => (
        <nav {...nav}>
          <h1 className={styles.title}>{title}</h1>
          <button {...button} onClick={toApp ? closeWebView : goBack}>
            <Times />
          </button>
        </nav>
      ),
    ],
    [
      equals(Types.GO_BACK),
      () => (
        <nav {...nav}>
          <button {...button} onClick={() => (toApp ? closeWebView() : to ? replace(to) : goBack())}>
            <ChevronLeftLarge />
            {backLabel}
          </button>

          {isDesktop && <CHAI className={styles.chai} />}
        </nav>
      ),
    ],
  ])

  return render(type) || null
}

Nav.propTypes = propTypes
Nav.defaultProps = defaultProps

export default Nav

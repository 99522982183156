import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RequestRoute } from '../../routes'
import Preperation from './Preperation'
import SetCashReceipt from './SetCashReceipt'

const Payment = ({ match }) => {
  const SetCashReceiptRoute = {
    url: '/cashReceiptType',
    path: match.url + '/cash-receipt'
  }

  return (
    <Switch>
      <RequestRoute {...SetCashReceiptRoute} component={SetCashReceipt} />
      <Route path={match.url} component={Preperation} />
    </Switch>
  )
}

export default Payment

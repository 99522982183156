import React, { useState, useEffect, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { isProduction } from './env'
import { useApp, useUser, WithRequest } from './hooks'

import Home from './pages/Home'

/* Auth */
import AppLink from './forms/AppLink'
import Existence from './forms/Existence'
import Registration from './pages/auth/Registration'
import Login from './forms/Login'
import Signout from './pages/auth/Signout'
import Payment from './pages/pay/Payment'
import Approval from './pages/pay/Approval'
import Agree from './pages/agreements/Agree'

/* Menu */
import Money from './pages/money/Money'
import Accounts from './pages/accounts/Accounts'
import Coupon from './pages/coupons/Coupon'
import Agreed from './pages/agreements/Agreed'
import Privacy from './pages/agreements/Privacy'
import TosUpdate from './pages/agreements/TosUpdate'
import Delete from './pages/auth/Delete'

import Receipt from './pages/payments/Receipt'
import Agreement from './pages/agreements/Agreement'
import Notices from './pages/notices/Notices'
import Help from './pages/help/Help'
import CardFaq from './pages/help/CardFaq'
import HelpFAQ from './pages/help/FAQ'
import FAQ from './pages/faq/FAQ'
import TopUp from './pages/TopUp'
import Lottery from './pages/lottery/Lottery'
import Barcode from './pages/barcode/Barcode'
import Event from './pages/event/Event'

import Card from './pages/card/Card'

import ChatUpdate from './pages/chatUpdate/ChatUpdate'

/* Temp */
import OpenApp from './pages/OpenApp'
import Temp from './pages/Temp'

/* Debug */
import Payments from './pages/payments/Payments'
import Coupons from './pages/coupons/Coupons'

/* Not found */
import NotFound from './pages/NotFound'

import Burning from './pages/burning/Burning'
import Shorten from './pages/shorten/Shorten'
import PromotionRoutes from './pages/promotion/PromotionRoutes'
import ReissueNotice from './pages/card/ReissueNotice'
import HanaIssue from './pages/card/HanaIssue'
import HanaIssueComplete from './pages/card/HanaIssueComplete'
import HanaIssueTest from './pages/card/HanaIssueTest'
import BoostUpDetail from './pages/boostUp/BoostUpDetail'

const BoltTest = lazy(() => import('./pages/bolt/BoltTest'))

const AuthRoute = (props) => {
  const { component: Component, withoutAuth, withUser, ...rest } = props
  const { pathname } = props.location

  /* context */
  const { auth } = useApp()
  const { user = {} } = useUser()

  /* 판별 */
  const authRequired = !withoutAuth && !auth.authenticated
  const userRequired = withUser && !(user.phone && user.birth)
  const shouldSignin = authRequired || userRequired
  const shouldRedirect = withoutAuth && auth.authenticated

  /* 로그인으로 리다이렉션 당할 때, 원래 시도하려던 경로를 저장 */
  const [next, setNext] = useState()
  const sholudReturnToNext = next && auth.authenticated

  useEffect(() => {
    shouldSignin && setNext(pathname)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // 목표 경로에 도달하면 다시 비우기
    next === pathname && setNext()
  }, [next, pathname])

  const render = (props) => {
    const { search } = props.location

    return sholudReturnToNext ? (
      <Redirect to={{ pathname: next, search }} />
    ) : shouldRedirect ? (
      // 회원가입 혹은 로그인 절차가 끝났을 때 Home으로 리다이렉트
      <Redirect to={{ pathname: '/', search }} />
    ) : shouldSignin ? (
      // auth 혹은 user 정보가 필요한 경로에 정보 없이 진입하려고 할 때 리다이렉트
      <Redirect to={{ pathname: '/exist', search }} />
    ) : (
      <Component {...props} />
    )
  }

  return <Route {...rest} render={render} />
}

export const RequestRoute = ({ component: Component, url, ...rest }) => {
  const render = (props) => <WithRequest url={url}>{(data) => <Component {...props} data={data} />}</WithRequest>

  return <Route {...rest} render={render} />
}

export default (
  <Switch>
    <Route path="/" exact component={Home} />

    {/* Auth */}
    <Route path="/app" component={AppLink} />

    <AuthRoute withoutAuth path="/exist" component={Existence} />
    <AuthRoute withoutAuth withUser path="/register" component={Registration} />
    <AuthRoute withoutAuth withUser path="/login" component={Login} />
    <AuthRoute path="/signout" component={Signout} />

    {/* Payment */}
    <AuthRoute path="/pay" component={Payment} />
    <AuthRoute path="/approve" component={Approval} />
    <AuthRoute path="/agree" component={Agree} />
    <Route path="/s" component={Shorten} />

    {/* Menu */}
    <AuthRoute path="/money/history" component={Money} />
    <AuthRoute path="/accounts" component={Accounts} />
    <AuthRoute path="/coupon/:id" component={Coupon} />
    <AuthRoute path="/agreed" component={Agreed} />
    <AuthRoute path="/delete" component={Delete} />

    <AuthRoute path="/burning" component={Burning} />

    <Route path="/promotion" component={PromotionRoutes} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/tos/update" component={TosUpdate} />
    <Route path="/receipt/:paymentId" component={Receipt} />
    <Route path="/receipt" component={Receipt} />
    <Route path="/terms/:id" component={Agreement} />
    <Route path="/notices" component={Notices} />

    <Route path="/help/faq/card" component={CardFaq} />
    <Route path="/help/faq" component={HelpFAQ} />
    <Route path="/help" component={Help} />
    <Route path="/faq" component={FAQ} />
    <Route path="/top-up" component={TopUp} />
    <Route path="/barcode" component={Barcode} />
    <Route path="/event" component={Event} />
    <Route path="/card_benefit" component={Card} />
    <Route path="/chat_update" component={ChatUpdate} />

    <Route path="/lottery" component={Lottery} />
    <Route path="/card/reissue/notice" component={ReissueNotice} />
    <Route path="/card/hana/issue/complete" component={HanaIssueComplete} />
    <Route path="/card/hana/issue/latest" component={HanaIssueTest} />
    <Route path="/card/hana/issue" component={HanaIssue} />

    <Route path="/boost_up/:id" component={BoostUpDetail} />

    {/* Temp */}
    <Route path="/benefit" component={OpenApp} />
    <Route path="/p" component={Temp} />

    <Route path="/bolt-test" component={BoltTest} />

    {/* Debug */}
    {!isProduction && <AuthRoute path="/payments" component={Payments} />}
    {!isProduction && <AuthRoute path="/coupons" component={Coupons} />}

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
)

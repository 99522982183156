import React, { createContext, useState } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { path, not } from 'ramda'
import Form from '../../forms/Agreements'
import Agreement from './Agreement'

export const Context = createContext()
const Agreements = ({ match, data }) => {
  /* state */
  // 경로가 바뀌어도 동의 여부를 유지하기 위해 라우팅 밖에서 상태를 관리한다.
  const optional = path(['optional'], data) || []
  const initial = { required: false, optional: optional.map(not) }
  const [agreed, setAgreed] = useState(initial)

  /* context */
  const value = {
    ...data,
    agreed,
    initAgreed: () => setAgreed(initial),
    setAgreed: object => setAgreed({ ...agreed, ...object })
  }

  return (
    <Context.Provider value={value}>
      <Switch>
        <Route path={match.url + '/:id'} component={Agreement} />
        <Route path={match.url} component={Form} />
      </Switch>
    </Context.Provider>
  )
}

export default withRouter(Agreements)

import axios from 'axios'

/* Constants */
const TIMEOUT_DEFAULT = 15000 // 15초
const TIMEOUT_PAYMENT = 8000 // 8초
const LIMIT_PAYMENT = 21000 // 21초

const API_HOST = {
  local: 'https://local.chai.finance:3030',
  development: 'https://api-dev.chai.finance',
  staging: 'https://api-staging.chai.finance',
  qa: 'https://api-qa.chai.finance',
  alpha: 'https://api-alpha.chai.finance',
  production: 'https://api.chai.finance',
  'hana-dev': 'http://210.89.184.41:3030',
}[process.env.REACT_APP_ENV]

const VERSION = 'v1'

/* 인스턴스 생성 */
const instance = axios.create({
  baseURL: `${API_HOST}/${VERSION}`,
  withCredentials: true,
  timeout: TIMEOUT_DEFAULT,
})

/* Payment: 타임아웃을 변경 */
instance.interceptors.request.use((config) => {
  isConfigPayment(config) && config.timeout === TIMEOUT_DEFAULT && (config.timeout = TIMEOUT_PAYMENT)
  return config
})

/* Payment: 타임아웃이 발생하면 여러차례 재시도 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // 408 Request Timeout https://developer.mozilla.org/ko/docs/Web/HTTP/Status/408
    // 504 Gateway Timeout https://developer.mozilla.org/ko/docs/Web/HTTP/Status/504

    const retry = () => {
      config && (config.timeout = getNextTimeout(config.timeout))
      return instance.request(config)
    }

    const reject = () => {
      config && (config.timeout = TIMEOUT_DEFAULT)
      return Promise.reject(error)
    }

    const { config } = error
    const isPayment = isConfigPayment(config)
    const inLimit = config && config.timeout < LIMIT_PAYMENT
    const shouldRetry = isPayment && inLimit && isTimeout(error)
    return shouldRetry ? retry() : reject()
  }
)

export default instance

/* utils */
export const isConfigPayment = ({ url = '' } = {}) => url.includes('/payment') && !url.includes('/cancel')

export const getNextTimeout = (n) => {
  const PHI = (1 + Math.sqrt(5)) / 2 // 피보나치 1.618033...
  return Math.round((n * PHI) / 1000) * 1000
}

export const isTimeout = ({ code, response } = {}) =>
  code === 'ECONNABORTED' || code === 'ECONNREFUSED' || (response && [408, 504].includes(response.status))

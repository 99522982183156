import React from 'react'
import cx from 'classnames'
import { ReactComponent as Icon } from '../icons/Check.svg'
import styles from './Check.module.scss'

const Check = ({ checked, className }) => (
  <div className={cx(styles.input, checked && styles.checked, className)}>
    <Icon />
  </div>
)

export default Check

import styles from './ReissueNotice.module.scss'
import React from 'react'

const ReissueNotice = () => {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <img src="https://static.chai.finance/card/notice/img_card_reissue_notice.png" alt="카드 재발급 공지" />
      </div>
    </div>
  )
}

export default ReissueNotice

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import Page from '../../components/Page'
import styles from './FAQ.module.scss'

const FAQ = ({ match }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true, backLabel: '고객센터' })

  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <WithRequest url="/faq">
      {(faq) => (
        <Page title={t('FAQ:자주 묻는 질문')}>
          <section className={styles.tab}>
            <div className={styles.inner}>
              {faq.map(({ title }, index) => (
                <button
                  className={cx(styles.button, currentIndex === index && styles.active)}
                  onClick={() => setCurrentIndex(index)}
                  key={index}
                >
                  {title}
                </button>
              ))}
            </div>
          </section>

          <section dangerouslySetInnerHTML={{ __html: faq[currentIndex].content }} className={styles.html} />
        </Page>
      )}
    </WithRequest>
  )
}

export default FAQ

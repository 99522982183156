import React, { useState, useEffect } from 'react'
import { useApp } from '../../hooks'
import Page from '../../components/Page'
import Coupon from './CouponItem'

const Coupons = () => {
  /* context */
  const { api, handleError } = useApp()

  /* state */
  const [coupons, setCoupons] = useState([])

  /* onMount */
  useEffect(() => {
    fetchCoupons()
    // eslint-disable-next-line
  }, [])

  /* 쿠폰 가져오기 */
  const fetchCoupons = async () => {
    try {
      const { data } = await api.get('/user/coupon')
      setCoupons(data)
    } catch (error) {
      handleError(error)
    }
  }

  /* 쿠폰 다운로드 */
  const download = async ids => {
    try {
      await api.post('/user/coupon/download', { ids })
      alert('발급했습니다')
      fetchCoupons()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Page title="사용 가능 쿠폰">
      {coupons.length ? (
        <>
          <button
            onClick={() =>
              download(coupons.map(({ promotionId }) => promotionId))
            }
            className="btn btn-block btn-primary"
          >
            전체 다운로드
          </button>
          <ul>
            {coupons.map((coupon, index) => {
              const { couponId } = coupon
              const to = couponId && `/coupon/${coupon.couponId}`
              return (
                <li key={index}>
                  <Coupon coupon={coupon} to={to} onClick={download} />
                </li>
              )
            })}
          </ul>
        </>
      ) : (
        <p>사용 가능한 쿠폰이 없습니다</p>
      )}
    </Page>
  )
}

export default Coupons

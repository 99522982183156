import { isProduction, isStaging, isMobile, isQa } from '../env'

const { ga = () => {} } = window
const shouldSend = isProduction || isStaging || isQa
const suffix = isMobile ? ' (Mobile)' : ' (Desktop)'

export default {
  screen: (screenName) => shouldSend && ga('send', 'screenview', { screenName: screenName + suffix }),
  button: (category, label) => shouldSend && ga('send', 'event', category + suffix, '버튼', label),
}

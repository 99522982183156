import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { bool, func } from 'prop-types'
import { sanitizeNumber } from '../utils'
import { useRequest } from '../hooks'
import Page from '../components/Page'
import Loading from '../components/Loading'
import Actions from '../components/Actions'
import InputGroup from './InputGroup'

const propTypes = { isSubmitting: bool, onSuccess: func }
const defaultProps = { isSubmitting: false, onSuccess: () => {} }

/* 계좌번호와 은행 */
const AccountForm = ({ isSubmitting, onSubmit }) => {
  const { t } = useTranslation()

  /* state */
  const [account, setAccount] = useState({ number: '', bank: '' })
  const [bankName, setBankName] = useState('')
  const { number, bank } = account

  /* 은행 목록 요청 */
  const { data: bankList = [] } = useRequest('/bank')

  /* 이벤트 핸들러 */
  const handleChange = (e) => {
    const { name, value } = e.target
    setAccount({ ...account, [name]: sanitizeNumber(value) })
  }

  const onBankChange = ({ name, code }) => {
    setAccount({ ...account, bank: code })
    setBankName(name)
  }

  /* 제출 */
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ accountNo: number, bankCode: bank })
  }

  /* 검증 */
  const validate = () => Object.values(account).every((v) => !!v)

  /* render */
  const mapOptions = (bank) => ({
    image: bank.icon,
    text: bank.shortName,
    disabled: !!bank.onMaintenance,
    overlay: bank.onMaintenance && t('결제:점검중'),
    onClick: () => !bank.onMaintenance && onBankChange(bank),
  })

  const title = !bank ? (
    <Trans i18nKey="계좌:계좌 등록을 위해<0/><1>은행</1>을 선택해주세요">
      <br />
      <span className="red" />
    </Trans>
  ) : (
    <Trans i18nKey="계좌:<0>{{bankName}} 계좌번호</0>를<1/>입력해주세요" bankName={bankName}>
      <span className="red" />
      <br />
    </Trans>
  )

  // 은행 리스트
  if (!bank) {
    return (
      <Page title={title}>
        {isSubmitting && <Loading />}
        {!!bankList.length && <Actions list={bankList.map(mapOptions)} column={3} />}
      </Page>
    )
  }

  // 추가 브릿지가 필요한 은행의 경우 redirect (KRT ...)
  if (Number.isNaN(Number(bank))) {
    return <Redirect to={{ pathname: `/accounts/add/${bank}` }} />
  }

  return (
    <Page title={title}>
      {isSubmitting && <Loading />}

      <form onSubmit={handleSubmit}>
        <InputGroup>
          {(attrs) => (
            <input
              {...attrs}
              type="tel"
              name="number"
              value={number}
              onChange={handleChange}
              maxLength={16}
              placeholder={t('계좌:숫자만 입력')}
              className="input"
              autoComplete="off"
              autoFocus
            />
          )}
        </InputGroup>

        <button
          type="submit"
          onClick={handleSubmit}
          disabled={!validate() || isSubmitting}
          className="btn btn-block btn-primary btn-submit"
        >
          {t('계좌:ARS 인증전화 요청')}
        </button>
      </form>
    </Page>
  )
}

AccountForm.propTypes = propTypes
AccountForm.defaultProps = defaultProps

export default AccountForm

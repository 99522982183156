import React from 'react'

import styles from './AcpHome.module.scss'

const AcpDetail = () => {
  return (
    <>
      <div className={styles.event}>
        <div className={styles.img_area}>
          <img
            src="https://static.chai.finance/event/auto_charge_plus/img_autoplus.png"
            alt="차이 오토 충전 플러스 이벤트 설명서"
          />
        </div>
        <div className={styles.box}>
          <span>이벤트가 종료되었습니다.</span>
        </div>
      </div>
    </>
  )
}

export default AcpDetail

import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { WithRequest } from '../../hooks'
import { useNav, NavTypes, useBodyClass, useApp } from '../../hooks'
import Page from '../../components/Page'
import Footer from '../../components/Footer'
import Benefit from '../../components/Benefit'
import { getAffix } from './utils'
import Details from './Details'
import styles from './Promotion.module.scss'

const Promotion = ({ match }) => {
  const { t } = useTranslation()
  const { env } = useApp()
  useBodyClass('p-0')
  useNav(NavTypes.GO_BACK, { toApp: true })

  const render = (data) => {
    const { title, promotions = [], landingUrl, merchant = {} } = data
    const url = encodeURIComponent(landingUrl)

    return (
      <>
        <Page
          title={title}
          className={cx(styles.upper, styles.container)}
          headerClassName={styles.header}
          mainClassName={styles.content}
        >
          <Benefit promotions={promotions} />
          <Details details={data} compact />
          {env.isV2 && !!landingUrl && (
            <Footer>
              <a
                href={`chaiexternalweb://webview?url=${url}`}
                className="btn btn-block btn-footer"
              >
                {getAffix(merchant.name)} 이동
              </a>
            </Footer>
          )}
        </Page>
      </>
    )
  }

  return (
    <WithRequest url={`/promotion/group/${match.params.id}`}>
      {(data) =>
        data && data.merchant
          ? render(data)
          : t('프로모션:존재하지 않는 프로모션입니다')
      }
    </WithRequest>
  )
}

export default Promotion

import React from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cond, equals, T } from 'ramda'
import { isDesktop } from '../env'
import { useApp, ThemeTypes, NavTypes } from '../hooks'
import Error from '../components/Error'
import SelectPlatform from '../forms/SelectPlatform'
import PrepareWithShortenLink from '../forms/PrepareWithShortenLink'
import Account from '../forms/Account'
import SetPin from '../forms/SetPin'

/* 분기의 루트 */
const Home = ({ location: { search }, history }) => {
  const { t } = useTranslation()
  const { auth, env } = useApp()

  /* render */
  const account = {
    theme: ThemeTypes.AUTH,
    nav: NavTypes.CANCEL,
    onFinish: auth.getAuth,
  }

  const renderProcedure = cond([
    [equals('set-account'), () => <Account {...account} />],
    [equals('set-pin'), () => <SetPin />],
    [equals('agree-terms'), () => <Redirect to={'/agree' + search} />],
    [T, () => <Error>{t('오류:계정 상태에 문제가 있습니다')}</Error>],
  ])

  const renderError = cond([
    [equals('development'), () => <Redirect to="/demo" />],
    [equals('staging'), () => <Error>{t('오류:결제 파라미터가 없습니다')}</Error>],
    [equals('production'), () => <Error>{t('오류:결제 파라미터가 없습니다')}</Error>],
  ])

  return !env.isPayment && !env.isSubscription ? (
    renderError(process.env.REACT_APP_ENV)
  ) : !env.withWeb && !isDesktop && !env.withShortenLink ? (
    <SelectPlatform onSelectWeb={() => env.setWithWeb(true)} onSelectShortenLink={() => env.setWithShortenLink(true)} />
  ) : auth.status !== 'active' && auth.signoutIfNotActive ? (
    <Redirect to={{ pathname: '/signout', search }} />
  ) : auth.procedure ? (
    renderProcedure(auth.procedure)
  ) : !auth.authenticated ? (
    <Redirect to={{ pathname: '/exist', search }} />
  ) : auth.authenticated && (env.withShortenLink || env.isSubscription) ? (
    <PrepareWithShortenLink />
  ) : (
    <Redirect to={{ pathname: '/pay', search }} />
  )
}

export default Home

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import { ReactComponent as HeadsetMic } from '../../icons/HeadsetMic.svg'
import { ReactComponent as Message } from '../../icons/Message.svg'
import { ReactComponent as Inbox } from '../../icons/Inbox.svg'
import { ReactComponent as ChevronRight16 } from '../../icons/ChevronRight16.svg'
import Page from '../../components/Page'
import styles from './List.module.scss'

const List = ({ match, location: { search } }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })

  return (
    <WithRequest url="/faq">
      {(faq) => (
        <Page title={t('FAQ:자주 묻는 질문')}>
          <ul className={cx(styles.box, styles.list)}>
            {faq.map(({ title }, index) => (
              <li key={index}>
                <Link to={{ pathname: `${match.url}/${index}`, search }} className={styles.link}>
                  {title}
                  <ChevronRight16 />
                </Link>
              </li>
            ))}
          </ul>

          <a href="tel:1544-7839" className={cx(styles.box, styles.contact, styles.link)}>
            <article>
              <h1>{t('FAQ:전화문의')}</h1>
              <p>1544-7839</p>
              <p>
                {t('FAQ:평일')} 09:00 - 18:00 ({t('FAQ:점심')} 13:00 - 14:00)
              </p>
            </article>
            <HeadsetMic />
          </a>

          <Link to={{ pathname: '/chat_update', search }} className={cx(styles.box, styles.contact, styles.link)}>
            <article>
              <h1>1:1 {t('FAQ:채팅 문의')}</h1>
              <p>{t('FAQ:카카오톡으로 문의하실 수 있습니다')}</p>
              <p>
                {t('FAQ:평일')} 09:00 - 18:00 ({t('FAQ:점심')} 13:00 - 14:00)
              </p>
            </article>
            <Message />
          </Link>

          <a href="mailto:help@chai.finance" className={cx(styles.box, styles.contact, styles.link)}>
            <article>
              <h1>{t('FAQ:이메일문의')}</h1>
              <p>help@chai.finance</p>
              <p>{t('FAQ:확인 후 빠른 시일내에 답변드리겠습니다')}</p>
            </article>
            <Inbox />
          </a>
        </Page>
      )}
    </WithRequest>
  )
}

export default List

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import List from './List'
import New from './New'
import Verify from './Verify'
import Complete from './Complete'
import Add from './Add'
import AddComplete from './AddComplete'

const Accounts = ({ match }) => (
  // 아래 Route마다 Nav의 배경색을 바꾸기위해 각각 컴포넌트에서 useNav() hook을 호출해야 한다.
  // 각 컴포넌트가 언마운트될 때 Nav가 초기값으로 리셋되기 때문이다.
  // 그리고 그렇게 하는 편이 더 명시적이다.
  <Switch>
    <Route path={match.url + '/new'} component={New} />
    <Route path={match.url + '/verify/complete'} component={Complete} />
    <Route path={match.url + '/verify'} component={Verify} />
    <Route path={match.url + '/add/:bankCode/complete'} component={AddComplete} />
    <Route path={match.url + '/add/:bankCode'} component={Add} />
    <Route path={match.url} component={List} />
  </Switch>
)

export default Accounts

import numeral from 'numeral'
import { DateTime } from 'luxon'
import sanitize from './sanitizeNumber'

const formatKorean = (number) => {
  const m /* 만 */ = Math.floor(number / 10000)
  const c /* 천 */ = Math.floor((number - m * 10000) / 1000)
  return [m && `${m}만`, c && `${c}천`, number - m * 10000 - c * 1000].filter(Boolean).join('')
}

const formatDollar = (number) => number.toLocaleString('en-US')

const formatPrice = (v, ko) => (v ? (ko ? formatKorean(v) : numeral(v).format()) : '0')

export const getMaxLength = (v = '') => {
  const n = sanitize(v)
  const e = n.slice(0, 3)
  const h = n.slice(3, 5)
  return e === '010' || h === '17' || h[0] === '9' ? 11 : 10
}

const formatter = (v = '', { pattern = [], sep = ' ' } = {}) => {
  const split = (acc = [], sum = 0, index = 0) => {
    const next = sum + pattern[index]
    const array = [...acc, n.slice(sum, next || undefined)]
    return pattern[index] ? split(array, next, index + 1) : array
  }

  const n = sanitize(v)
  return split().filter(Boolean).join(sep)
}

export default {
  price: formatPrice,
  dollar: formatDollar,
  date: (v, t) => (v ? DateTime.fromISO(v).toFormat(t('날짜:yyyy.MM.dd') + ' HH:mm') : ''),
  discount: (v, t) => (!v ? '' : v.includes('%') ? v : t('통화:{{price}}원', { price: formatPrice(v) })),
  /* input */
  phone: (v) => formatter(v, { pattern: [3, getMaxLength(v) - 7, 4] }),
  birth: (v) => formatter(v, { pattern: [2, 2, 4], sep: '.' }),
  business: (v) => formatter(v, { pattern: [3, 2, 5] }),
  cashReceiptCard: (v) => formatter(v, { pattern: [4, 4, 4] }),

  /* string */
  bank: ({ bankName = '', accountNumber = '' } = {}, withoutNumber = false) =>
    [bankName.replace('은행', ''), !withoutNumber && accountNumber].filter(Boolean).join(' '),
}

import { cond, equals, always } from 'ramda'
import { format, is, getMaxLength } from '../../utils'

export const getUtils = ({ type = '', number = '' }) =>
  type
    ? cond([
        [
          equals('consumer_number'),
          always({
            format: format.phone,
            formatted: format.phone(number),
            valid: is.phone(number),
            maxLength: getMaxLength(number) + 2,
            placeholder: '010 1234 5678'
          })
        ],
        [
          equals('business_number'),
          () => ({
            format: format.business,
            formatted: format.business(number),
            valid: number.length === 10 + 2,
            maxLength: 10 + 2,
            placeholder: '000 00 00000'
          })
        ]
      ])(type) || {
        format: format.cashReceiptCard,
        formatted: format.cashReceiptCard(number),
        valid: number.length >= 13 + 2,
        maxLength: 19 + 2,
        placeholder: '0000 0000 0000 0000'
      }
    : {}

import { useState } from 'react'

const portalClassName = 'ChaiModalPortal'
export default ({ onClose = () => {} } = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [config, setConfig] = useState({})

  const appendConfig = (object = {}) => {
    setConfig({ ...config, ...object })
  }

  const open = (content = null, config = {}) => {
    appendConfig(config)
    setContent(content)
    setIsOpen(true)
  }

  const close = () => {
    onClose()
    setConfig({})
    setContent(null)
    setIsOpen(false)
  }

  const actions = { open, close, setContent }
  const defaultConfig = { isOpen, onRequestClose: close, portalClassName }
  return { ...actions, content, config: { ...defaultConfig, ...config } }
}

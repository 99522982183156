import React, { useState, useEffect, useCallback } from 'react'

import { useApp, useNav, NavTypes } from '../../hooks'
import { isKakaoWebView } from '../../env'

const INTERVAL = 500

const OpenAppWithShortenLink = ({ match }) => {
  useNav(NavTypes.CANCEL)

  const { handleError, api } = useApp()
  const [pollingId, setPollingId] = useState() // 1초마다 결제상태 가져오기
  const { group = 'payment', shortenId } = match.params

  const openApp = useCallback((data) => {
    window.open(`${data}`, '_self')
    setPollingId(0)
  }, [])

  const requestPaymentInfo = useCallback(async () => {
    try {
      const { data } = await api.get(`/s/${group}/${shortenId}`)
      const { originData } = data
      openApp(originData)
    } catch (error) {
      handleError(error)
    }
  }, [api, group, shortenId, openApp, handleError])

  useEffect(() => {
    const init = async () => {
      await requestPaymentInfo()
    }
    init()
  }, [requestPaymentInfo])

  /* 언마운트할 때: 폴링 멈추기 */
  useEffect(() => {
    return () => clearTimeout(pollingId)
    // eslint-disable-next-line
  }, [pollingId])

  useEffect(() => {
    const id = setTimeout(() => {
      setPollingId(pollingId + 1)
    }, INTERVAL)

    if (pollingId > 0) {
      closeWindow()
    }

    return () => clearTimeout(id)
  }, [pollingId])

  const closeWindow = async () => {
    isKakaoWebView && window.location.replace('kakaotalk://inappbrowser/close')
    !isKakaoWebView && window.close()
  }

  return <></>
}

export default OpenAppWithShortenLink

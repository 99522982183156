import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Message } from '../images/Message.svg'
import Border from './Border'
import styles from './Benefit.module.scss'

const Benefit = ({ promotions }) => {
  const { t } = useTranslation()

  // 첫 결제를 항상 먼저 출력하기 위해, 프로모션 목록을 둘로 나눈다.
  const contents = [
    promotions.filter((p) => p.isUsableOnFirstPurchase),
    promotions.filter((p) => !p.isUsableOnFirstPurchase),
  ]

  const renderPromotion = (promotion, index) => {
    const { mainText, subText, isUsableOnFirstPurchase: first } = promotion
    return (
      <li className={styles.item} key={index}>
        <header className={styles.header}>
          <section className={styles.bubble}>
            <Message />
            <span>
              {first ? t('혜택:첫결제') : `${t('혜택:혜택')} ${index + 1}`}
            </span>
          </section>
          <h1>{mainText}</h1>
        </header>
        <p className={styles.desc}>{parse.sub(subText)}</p>
      </li>
    )
  }

  return (
    <>
      <Border className={styles.border} />
      <ul className={styles.list}>
        {contents.map((promotions) => promotions.map(renderPromotion))}
      </ul>
    </>
  )
}

export default Benefit

/* parser */
const parse = {
  sub: (string) => string.replace(' / ', '\n'),
}

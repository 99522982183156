import { useEffect } from 'react'
import { useApp } from '../../hooks'

/* 로그아웃 */
const Signout = () => {
  const { auth } = useApp()

  useEffect(() => {
    auth.signout()
    // eslint-disable-next-line
  }, [])

  return null
}

export default Signout

import React from 'react'
import { closeWebView } from '../../utils/replace'
import Page from '../../components/Page'
import styles from './Complete.module.scss'

const Complete = () => {
  return (
    <Page>
      <div className={styles.center}>
        <p className={styles.title}>계좌 인증이 완료됐습니다.</p>
        <p className={styles.content}>
          이제 결제가 가능하니
          <br />
          차이의 혜택을 누려보세요
        </p>
      </div>
      <div className={styles.bottom}>
        <button className="btn btn-primary" onClick={closeWebView}>
          확인
        </button>
      </div>
    </Page>
  )
}

export default Complete

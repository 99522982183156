import React from 'react'
import { Switch, Route } from 'react-router-dom'
import NotFound from '../NotFound'
import Rps from './rps/Rps'
import Acp from './acp/Acp'
import KVoucherRoute from './kvoucher/Route'

const Event = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + '/rps'} component={Rps} />
      <Route path={match.url + '/acp'} component={Acp} />
      <Route path={match.url + '/kvoucher'} component={KVoucherRoute} />

      {/* Not found */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default Event

import React from 'react'
import { DateTime } from 'luxon'
import { format } from '../../../utils'
import styles from './Payments.module.scss'

const PaymentsItem = ({ payment }) => {
  const {
    description,
    createdAt,
    merchant,
    cashbackAmount,
    displayStatus,
    discountAmount,
    canceledDiscountAmount,
  } = payment

  const FMT = 'MM.dd'

  const convertPaymentStatus = (displayStatus) => {
    if (displayStatus === 'confirmed') {
      return '결제완료'
    } else if (displayStatus === 'partial_confirmed') {
      return '부분취소'
    }
    return '결제취소'
  }

  const sliceDescription = (text) => {
    if (text.length > 20) {
      return text.slice(0, 20).concat(' ...')
    }
    return text
  }

  return (
    <>
      <div>
        <div className={styles.payment_1}>
          <span className={styles.payment_day}>{`${DateTime.fromISO(createdAt).toFormat(FMT)}`}</span>
          <div className={styles.rectangle} />
          <span className={styles.payment_status}>{convertPaymentStatus(displayStatus)}</span>
        </div>
        <div className={styles.payment_2}>
          <div className={styles.payment_2_1}>
            <span>{sliceDescription(description)}</span>
          </div>
          <div className={styles.payment_2_2}>
            {displayStatus === 'canceled' ? (
              <div className={styles.payment_amount_cancel_p}>
                <div className={styles.payment_amount_cancel_line}></div>
                <span className={styles.payment_amount_cancel}>{`-${format.price(canceledDiscountAmount)}원`}</span>
              </div>
            ) : (
              <span className={styles.payment_amount}>{`-${format.price(discountAmount)}원`}</span>
            )}
          </div>
        </div>
        <div className={styles.payment_3}>
          <span className={styles.payment_method}>{merchant.name}</span>
          {cashbackAmount > 0 && (
            <span className={styles.payment_cashback}>{format.price(cashbackAmount)}원 캐시백</span>
          )}
        </div>
        <div className={styles.payment_line} />
      </div>
    </>
  )
}

export default PaymentsItem

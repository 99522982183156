import { useEffect } from 'react'

export default className => {
  // 마운트하면 body 클래스를 변경하고,
  // 마운트 해제할 때 원래 클래스로 되돌려 놓는 hook
  useEffect(() => {
    const getClassName = () => document.body.className
    const setClassName = className => (document.body.className = className)
    const initial = getClassName()
    className && setClassName(className)
    return () => setClassName(initial)
    // eslint-disable-next-line
  }, [])
}

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNav, NavTypes, WithRequest } from '../../hooks'
import Page from '../../components/Page'
import styles from './List.module.scss'

const List = ({ match, location: { search } }) => {
  const { t } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })

  const renderItem = ({ id, title, hasDescription }, index) => (
    <li className={styles.item} key={index}>
      {hasDescription ? (
        <Link to={{ pathname: `${match.url}/${id}`, search }} className={styles.link}>
          {title}
        </Link>
      ) : (
        title
      )}
    </li>
  )

  return (
    <Page title={t('약관:약관 및 개인정보 활용')}>
      <WithRequest url="/agreement">
        {({ required, optional }) => {
          return (
            <>
              <section className={styles.group}>
                <h1>필수 항목</h1>
                <ul>{required.map(renderItem)}</ul>
              </section>

              <section className={styles.group}>
                <h1>선택 항목</h1>
                <ul>{optional.map(renderItem)}</ul>
              </section>

              <section className={styles.group}>
                <h1>개인정보 활용</h1>
                <ul>
                  <li className={styles.item}>
                    <a href="https://chai.finance/privacypolicy.html" className={styles.link}>
                      개인정보처리방침
                    </a>
                  </li>
                </ul>
              </section>
            </>
          )
        }}
      </WithRequest>
      <WithRequest url="/cardAgreement">
        {({ required, optional }) => {
          return (
            <section className={styles.group}>
              <h1>차이카드 이용약관</h1>
              <ul>{required.map(renderItem)}</ul>
              <ul>{optional.map(renderItem)}</ul>
            </section>
          )
        }}
      </WithRequest>
    </Page>
  )
}

export default List

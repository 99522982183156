import React from 'react'
import { useTranslation } from 'react-i18next'
import Article from '../components/Article'
import { useNav, NavTypes } from '../hooks'

const getMessages = (en) =>
  !en
    ? [
        '차이앱 최신 버전에서 사용 가능합니다.',
        '자동 충전이란 결제시 차이머니 잔액이 기준 잔액보다 낮아지면 미리 설정한 금액만큼 자동으로 충전되는 기능을 의미합니다.',
        '차이머니는 최대 200만원까지 보유 가능합니다.',
        '월 최대 10만원까지 적립 가능하며 최대 한도는 변경될 수 있습니다.',
        '상시 차이머니 적립률은 상이할 수 있습니다.',
        '자동충전 ON 상태에서 차이머니로 결제 시 1% 차이머니가 추가 적립됩니다.' +
          '\n단, 번개장터는 미적립, 가맹점별 적립금 한도가 있을 수 있으니 차이 혜택 페이지를 참고해 주세요.',
        '할인 쿠폰 및 특별 프로모션과 중복 적용 가능합니다.',
        '충전된 차이머니는 언제든지 인출 가능하며 인출수수료는 무료입니다.',
        '결제취소 시 적립된 차이머니는 회수됩니다. (부분취소 시, 부분취소 금액에 대해 적립된 차이머니는 정률로 회수. 원단위 아래 절사)' +
          '\n예시: 10만원 결제 시 차이머니 3,500원 적립(결제금액의 3.5%). 이후 5만원 부분취소 시, 차이머니 1,750원 회수(부분취소 금액의 3.5%)',
        '상품권 등 환금성 상품 구매 시 차이머니는 적립되지 않습니다. (일부 가맹점 제외)',
        '결제 취소 시 차이머니로 환불됩니다',
        '본 정책은 사전 고지 없이 변경/종료될 수 있습니다.',
      ]
    : [
        'Available in the latest version of the Chai App',
        'Auto-charge means that if the balance of Chai money is lower than the criteria balance on payment, it is automatically charged by the preset amount.',
        'You can hold up to 2 million won.',
        'You can save up to 100,000 won per month and the maximum limit is subject to change.',
        'The usual cashback rate may vary.',
        'An additional 2% cashback will be accumulated when paying by Chai Money with auto-charge on.',
        'Duplicate discount coupons and special promotions, but the cashback received on automatic charging may be limited to 2%' +
          '\nExample: 2% more automatic charging cashback when using Yanolja or Daily Hotel 10% off coupons' +
          '\nExample: Auto-charge cacheback plus 2% when receiving 10% cacheback from CU convenience store',
        'Chai Money charged or deposited can be withdrawn at any time, and the withdrawal fee is free. (However, withdrawals can be made after the inspection time is over during the regular inspection time of the bank.)',
        'In case of cancellation of payment, the accumulated difference money will be recovered. (In case of partial cancellation, the amount of debit money recovered is 5% of the amount of partial cancellation, cut below the original)' +
          '\nExample: 1,000 won for 20,000 won (five percent of the payment amount). Subsequently, upon partial cancellation of 5,000 won, recover Chai Money of 250 won (five percent of partial cancellation amount)',
        'The money will expire when unused within five years of the date of charge or the date of accrual.',
        'If improper use is detected, there may be cancellation of the reserves and legal sanctions.',
        'Cashback is not available for purchase of gift certificates and other redeemable goods.',
        'If you have received a cashback or have used an automatic charge once, you will be refunded to Chai Money upon cancellation of payment.',
        'This policy may be changed/ terminated without prior notice.',
      ]

const TopUp = () => {
  const { t, i18n } = useTranslation()
  useNav(NavTypes.GO_BACK, { toApp: true })

  return (
    <Article title={t('유의사항:유의사항')}>
      {getMessages(i18n.language === 'en').map((l) => (
        <li key={l}>* {l}</li>
      ))}
    </Article>
  )
}

export default TopUp

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { closeWebView } from '../../utils/replace'
import { useApp, useNav, NavTypes, useModal } from '../../hooks'
import { ReactComponent as Plus } from '../../icons/Plus.svg'
import Page from '../../components/Page'
import Drawer from '../../components/Drawer'
import Actions from '../../components/Actions'
import Item from './Item'
import styles from './List.module.scss'

const List = ({ match, location: { search } }) => {
  useNav(NavTypes.GO_BACK, { toApp: true })

  const { t } = useTranslation()
  const { api, handleError } = useApp()
  const modal = useModal()

  /* state */
  const [accounts, setAccounts] = useState({})
  const entries = Object.entries(accounts)
  const isLast = entries.length === 1

  useEffect(() => {
    fetchAccounts()
    // eslint-disable-next-line
  }, [])

  /* 계좌 목록 가져오기 */
  const fetchAccounts = async () => {
    try {
      const { data } = await api.get('/user/account')
      setAccounts(data)
    } catch (error) {
      handleError(error)
    }
  }

  /* 사용자 컨펌, API 요청, 새로고침, 모달 닫기 */
  const requestOnConfirm = ({ message, callback, isLastDeleted }) => {
    const request = async () => {
      try {
        await callback(api)
        isLastDeleted ? onLastDeleted() : fetchAccounts()
      } catch (error) {
        handleError(error)
      }
      modal.close()
    }

    const list = [
      { text: t('일반:예'), onClick: request },
      { text: t('일반:아니오'), onClick: modal.close },
    ]

    modal.open(<Actions list={list} />, { title: message })
  }

  /* 마지막 계좌를 지운 후, 계좌 등록 페이지로 이동한다. */
  const onLastDeleted = () => {
    setAccounts({})
    closeWebView()
  }

  /* render */
  const renderItem = ([accountKey, account], index) => {
    const item = {
      account,
      accountKey,
      requestOnConfirm,
      isLast,
      onClick: (title, list) => modal.open(<Actions list={list} />, { title }),
    }

    return (
      <li key={index}>
        <Item {...item} />
      </li>
    )
  }

  const add = (
    <Link
      replace
      to={{ pathname: match.url + '/new', search }}
      className={styles.add}
    >
      <Plus />
      {t('계좌:계좌 추가하기')}
    </Link>
  )

  return !!entries.length ? (
    <Page title={t('계좌:연결계좌')}>
      <ul>
        {entries.map(renderItem)}
        {entries.length < 4 && <li>{add}</li>}
      </ul>
      <Drawer config={modal.config}>{modal.content}</Drawer>
    </Page>
  ) : null
}

export default List

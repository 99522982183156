import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Item.module.scss'
import { format } from '../../utils'
import { Types } from '../../Constants'

const Item = ({ type, currency, amount, balance, payment, merchant }) => {
  const { status, description: paymentDescription } = payment || {}
  const { label, description, cat } = Types[type]
  const { t } = useTranslation()

  const title = t(
    type === 'charge_in' && !payment
      ? '차이머니:자동충전'
      : type === 'revoke_in' && status === 'confirmed'
      ? '차이머니:부분취소'
      : `차이머니:${label}`
  )

  const desc = t(description ? '차이머니:' + description : paymentDescription)

  return (
    <article className={styles.component}>
      <section className={styles.merchant}>
        <img src={merchant.logoUrl} alt="" width={40} height={40} />
      </section>

      <header className={styles.header}>
        <h1 className={styles.upper}>{title}</h1>
        <p className={styles.lower}>{desc}</p>
      </header>

      <footer className={styles.footer}>
        <p className={styles.upper}>
          {cat}
          {t('통화:{{price}}원', { price: format.price(amount) })}
        </p>
        <p className={styles.lower}>
          {t('차이머니:잔액')}{' '}
          {t('통화:{{price}}원', { price: format.price(balance) })}
        </p>
      </footer>
    </article>
  )
}

export default Item

import React, { useState, useEffect } from 'react'
import styles from './Disconnected.module.scss'

const Disconnected = () => {
  const [connected, setConnected] = useState(true)
  const onOffline = () => setConnected(false)
  const onOnline = () => setConnected(true)

  useEffect(() => {
    window.addEventListener('offline', onOffline)
    window.addEventListener('online', onOnline)

    return () => {
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [])

  return connected ? null : (
    <div className={styles.Disconnected}>Disconnected</div>
  )
}

export default Disconnected

import React, { useState } from 'react'
import { useApp, WithRequest } from '../hooks'
import { useTheme, ThemeTypes, useNav, NavTypes } from '../hooks'
import Keypad from '../assets/Keypad'

/* 핀 설정: 입력 후 검증 */
const SetPin = () => {
  useTheme(ThemeTypes.AUTH)
  useNav(NavTypes.CANCEL)
  const { auth, api, handleError } = useApp()

  /* state */
  const [pin, setPin] = useState()
  const [attempt, setAttempt] = useState(0 /* 반드시 숫자 */)
  const [isSubmitting, setIsSubmitting] = useState(false)

  /* 검증 */
  const validate = async pin => {
    setIsSubmitting(true)
    try {
      await api.post('/auth/pin/validation', { pin })
      setIsSubmitting(false)
      setAttempt(0)
      setPin(pin)
    } catch (error) {
      setIsSubmitting(false)
      setAttempt(attempt + 1)
      handleError(error)
    }
  }

  /* 제출 */
  const submit = async confirm => {
    setIsSubmitting(true)
    try {
      await api.post('/auth/pin', { pin1: pin, pin2: confirm })
      setIsSubmitting(false)
      await auth.getAuth()
    } catch (error) {
      setIsSubmitting(false)
      setAttempt(attempt + 1)
      handleError(error)
    }
  }

  return (
    <WithRequest url="/auth/pin/token">
      {token =>
        !pin ? (
          <Keypad
            token={token}
            title="결제 비밀번호를 등록해주세요"
            onFinish={validate}
            disabled={isSubmitting}
            shake={!!attempt}
            key={'pin' + attempt}
          />
        ) : (
          <Keypad
            token={token}
            title="결제 비밀번호를 확인해주세요"
            onFinish={submit}
            disabled={isSubmitting}
            shake={!!attempt}
            key={'confirm' + attempt}
          />
        )
      }
    </WithRequest>
  )
}

export default SetPin

import { useEffect } from 'react'
import useApp from './useApp'

export const Types = { AUTH: 'auth' }
export default type => {
  const { env } = useApp()

  /* onMount */
  useEffect(() => {
    const { setTheme } = env
    setTheme(type)
    return () => setTheme() // 마운트 해제할 때 기본값으로 되돌리기
    // eslint-disable-next-line
  }, [])
}

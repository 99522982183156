import React from 'react'
import { useNav, NavTypes } from '../../hooks'

import styles from './Benefit.module.scss'

const Benefit = ({ match }) => {
  useNav(NavTypes.GO_BACK)
  return (
    <>
      <div className={styles.benefit}>
        <div className={styles.img_area}>
          <img src="https://static.chai.finance/card/benefit/img_card_benefit_guide.png" alt="차이카드 혜택" />
        </div>
      </div>
    </>
  )
}

export default Benefit

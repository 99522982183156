import React from 'react'
import styles from './Error.module.scss'

const Error = ({ children }) => (
  <article className="center">
    <p className={styles.content}>{children}</p>
  </article>
)

export default Error

import React from 'react'
import { bool, number, string, arrayOf, shape, func, node } from 'prop-types'
import cx from 'classnames'
import { ReactComponent as Check } from '../icons/Check.svg'
import { ReactComponent as Check2 } from '../icons/Check2.svg'
import styles from './Actions.module.scss'

const itemTypes = {
  image: string,
  text: node,
  active: bool,
  disabled: bool,
  onClick: func,
}

const propTypes = {
  v2: bool,
  column: number,
  list: arrayOf(shape(itemTypes)),
  className: string,
  itemClassName: string,
}

const defaultProps = {
  v2: false,
  column: 1,
  list: undefined,
  className: '',
  itemClassName: '',
}

/* 하단에 드로어 형태로 띄우는 모달 - 내부 */
const Actions = ({ v2, column, list, emptyContent, className, itemClassName }) =>
  !Array.isArray(list) ? null : !list.length ? (
    <div className={className}>{emptyContent}</div>
  ) : (
    <ul className={cx(styles.list, column > 1 && styles.multiple, className)}>
      {list.map((item, index) => {
        const { image, text, overlay, active, disabled, onClick } = item
        const { additionalText } = item
        const check = v2 ? <Check2 /> : <Check />
        const attrs = {
          onClick,
          disabled,
          className: cx(
            styles.item,
            active ? (v2 ? styles.active2 : styles.active) : false,
            disabled && styles.disabled,
            itemClassName
          ),
        }

        return (
          <li style={{ width: `${100 / column}%` }} key={index}>
            <button type="button" {...attrs}>
              {image && <img src={image} alt="" />}
              {overlay && <span className={styles.overlay}>{overlay}</span>}
              {text}
              <small>{additionalText || (active && !(column > 1) && check)}</small>
            </button>
          </li>
        )
      })}
    </ul>
  )

Actions.propTypes = propTypes
Actions.defaultProps = defaultProps

export default Actions

import React, { useState, useEffect } from 'react'
import { bool, string, func } from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { cond, equals } from 'ramda'
import { DateTime } from 'luxon'
import cx from 'classnames'
import { isTimeout } from '../api'
import { useApp } from '../hooks'
import { ReactComponent as ChevronRight14 } from '../icons/ChevronRight14.svg'
import Page from '../components/Page'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import Debug from '../components/Debug'
import styles from './AccountARS.module.scss'

const INITIAL = 3000
const INTERVAL = 1000

const propTypes = {
  birth: string,
  isRequested: bool,
  canRequest: bool,
  setCanRequest: func,
  request: func,
  onFinish: func
}

const defaultProps = {
  birth: '',
  isRequested: false,
  canRequest: false,
  setCanRequest: () => {},
  request: () => {},
  onFinish: () => {}
}

/* ARS */
const AccountARS = ({ birth, ...props }) => {
  const { t } = useTranslation()

  // 재요청 버튼 클릭하면 isRequested: true 이후 polling을 시작해야만 한다.
  const { isRequested, canRequest, setCanRequest, request, onFinish } = props
  const { api, toast, handleError } = useApp()

  /* state */
  const [timeoutId, setTimeoutId] = useState()
  const [timestamp, setTimestamp] = useState(Date.now())
  const [isPolling, setIsPolling] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [response, setResponse] = useState([]) // Debug
  const appendResponse = message => {
    const now = DateTime.local().toFormat('HH:mm:ss')
    setResponse(r => [`[${now}] ${message}`, ...r])
  }

  const handleClickRequest = () => {
    toast.dismiss()
    setTimestamp(Date.now())
    request()
  }

  /* onMount */
  useEffect(() => {
    isRequested && poll(INITIAL)
    // eslint-disable-next-line
  }, [isRequested]) // 컨펌 요청 시작

  /* onUnmount: timeout */
  useEffect(() => {
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line
  }, [timeoutId]) // 새 timeout마다 새 clearTimeout 필요

  /* effect */
  useEffect(() => {
    isLoading &&
      isWaiting &&
      toast.error(t('계좌:ARS 인증이 완료되지 않았습니다'))
    // eslint-disable-next-line
  }, [isLoading, isWaiting])

  /* 폴링 */
  const poll = (i = INTERVAL) => {
    setIsWaiting(false)
    const id = setTimeout(async () => {
      const status = await requestAPI()
      const isWaiting = ['waiting'].includes(status)
      setIsPolling(isWaiting)
      setIsWaiting(isWaiting)
      setIsLoading(false)
      cond([
        [equals('ok'), async () => await onFinish()],
        [equals('waiting'), () => poll()],
        [equals('canceled'), () => onCanceled()]
      ])(status)
    }, i)

    setTimeoutId(id)
  }

  const onCanceled = () => {
    toast.error(t('계좌:인증이 취소되었습니다. 재시도 해주세요'), {
      autoClose: false
    })
    setCanRequest(true)
  }

  /* api: request */
  const requestAPI = async () => {
    setIsPolling(true)

    try {
      appendResponse(`Request`)
      const { data } = await api.post('/user/account/confirm')
      appendResponse(data)
      return data
    } catch (error) {
      const onError = () => {
        setCanRequest(true)
        handleError(error)
      }

      const isEarly = Date.now() - timestamp < 180 * 1000 // 3분 이내
      const shouldRetry = isEarly && isTimeout(error)
      return shouldRetry ? 'waiting' : onError()
    }
  }

  const title = (
    <Trans i18nKey="계좌:ARS인증 전화가 오면<0/><1>생년월일 6자리</1>를 입력해주세요">
      <br />
      <span className="red" />
    </Trans>
  )

  return (
    <Page title={title}>
      {isLoading && <Loading backdrop />}

      <section className={styles.birth}>
        {[...birth].map((n, index) => (
          <span className={styles.number} key={index}>
            {n}
          </span>
        ))}
      </section>

      <button
        type="button"
        onClick={handleClickRequest}
        disabled={!canRequest}
        className={cx('btn btn-block btn-text', styles.request)}
      >
        {t('계좌:ARS 인증전화 재요청')}
        <ChevronRight14 />
      </button>

      <Debug log={response} />

      <Footer>
        <button
          type="button"
          onClick={() => setIsLoading(true)}
          disabled={!isPolling}
          className="btn btn-block btn-primary btn-submit"
        >
          {t('계좌:인증완료')}
        </button>
      </Footer>
    </Page>
  )
}

AccountARS.propTypes = propTypes
AccountARS.defaultProps = defaultProps

export default AccountARS

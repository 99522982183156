import React, { useState, useEffect, useRef } from 'react'

import { func } from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import cx from 'classnames'

import { isAndroid } from '../env'
import { isKeyMerchant } from '../merchant'
import { ga, getReturnLink, replaceUrl } from '../utils'
import { useApp, useNav, NavTypes } from '../hooks'
import { ReactComponent as ArrowRight } from '../icons/ArrowRight.svg'
import WithOpenApp from './WithOpenApp'
import Center from '../components/Center'
import Footer from '../components/Footer'
import Loading from '../components/Loading'
import Debug from '../components/Debug'
import styles from './SelectPlatform.module.scss'
import { getQueryParam, isWebAvailable } from '../function'

const INITIAL = 3000
const INTERVAL = 1000
const SCREEN = 'Bridge'

const propTypes = { onSelectWeb: func, onSelectShortenLink: func }
const defaultProps = { onSelectWeb: () => {}, onSelectShortenLink: () => {} }

/* 플랫폼 선택 */
const SelectPlatform = ({ onSelectWeb, onSelectShortenLink, openApp, openStore, isApp, url }) => {
  useNav(NavTypes.CANCEL)

  const { t } = useTranslation()
  const { api, query, handleError, env } = useApp()
  const { publicAPIKey, paymentId, subscriptionId, returnUrl } = query

  const { isSubscription } = env

  /* state */
  const [timeoutId, setTimeoutId] = useState() // 3초 후 폴링 시작
  const [pollingId, setPollingId] = useState() // 1초마다 결제상태 가져오기
  const [timestamp, setTimestamp] = useState(Date.now()) // 폴링 시작 시점
  const [isLoading, setIsLoading] = useState(false) // 로딩 인디케이터 잠깐 출력
  const [isFinished, setIsFinished] = useState(false) // 결제 완료 여부
  const [shouldShake, setShouldShake] = useState(false)
  const [returnLink, setReturnLink] = useState(window.location.href)

  const pageTerminated = useRef()

  /* state: DEBUG */
  const [res, setRes] = useState() // 응답
  const [hasReq, setHasReq] = useState(false) // 요청
  const [hasRes, setHasRes] = useState(false) // 응답: 여부
  const [status, setStatus] = useState() // 응답: 상태

  /* 함수: 개발용 로그 전송 */
  const sendPayLog = async (message = '') => {
    if (!isSubscription) {
      try {
        await api.post(`/payment/${paymentId}/log`, { message })
      } catch (error) {
        // do nothing
      }
    }
  }

  /* 마운트할 때 */
  useEffect(() => {
    const init = async () => {
      await sendPayLog('Init payment')
      ga.screen(SCREEN)
    }

    init()
    // eslint-disable-next-line
  }, [])

  /* 언마운트할 때: 타임아웃 비우기 */
  useEffect(() => {
    return () => clearTimeout(timeoutId)
  }, [timeoutId])

  /* 언마운트할 때: 폴링 멈추기 */
  useEffect(() => {
    return () => clearTimeout(pollingId)
  }, [pollingId])

  useEffect(() => {
    if (window.location.href !== returnLink) {
      replaceUrl(returnLink)
    }
  }, [returnLink])

  /* 함수: 앱 열기 버튼 클릭 */
  const beforeClick = async () => {
    await sendPayLog('Click button: Open app')
    ga.button(SCREEN, 'App')

    setIsLoading(true) // 로딩 인디케이터를 출력한다.

    // 3초 후:
    const id = setTimeout(() => {
      setShouldShake(true) // 버튼을 흔든다. (앱으로 넘어가지 않으면 보게 된다.)
      setIsLoading(false) // 로딩 인디케이터를 숨긴다.
    }, INITIAL)

    setTimeoutId(id)

    // 기존 마운트 시점에 폴링 정책을 버튼 클릭시로 옮겨옴.
    startPoll() // 폴링을 시작한다.
  }

  /* 함수: 응답의 상태값이 가맹점으로 돌아가야하는지 판별 */
  const isFinishedStatus = (s) =>
    ['approved', 'user_canceled', 'canceled', 'inactive', 'failed', 'timeout', 'confirmed', 'churn'].includes(s)

  /* 함수: 폴링 */
  const poll = async () => {
    // 기존 polling  제거
    const data = await request()
    if (isFinishedStatus(data.status)) {
      if (pageTerminated.current) {
        return
      }
      pageTerminated.current = true
      setIsFinished(true)
      setStatus(data.status)
      try {
        await finish(data)
      } catch (e) {
        pageTerminated.current = false
      }
    } else {
      startPoll()
    }
  }

  const finish = async ({ status, errorMessage, errorCode }) => {
    const params = getQueryParam({ query, status, errorMessage, errorCode })
    const returnLink = getReturnLink(returnUrl, params)
    await sendPayLog(`Return to ${returnLink}`)
    ga.button('결제완료대기', '결제완료')
    setReturnLink(returnLink)
  }

  const startPoll = async () => {
    // 가맹점으로 돌아갈 조건이 아니면 계속 폴링한다.
    // 완료된 상태값이면 파라미터로 전달하여 불필요한 요청을 방지한다.
    const id = setTimeout(() => {
      poll()
    }, INTERVAL)

    setPollingId(id)
  }

  /**
   * userClick이 노출되는 시점은 이미 결제에 대한 결과 값이 나온 시점이므로 기존에 폴링을 제거한다.
   */
  const finishByUser = async () => {
    startPoll()
  }

  /* api: request */
  const request = async () => {
    try {
      const checkUrl = isSubscription ? `/payment/subscription/${subscriptionId}` : `/payment/${paymentId}`
      const { data } = await api.get(checkUrl)
      setHasReq(true)
      setHasRes(true)
      setRes(data)
      return data
    } catch (error) {
      setRes(error.response)

      if (Date.now() - timestamp < 600 * 1000) {
        // 10분 이내라면 무조건 재시도한다
        return { status: 'waiting' }
      }

      handleError(error)
    }
  }

  // 웹결제가 모두 공개될 때 사라질 임시 코드입니다.
  const webAvailable = isWebAvailable({ isSubscription, publicAPIKey })
  const buttonAttrs = { className: cx('btn btn-block btn-primary', styles.app) }
  const buttonLabel = (
    <>
      {isSubscription ? t('브릿지:앱으로 등록하기') : t('브릿지:앱으로 결제')}
      <ArrowRight />
    </>
  )

  return (
    <>
      {isLoading && <Loading backdrop />}
      <Center className={styles.content}>
        {isSubscription ? (
          <p className={styles.brand}>
            <Trans i18nKey="브릿지:차이로 등록하면<0/>언제나 <1>즉시 캐시백!</1>">
              <br />
              <span />
            </Trans>
          </p>
        ) : (
          <p className={styles.brand}>
            <Trans i18nKey="브릿지:차이로<1/>안전하게🔒<1/>결제합니다">
              <span />
              <br />
            </Trans>
          </p>
        )}
        {!isFinished ? (
          url && !isKeyMerchant(publicAPIKey, '에이블씨엔씨') ? (
            <a {...buttonAttrs} href={url} onClick={beforeClick}>
              {buttonLabel}
            </a>
          ) : (
            <button
              {...buttonAttrs}
              onClick={() => {
                beforeClick()
                openApp()
              }}
            >
              {buttonLabel}
            </button>
          )
        ) : (
          <button
            {...buttonAttrs}
            onClick={() => {
              finishByUser()
            }}
          >
            {isSubscription ? t('브릿지:등록 처리중') : t('브릿지:결제 처리중')}
            <ArrowRight />
          </button>
        )}

        {shouldShake && !isFinished && !isAndroid && !webAvailable && (
          <button
            onClick={async () => {
              ga.button(SCREEN, 'CustomUrl')
              await sendPayLog('Select CustomUrl')
              await onSelectShortenLink()
            }}
            className="btn btn-block btn-link btn-submit"
          >
            {isSubscription ? t('브릿지:등록에 문제가 있으신가요?') : t('브릿지:결제에 문제가 있으신가요?')}
          </button>
        )}

        {webAvailable && (
          <button
            onClick={() => {
              ga.button(SCREEN, 'Web')
              onSelectWeb()
            }}
            className="btn btn-block btn-text btn-submit"
          >
            {t('브릿지:웹으로 결제')}
          </button>
        )}

        <Debug log={res}>
          <button
            className="btn-submit"
            onClick={() => {
              startPoll()
              setTimestamp(Date.now())
              setIsLoading(true)
            }}
          >
            폴링 강제 시작
          </button>

          <ul>
            <li>timestamp: {timestamp}</li>
            <li>pollingId: {pollingId}</li>
            <li>request: {hasReq && '✔︎'}</li>
            <li>response: {hasRes && '✔︎'}</li>
            <li>status: {status}</li>
          </ul>
        </Debug>
      </Center>

      <Footer className={styles.content}>
        <button
          onClick={() => {
            ga.button(SCREEN, 'Download')
            openStore()
          }}
          className={cx('btn btn-block btn-text', styles.store)}
        >
          {t('브릿지:차이앱 다운로드')}
        </button>

        {shouldShake && (
          <p className={cx(styles.bubble, 'animated faster', styles.fadeUp)}>
            {t('브릿지:차이앱이 없으신가요?')}
            <br />
            {t('브릿지:다운받아주세요')}
          </p>
        )}
      </Footer>
    </>
  )
}

SelectPlatform.propTypes = propTypes
SelectPlatform.defaultProps = defaultProps

const SelectPlatformContainer = (props) => (
  <WithOpenApp>{(params) => <SelectPlatform {...props} {...params} />}</WithOpenApp>
)

export default SelectPlatformContainer

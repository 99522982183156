import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { cond, equals } from 'ramda'
import { isValidDate } from '../utils'
import { useApp, useUser, useNav, NavTypes } from '../hooks'
import Page from '../components/Page'
import CodeRequest from './CodeRequest'
import CodeConfirm from './CodeConfirm'

/* 본인인증: 인증코드 요청 및 검증 */
const Verification = () => {
  useNav(NavTypes.CANCEL)

  const { api, handleError } = useApp()
  const { user, setUser } = useUser()

  /* state */
  const initial = { ...user, name: '', birth: '', socialGender: '' }
  const [values, setValues] = useState(initial)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [timestamp, setTimestamp] = useState()

  /* 이벤트 핸들러 */
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  /* 인증코드 요청 */
  const requestCode = async e => {
    e.preventDefault()

    // 낙관적 업데이트 기법으로, 네트워크보다 앞서 업데이트한다.
    setTimestamp(Date.now())
    setIsSubmitting(true)

    try {
      const data = getUser()
      setUser(data)
      await api.post('/auth/identification/request', data)
    } catch (error) {
      setTimestamp()
      handleError(error)
    }

    setIsSubmitting(false)
  }

  /* 사용자 오브젝트 다듬기 */
  const getUser = () => {
    const includes = array => array.includes(Number(socialGender))
    const { birth, socialGender } = values
    const century = includes([0, 9])
      ? '18'
      : includes([1, 2, 5, 6])
      ? '19'
      : '20'

    return Object.assign(
      {},
      user,
      values,
      socialGender && {
        birth: century + birth,
        sex: includes([1, 3, 5, 7]) ? 'M' : 'F',
        foreigner: includes([5, 6, 7, 8]) ? 'Y' : 'N'
      }
    )
  }

  /* 밸리데이션 */
  const validate = () => {
    const invalid = ([name, value]) =>
      cond([
        [equals('name'), () => value.length < 2],
        [equals('birth'), () => !isValidDate(value)],
        [equals('socialGender'), () => !value.length]
      ])(name)

    return !Object.entries(values).some(invalid)
  }

  /* props */
  const props = {
    request: {
      isSubmitting,
      invalid: !validate(),
      values,
      handleChange,
      onSubmit: requestCode,
      setValues: v => setValues(values => ({ ...values, ...v }))
    },

    confirm: {
      url: '/auth/identification/verify',
      request: requestCode
    }
  }

  /* render */
  const title = (
    <Trans i18nKey="<0>본인 확인</0>을 위해<1/>인증이 필요합니다">
      <span className="red" />
      <br />
    </Trans>
  )

  return (
    <Page title={title}>
      {!timestamp ? (
        <CodeRequest {...props.request} />
      ) : (
        <CodeConfirm {...props.confirm} key={timestamp} />
      )}
    </Page>
  )
}

export default Verification

import React, { useState, useEffect, useCallback } from 'react'
import qs from 'qs'
import { useApp } from '../../hooks'
import { getUserAgent } from '../../env'
import { getErrorMessage } from '../../utils'
// import { closeWebView } from '../../utils/replace'
import { isProduction } from '../../env'
import Bridge from '../../layouts/Bridge'

const HANA_CARD_ISSUE_URL = isProduction
  ? 'https://m.hanacard.co.kr/MPACMM101M.web?CD_PD_SEQ=14437'
  : 'http://175.120.243.115:8083/MPACMM101M.web'

const HanaIssue = () => {
  const { api, handleError } = useApp()
  const [errorMsg, setErrorMsg] = useState()

  // KRT bridge
  const fetchHana = useCallback(async () => {
    try {
      const { data } = await api.get('/user/plcc/token')
      if (!data.token) {
        handleError(new Error('유저 정보 획득 실패'))
        setErrorMsg('유저 정보 획득 실패')
        // closeBridge('유저 정보 획득 실패')
        return
      }

      const param = qs.stringify({
        chai_token: data.token,
        User_Agent: getUserAgent() + ' CHAIAPP',
        return_url: `${window.location.origin}/card/hana/issue/complete`,
        CD_PD_SEQ: '14437',
      })

      window.location.replace(`${HANA_CARD_ISSUE_URL}?${param}`)
    } catch (error) {
      const message = getErrorMessage(error.response)
      handleError(error)
      setErrorMsg('e:' + message)
      // closeBridge(message)
    }
  }, [api, handleError])

  useEffect(() => {
    fetchHana()
  }, [fetchHana])

  if (errorMsg) {
    return (
      <Bridge>
        <h1>오류가 발생하였습니다.</h1>
        <h2>{errorMsg}</h2>
      </Bridge>
    )
  }

  return (
    <Bridge>
      <h1>차이에서 카드를 신청합니다.</h1>
    </Bridge>
  )
}

// function closeBridge(message) {
//   const result = message ? { errorMessage: message } : {}
//   if (isAndroid && window.ChaiAndroidHandler) {
//     // window.ChaiAndroidHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
//     closeWebView()
//   } else if (window.ChaiiOSHandler) {
//     // window.ChaiiOSHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
//     closeWebView()
//   } else {
//     // 브릿지 처리가 없는 경우
//     console.log(result)
//   }
// }

export default HanaIssue

import React, { useCallback, useEffect } from 'react'
import { useApp } from '../../hooks'
import styles from './Add.module.scss'
import { isAndroid } from '../../env'
import { getErrorMessage } from '../../utils'

function closeBridge(message) {
  const result = message ? { errorMessage: message } : {}
  if (isAndroid && window.ChaiAndroidHandler) {
    window.ChaiAndroidHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
  } else if (window.ChaiiOSHandler) {
    window.ChaiiOSHandler.postAction('', 'closeAccountBridge', JSON.stringify(result))
  } else {
    // 브릿지 처리가 없는 경우 (웹 버전?)
    console.log(result)
  }
}

const AddComplete = ({ match }) => {
  const { api, handleError } = useApp()

  // KRT bridge
  const fetchKRT = useCallback(async () => {
    try {
      const { data } = await api.post('/user/account/krt')

      if (data?.success) {
        closeBridge()
      } else {
        // 오류 발생
        const message = getErrorMessage(data.response)
        closeBridge(message)
      }
    } catch (error) {
      handleError(error)
      const message = getErrorMessage(error.response)
      closeBridge(message)
    }
  }, [api, handleError])

  useEffect(() => {
    switch (match.params.bankCode) {
      // KRT
      case 'TERRA':
        fetchKRT()
        break
      default:
        throw new Error('잘못된 경로')
    }
  }, [fetchKRT, handleError, match.params.bankCode])

  return (
    <div className={styles.center}>
      <p className={styles.title}>차이에서 계좌를 추가합니다.</p>
    </div>
  )
}

export default AddComplete

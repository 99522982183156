import React, { useEffect } from 'react'
import Lottie from 'lottie-react-web'

import { useBodyClass } from '../../../hooks'

import lose_bg from '../../../animations/rps_lose_bg.json'

import styles from './Rps.module.scss'

const draw = 'https://static.chai.finance/event/rps/draw@2x.png'

const RpsDraw = ({ history }) => {
  useBodyClass(styles.rps)

  useEffect(() => {
    setTimeout(() => {
      history.replace('/event/rps/play')
    }, 2300)
  }, [history])

  return (
    <section className={styles.resultWrap}>
      <section className={styles.result}>
        <div className={styles.animation}>
          <Lottie
            options={{
              animationData: lose_bg,
              loop: false,
              rendererSettings: {
                preserveAspectRatio: 'xMaxYMax slice'
              },
            }}
          />
        </div>
        <img className={styles.resultImage} src={draw} width={252} height={112} alt="" />
        <p className={styles.resultText}>한 판 더 차이 바위 보!</p>
      </section>
    </section>
  )
}

export default RpsDraw

export const isDevelopment = process.env.REACT_APP_ENV === 'development'
export const isStaging = process.env.REACT_APP_ENV === 'staging'
export const isQa = process.env.REACT_APP_ENV === 'qa'
export const isProduction = process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'alpha'

export const matchMobile = (s) => s.match(/(iPad)|(iPhone)|(iPod)|(android)/i)
export const isMobile = !!matchMobile(navigator.userAgent)
export const isDesktop = !matchMobile(navigator.userAgent)

export const getUserAgent = () => (navigator && navigator.userAgent ? navigator.userAgent.toLowerCase() : '')

export const isAndroid = getUserAgent().indexOf('android') !== -1
export const isKakaoWebView = getUserAgent().indexOf('kakaotalk') !== -1
export const isSamsungBrowser = getUserAgent().indexOf('samsungbrowser') !== -1

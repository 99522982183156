import React, { useContext, useState, useEffect } from 'react'
import * as Toast from 'react-toastify'
import cx from 'classnames'

import { isDevelopment } from '../../../env'
import { useBodyClass } from '../../../hooks'

import { ReactComponent as HomeTitle } from './svg/HomeTitle.svg'
import { ReactComponent as ToggleOff } from './svg/ToggleOff.svg'
import { ReactComponent as ToggleOn } from './svg/ToggleOn.svg'
import { ReactComponent as Check } from './svg/Check.svg'

import styles from './Rps.module.scss'
import { Context } from './Rps'

const endBg = 'https://static.chai.finance/event/rps/homeSticker05@2x.png'

const RpsEnd = ({ history }) => {
  const { api, handleError, toast, rpsConfig } = useContext(Context)

  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useBodyClass(styles.rps)

  useEffect(() => {
    try {
      setIsSubscribed(JSON.parse(localStorage.getItem('isSubscribed')))
    } catch (error) {}

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isUpdating) {
      if (isSubscribed) {
        toast(
          <div className={styles.toastWrap}>
            <span>
              <Check />
            </span>
            <p>이벤트 알림수신에 동의했어요.</p>
          </div>,
          {
            className: 'Toastify__toast--rps',
            position: Toast.toast.POSITION.BOTTOM_CENTER,
          }
        )
      }

      updateSubscriptionStatus()
    }

    // eslint-disable-next-line
  }, [isSubscribed])

  const updateSubscriptionStatus = async () => {
    try {
      const { version } = rpsConfig

      // 서버에 구독 상태 변경을 알린다.
      await api.put(`/event/rps/${version}/subscription`, { isSubscribed })

      // 로컬에 구독 상태를 저장한다.
      localStorage.setItem('isSubscribed', isSubscribed)
    } catch (error) {
      handleError(error)
    }

    setIsUpdating(false)
  }

  const onConfig = () => {
    if (isDevelopment) {
      history.replace('/event/rps/config')
    }
  }

  const onToggle = () => {
    if (isUpdating) {
      return false
    }

    setIsUpdating(true)
    setIsSubscribed(!isSubscribed)
  }

  return (
    <section className={cx(styles.homeSectionWrap, styles.endSectionWrap)}>
      <section className={cx(styles.homeSection, styles.endSection)}>
        <div className={styles.endBg}>
          <img src={endBg} width={374} height={296} alt="" />
        </div>

        <h1>
          <HomeTitle />
        </h1>
        <h2 onClick={onConfig}>이벤트가 종료됐어요</h2>
        <h3>다시 시작하면 알려드릴까요?</h3>
        <button className={styles.toggle} onClick={onToggle}>
          {isSubscribed ? <ToggleOn /> : <ToggleOff />}
        </button>
      </section>
    </section>
  )
}

export default RpsEnd

import { useState, useEffect } from 'react'
import { number, func } from 'prop-types'
import { useInterval } from '../hooks'

const propTypes = {
  initial: number, // 카운트다운할 전체 시간 (초)
  onEnd: func, // 카운트다운이 끝날 때 실행할 함수
  children: func
}

const defaultProps = {
  initial: 0,
  onEnd: () => {},
  children: () => null
}

/* 초 단위로 카운트다운하고, 마지막에 콜백 함수를 실행한다. */
const Countdown = ({ initial, onEnd, children }) => {
  const [current, setCurrent] = useState(initial)

  useInterval(() => {
    current > 0 && setCurrent(current - 1)
  }, 1000)

  useEffect(() => {
    current === 0 && onEnd()
    // eslint-disable-next-line
  }, [current])

  return children(current)
}

Countdown.propTypes = propTypes
Countdown.defaultProps = defaultProps

export default Countdown

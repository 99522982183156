import React, { useContext, useState, useEffect } from 'react'
import Lottie from 'lottie-react-web'
import cx from 'classnames'

import { useBodyClass } from '../../../hooks'

import lose_bg from '../../../animations/rps_lose_bg.json'

import styles from './Rps.module.scss'
import { Context } from './Rps'
import { EventRps2Url } from '../../../Constants'
import { isAndroid } from '../../../env'

const lose = 'https://static.chai.finance/event/rps/lose@2x.png'

const MIN_VERSION_AOS = 2000095
const MIN_VERSION_IOS = 1100368

const STORE_URL_AOS = 'market://details?id=finance.chai.app'
const STORE_URL_IOS = 'itms-apps://itunes.apple.com/app/id1459979272'

const RpsLose = ({ history }) => {
  const { api, handleError, rpsConfig, statusData, getStatusData, sendEvent } = useContext(Context)

  const [isUpdateRequired, setIsUpdateRequired] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [hideAnimation, setHideAnimation] = useState(false)

  useBodyClass(styles.rps)

  useEffect(() => {
    getStatusData()

    const minVersion = isAndroid ? MIN_VERSION_AOS : MIN_VERSION_IOS

    try {
      const buildNumber = localStorage.getItem('buildNumber')
      if (!buildNumber || buildNumber < minVersion) {
        setIsUpdateRequired(true)
      }
    } catch (error) {
      setIsUpdateRequired(true)
    }

    // eslint-disable-next-line
  }, [])

  const doUpdate = () => {
    window.location.href = isAndroid ? STORE_URL_AOS : STORE_URL_IOS
  }

  const openModal = () => {
    setHideAnimation(false)
    setShowModal(true)
  }

  const closeModal = () => {
    setHideAnimation(true)

    setTimeout(() => {
      setShowModal(false)
    }, 300)
  }

  const isAvailable = () => {
    const { maxAttempt } = rpsConfig
    return statusData && statusData.attempt < maxAttempt
  }

  const hasSeenAd = () => {
    const {
      link: { ads },
    } = rpsConfig
    return statusData && statusData.attempt > ads.length
  }

  const onReady = async () => {
    if (!isAvailable()) {
      history.replace('/event/rps/end')
      return false
    }

    if (hasSeenAd()) {
      sendEvent('click_rps_share')

      if (isUpdateRequired) {
        openModal()
      } else {
        try {
          const args = JSON.stringify({
            redirectTo: EventRps2Url,
          })

          if (isAndroid) {
            window.ChaiAndroidHandler.postAction('', 'inviteRPS', args)
          } else {
            window.ChaiiOSHandler.postAction('', 'inviteRPS', args)
          }
        } catch (error) {
          handleError(error)
        }
      }
    } else {
      sendEvent('click_chai_rps_ad')

      try {
        const {
          version,
          link: { ads },
        } = rpsConfig
        const { data } = await api.post(`/event/rps/${version}/ready`)
        if (data && data.result) {
          history.replace('/event/rps')

          // 시도 횟수에 따라 광고를 차례로 보여준다.
          window.location.href = ads[(statusData.attempt - 1) % ads.length]
        }
      } catch (error) {
        handleError(error)
      }
    }
  }

  return (
    <>
      <section className={styles.resultWrap}>
        <section className={styles.result}>
          <div className={styles.animation}>
            <Lottie
              options={{
                animationData: lose_bg,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMaxYMax slice',
                },
              }}
            />
          </div>
          <img className={styles.resultImage} src={lose} width={215} height={112} alt="" />
          <p className={styles.resultText}>
            이런! 아쉽네요
            <br />
            {hasSeenAd() ? '친구에게 공유하고 응모권을 받아보세요' : rpsConfig.text.lose.resultDescription}
          </p>
        </section>

        <section className={styles.bottomSection}>
          <div className={styles.blockButton}>
            <button className={styles.secondary} onClick={onReady}>
              {hasSeenAd() ? '내 친구에게 공유하기' : rpsConfig.text.lose.readyButton}
            </button>
          </div>
        </section>
      </section>
      {showModal && (
        <>
          <div className={styles.background} onClick={closeModal}></div>
          <div className={cx(styles.modal, styles.showModal, hideAnimation && styles.hideModal)}>
            <h3>
              연락처를 불러오기 위해
              <br />
              최신 버전이 필요해요.
            </h3>
            <button className={styles.ok} onClick={doUpdate}>
              업데이트 하기
            </button>
            <button className={styles.cancel} onClick={closeModal}>
              다음에 할께요
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default RpsLose

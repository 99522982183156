import React from 'react'
import { useNav, NavTypes } from '../../hooks'
import { isAndroid } from '../../env'
import styles from './Update.module.scss'

const STORE_URL_ANDROID = 'market://details?id=finance.chai.app'
const STORE_URL_IOS = 'itms-apps://itunes.apple.com/app/id1459979272'

const ChatUpdate = () => {
  useNav(NavTypes.GO_BACK)
  const doUpdate = () => {
    window.location.href = isAndroid ? STORE_URL_ANDROID : STORE_URL_IOS
  }

  return (
    <>
      <div className={styles.centerDiv}>
        <h2>
          쉽고 빠른 채팅 상담을
          <br />
          하러 오셨나요?
        </h2>
        <p>기존의 카카오톡 채팅 서비스는 종료되었어요.</p>
        <p>앱을 업데이트하면 새로워진 채팅을 경험하실 수 있어요.</p>
        <button type="submit" onClick={doUpdate} className="btn btn-block btn-primary btn-submit">
          새로워진 채팅상담 하러가기
        </button>
      </div>
    </>
  )
}

export default ChatUpdate

import React from 'react'
import { isDevelopment } from '../env'
import styles from './Debug.module.scss'

const Debug = ({ log, children }) =>
  isDevelopment ? (
    <article className={styles.component}>
      {children}
      {log && <pre className={styles.log}>{JSON.stringify(log, null, 2)}</pre>}
    </article>
  ) : null

export default Debug

import React from 'react'
import styles from './DropdownSmall.module.scss'

const DropdownSmall = ({ config, children }) =>
  config.isOpen ? (
    <>
      <div className={styles.backdrop} onClick={config.onRequestClose} />
      <div className={styles.children}>{children}</div>
    </>
  ) : null

export default DropdownSmall

import React from 'react'
import { number, string, arrayOf, shape, func, node, bool } from 'prop-types'
import cx from 'classnames'
import { isDesktop } from '../env'
import { useModal } from '../hooks'
import { ReactComponent as Caret } from '../icons/ChevronDownLight.svg'
import Drawer from './Drawer'
import Actions from './Actions'
import Options from './Options'
import DropdownSmall from './DropdownSmall'
import styles from './Select.module.scss'

const propTypes = {
  getOptions: func,

  name: string,
  value: string,
  placeholder: string,
  options: arrayOf(shape({ value: string, children: string })),
  onChange: func,

  v2: bool,
  label: string,
  caret: node,
  column: number,
  className: string,
  children: node,

  onClick: func,
  onFocus: func,
  onBlur: func,
}

const defaultProps = {
  getOptions: undefined,

  /* Form */
  name: '',
  value: '',
  placeholder: '',
  options: [{}],
  onChange: () => {},

  /* render */
  v2: false,
  label: '', // 제목
  caret: <Caret />,
  column: 1,
  className: '',
  children: null,

  /* event */
  onClick: () => {},
  onFocus: () => {},
  onBlur: () => {},
}

// 주의: select.value와 option.value의 구분
const Select = ({ label, caret, column, className, children, ...props }) => {
  /* context */
  const modal = useModal()

  /* props */
  const { getOptions, onFocus, onBlur, onClick, ...rest } = props
  const { name, placeholder, options: initial, onChange, v2, ...select } = rest

  /* Modal: Config */
  const config = {
    ...modal.config,
    onAfterOpen: () => onFocus(),
    onRequestClose: () => {
      onBlur()
      modal.close()
    },
  }

  /* Modal: Open */
  const handleClick = async () => {
    onClick()
    // 비동기 함수를 getOptions로 받으면 그 반환값으로 모달을 연다.
    // 즉, 이 컴포넌트에 getOptions가 있다면 options 초기값이 없어도 괜찮다.
    openModal(getOptions ? await getOptions() : initial)
  }

  const openModal = (options = initial) => {
    const list = options.map(({ image, children, ...option }, index) => {
      const isSelected = index === getSelected(options)
      return {
        image,
        text: children,
        active: isSelected,
        onClick: () => {
          onChange({ name, value: option.value })
          config.onRequestClose()
        },
      }
    })

    modal.open(isDesktop ? <Options options={list} /> : <Actions column={column} list={list} v2={v2} />)
  }

  const getSelected = (options = initial) => options.findIndex((option) => option.value === select.value)

  /* render */
  // 선택한 옵션의 텍스트 (동적으로 옵션을 가져올 때에는 작동하지 않는다.)
  const { children: text } = initial[getSelected()] || {}
  return (
    <div className={cx(styles.wrapper, rest.wrapperClassName)}>
      <button type="button" onClick={handleClick} className={cx(styles.button, className)}>
        {children || text || placeholder}
        {caret}
      </button>

      {isDesktop ? (
        <DropdownSmall config={modal.config}>{modal.content}</DropdownSmall>
      ) : (
        <Drawer title={label} config={config} large v2={v2}>
          {modal.content}
        </Drawer>
      )}
    </div>
  )
}

Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select

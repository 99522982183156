import { isDevelopment } from '../../env'
import { isKeyMerchant } from '../../merchant'

export default ({ isSubscription, publicAPIKey }) => {
  return (
    !isSubscription &&
    (isDevelopment ||
      isKeyMerchant(publicAPIKey, '티몬') ||
      isKeyMerchant(publicAPIKey, '야놀자') ||
      isKeyMerchant(publicAPIKey, '번개장터') ||
      isKeyMerchant(publicAPIKey, '투믹스') ||
      isKeyMerchant(publicAPIKey, '교보') ||
      isKeyMerchant(publicAPIKey, 'interpark'))
  )
}
